@keyframes movetoTop{

    0%{
        transform: translateY(1rem);
    }
    // 50%{
    //     transform: translateY(-.5rem);
    // }
    100%{
        transform: translateY(0);
    }
}

@keyframes menumotion{

    0%{
        opacity: 0;
        
    }

    100%{
        opacity: 1;
    }
}

@keyframes moveLeft{

    0%{
        transform: translateX(20%);
    }

    100%{
        transform: translateX(0);
    }
}

@keyframes pulsate{

    0%{
        transform: scaleY(1);
        box-shadow: none;
    }
    50%{
        transform: scaleY(1.05);
        box-shadow: 0 1rem 4rem rgba(0, 0, 0, .25);
    }
    100%{
        transform: scaleY(1);
        box-shadow: none;
    }
   }

   @keyframes moveInLeft {
    0% {
        opacity: 0;
        transform: translateX(-10rem);
    }

    80% {
        transform: translateX(1rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes moveInRight {
    0% {
        opacity: 0;
        transform: translateX(10rem);
    }

    80% {
        transform: translateX(-1rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes movetoBottom {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes movefromBottom{

    0% {
        opacity: 0;
        transform: translateY(100%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }

}

@keyframes menuBoxes{
    0%{

        opacity: 0;
        top: 120%;
    }

    100%{

        top:100%;
        opacity: 1;
    }
}

@keyframes serviceBox{

    0%{

        opacity: 0;
        transform: translateY(-50%);
    }

    100%{

        opacity: 1;
        transform: translateY(0%);
    }
}

@keyframes scookieBar{
    0%{

        transform: translateY(100%);
    }

    100%{

        transform: translateY(0%);
    }
}

@keyframes growSize{

    0%{
        opacity: .5;
        transform: translateY(-100%);
    }

    100%{

        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes zoomOout{

    0%{

        opacity: 0;
        width: 20%;
    }

    100%{

        opacity: 0;
        width: 100%;
    }
}


@keyframes invisible{

    0%{
        opacity: 0;
    }

    100%{
        opacity: 100%;
    }
}



@keyframes imageAnimation { 
	0% {
	    transform: scale(1.2) rotate3d(1, 1, 1, 8deg)
	}
	// 15% {
	//     opacity: 1;
	//     transform: scale(1.1);
	// }
	// 50% {
	//     opacity: 1;
	//     transform: scale(1) rotate(0deg);
	// }
	// 60% {
	//     opacity: 1;
	//     transform: scale(1.1) rotate(2deg);
	// }
	100% { transform: scale(1) rotate3d(0); }
}


@keyframes panning {
    0% {
      background-position: 0% 0%;
    }
    25% {
      background-position: 100% 100%;
    }
     50% {
      background-position: 100% 0%;
    }
    75% {
      background-position: 0 100%;
    }
    100% {
      background-position: 0 0;
    }
  }


  @keyframes landBottom {
    0% {
        opacity: .5;
        transform: translateY(-100%);
        margin-top: -100%;
    }

    100% {
        opacity: 1;
        transform: translateY(0);
        margin-top: -3rem;
    }
}



@keyframes snooze{

    0%{

        transform: scale(1);
    }

    50%{

        transform: scale(1.1) rotate(5deg);
    }

    100%{

        transform: scale(1);
    }
}

@keyframes orangereds{

    0%{

        background-color: $color-red;
    }

    50%{

        background-color: $color-orange-2;
    }

    100%{

        background-color: $color-orange-3;
    }
}


@keyframes colors{

    0%{

        background-color: $color-orange;
    }

    50%{

        background-color: $color-yellow;
    }

    100%{

        background-color: $color-orange;
    }
}


@keyframes scrolls {
    0%{

        top: 0;
    }

	100% { 
        top: -10rem;
     } 
}

@keyframes orangeCircle {
    0%{

        background-color: $color-white;
    }
    25%{

        background-color: $color-white;
    }
    75% { 
        background-color: $color-orange-1;
     }
	100% { 
        background-color: $color-orange-1;
     }
     
     
}

@keyframes orangeCircle-le {
    0%{

        background-color:transparentize($color-white, .55);
    }

	100% { 
        background-color: $color-orange-2;
     } 

}
@keyframes orangeCircle-sm {
    0%{

        background-color: transparentize($color-orange, .73);
    }

	100% { 
        background-color: transparentize($color-orange, .91);
     } 

}
@keyframes orangeCircle-sm-2 {
    0%{

        background-color: transparentize($color-orange, .55);
    }

	100% { 
        background-color: transparentize($color-orange, .82);
     } 

}

@keyframes colorheight{

    0%{
        height: 2rem;
    }

    50%{
        height: 3rem;
    }

    100%{
        height: 2rem;
    }

}


@include keyframes(fadeInRight) {
    from {
        opacity: 0;
        @include transform( translate3d(50%, 0, 0));
    }

    to {
        opacity: 1;
        @include transform( translate3d(0, 0, 0));
    }
}

@include keyframes(fadeInLeft) {
    from {
        opacity: 0;
        @include transform( translate3d(-50%, 0, 0));
    }

    to {
        opacity: 1;
        @include transform( translate3d(0, 0, 0));
    }
}

@include keyframes(fadeInTop) {
    from {
        opacity: 0;
        @include transform( translate3d(0, 50%, 0));
    }

    to {
        opacity: 1;
        @include transform( translate3d(0, 0, 0));
    }
}
