*{
    margin: 0;
    padding: 0;

    &::selection{

        background-color: $color-orange;
        color: $color-white;
    }
}

*,
*::after,
*::before{
    box-sizing: inherit;
}

html{
    box-sizing: border-box;
    font-size: 62.5%; 
    scroll-behavior: smooth;

    @include respond(tab-land) { 
        font-size: 56.25%; 
    }

}

body{
    font-weight: 400;
    line-height: 1.5;
    font-size: $default-font-size;
    font-family: 'Red Hat Text', sans-serif;
    caret-color:$color-orange; 
    padding-top:6.4rem;

}


