// h1, h2, h3, h4, h5, h6{
//     letter-spacing: .33px;
// }
ul{
    margin: 0;
    padding: 0;
    list-style: none;
}


.modal{
  //background-color: transparentize($color-orange, .11);
  background-image: $transdusk;
}

p{
  color: $gray-mid;
}

.white-bg{
  background-color: $color-white;
}

.light-orange{
  background-color: $light-orange;
}

.light-blue{
  background-color: $light-blue;
}

.light-pink{
  background-color: $light-pink;
}

.light-pink-2{
  background-color: $light-pink-2;
}
.light-aqua{
  background-color: $light-aqua;
}

.light-gray{
  background-color: $gray-light;
}

.light-gray-2{
  background-color: $gray-ed;
}

.dark-blue{
  background-color: $blue-dark;
}

.bluewhite{
  background-image: $bluewhite;
}

.violets{
background-color: transparentize($color-blue-8,.77);
& svg{
  fill: $color-blue-8;
}
}
.pinks{
  background-color: transparentize($color-pink,.77);
  & svg{
    fill: $color-pink;
  }
  }

  .greens{
    background-color: transparentize($color-green,.77);
    & svg{
      fill: $color-green;
    }
    }
    .aquas{
      background-color: transparentize($color-aqua-2,.77);
      & svg{
        fill: $color-aqua-2;
      }
      }
      .blues{
        background-color: transparentize($color-blue-9,.77);
        & svg{
          fill: $color-blue-9;
        }
        }
        .oranges{
          background-color: transparentize($color-orange-4,.77);
          & svg{
            fill: $color-orange-4;
          }
          }

.textlinks{

  display: inline-flex;
  font-weight: $medium-bold;

  &:link,
  &:visited{
    color: $gray-dark;
  }

  &:hover,
  &:focus{
    color: $color-orange;
    outline: none;
  }
}

.textlink-2{

  display: inline-flex;
  transition: $trans;
  font-size: 1.3rem;
  font-weight: $semi-bold;

  &:link,
  &:visited{
    color: $color-orange;
    text-decoration: none;
  }

  &:hover,
  &:focus{
    color: $gray-dark;
    outline: none;
  }
}

.underline-links{
  font-weight: $semi-bold;
  font-size: 2rem;
  margin-top: 1.69rem;
  transition: $trans;
  color: $color-white;
  display: inline-flex;
  width: max-content;
  padding-bottom: .33rem;
  background-color: transparent;
  border: none;
  border-bottom: .28rem solid $color-orange;

  &:link,
  &:visited{
    text-decoration: none;

  }

  &:hover,
  &:focus{
    border-color: $color-blue-4;
    outline: none;
    color: $color-yellow-2;
  }
  &.black{
    color: $gray-dark;
    &:hover{
    color: $color-orange
    }
  }
  &.center{
    margin: 0 auto;
  }
}

.large-btn{
    background-color: $color-orange;
    padding: 1rem 3rem;
    border-radius: $radius;
    border: 2px solid $color-orange;
    color: $color-white;
    font-size: 1.9rem;
    transition: $trans;
    font-weight: $semi-bold;
    display: inline-flex;

    @include respond(sd) {
      padding: .69rem 2.8rem;
      font-size: $default-font-size;
    }

    &-line{
        background-color: transparent;
        color: $color-orange;
    }

    &:link,
    &:visited{
        text-decoration: none;
    }

    &:hover,
    &:focus{
        background-color: $color-orange-1;
        border-color: $color-orange-1;
        color: $color-white;
        outline: none;
    }
}


.exlarge-btn{
  background-color: $color-orange;
  padding:1.5rem 6rem;
  border-radius:.5rem;
  border: 2px solid $color-orange;
  color: $color-white;
  font-size:2rem;
  text-transform: uppercase;
  transition: $trans;
  font-weight: $semi-bold;
  display: inline-flex;

  @include respond(medium-tab) {
    padding:1rem 4rem;
    justify-content: center;
  }

  &-line{
      background-color: transparent;
      color: $color-orange;
  }

  &:link,
  &:visited{
      text-decoration: none;
  }

  &:hover,
  &:focus{
      background-color: $gray-dark;
      border-color: $gray-dark;
      color: $color-white;
  }
}


.darkbtn{
  background-color: $gray-dark-1;
  padding:1.5rem 6rem;
  border-radius:.5rem;
  border: none;
  color: $color-white;
  display: flex;
  text-transform: uppercase;
  transition: $trans;
  font-weight: $medium-bold;
  width: $maxwidth;
  justify-content: center;
  align-items: center;

  &:link,
  &:visited{
      text-decoration: none;
  }

  &:hover,
  &:focus{
      background-color: $color-water-2;
      color: $gray-light;
  }
}



.dropDownBox{
    padding:1.33rem;
    position: absolute;
    top: $maxwidth;
    left:50%;
    transform: translateX(-50%);
    background-color:$color-orange;
    margin-top: 0;
    grid-column-gap: 1.5rem;
    grid-row-gap: .73rem;
   // box-shadow: .5rem .5rem 1rem rgba($gray-dark,.2);
    display: none;
    animation: menuBoxes .33s ease;
    width: max-content;
    z-index: 1000;
    border-radius: $radius;

    @include respond(tab-land) {
       position: static !important;
      // width: calc(100vw - 5rem);
       //box-shadow: none;
       padding: 1rem;
       animation: none;
       left: 0;
       transform: translateX(0);
      }

      &.twoColumn{
        grid-template-columns:repeat(2, 1fr);
        column-gap: 1.5rem;

      @include respond(tab-land) {
          grid-template-columns: 1fr !important;
          padding: 1rem;
         // width: calc(100vw - 5rem);
      }
      }



    &.largeColumn{
        grid-template-columns: repeat(4, auto) !important;
        grid-auto-flow: dense;
        column-gap: 3rem;

      @include respond(tab-land) {
          grid-template-columns: 1fr !important;
          padding: 0 1rem;
          //width: calc(100vw - 5rem);
      }
      }

      &.xtralargeColumn{
        grid-template-columns: repeat(5, auto) !important;
        grid-auto-flow: dense;
        column-gap: 3rem;

      @include respond(tab-land) {
          grid-template-columns: 1fr !important;
          padding: 0 1rem;
          //width: calc(100vw - 5rem);
      }
      }

      & h4{
        color:$gray-a;
        font-weight: $bold;
        padding-bottom: .5rem;
        text-transform: capitalize;
        font-size: $default-font-size


     }

ul{

    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;

    li{
        margin-bottom: .69rem;
        display: flex;

        &:last-child{
          margin-bottom: 0;
        }
    }
}

    a{
      padding: 0 !important;
      font-size:1.5rem;
      display: flex;
      color: $color-white !important;

      &:hover{
        color: $color-black !important;
      }

    }

  }
.sections{
  padding: 6rem 0;
  scroll-margin-top: 6rem;
  overflow-x: hidden;

  &.npfull{
    padding: 0;
  }

  &.nptop{
    padding-top: 0;
  }

  &.npbottom{
    padding-bottom: 0;
  }

}

.sectitle_01{
  display: flex;
  flex-direction: column;
  margin: 0 auto 3rem auto;

  h3{
    text-align: center;
    text-transform: uppercase;
    color:$color-orange;
    font-weight: $medium-bold;
    font-size: 2rem;
    margin-bottom: 1.69rem;
  }

  h2{
    font-size: 3.3rem;
    text-align: center;
    font-weight: $bold;

    @include respond(medium-tab) {
      font-size:2.8rem;

    }

    @include respond(newphones) {
      font-size:2.5rem;

    }

    @include respond(sd) {
      font-size: 2.1rem;

    }

    & span{
      display: block;
    }

    & strong{
      font-weight: $bold;
      color: $color-orange;
    }
  }

  p{
    text-align: center;
    max-width: 80rem;
    margin:1rem auto;
    color: $blue-dark;
    font-size: 1.73rem;
    font-weight: $medium-bold;
  }

}

.sectitle_02{
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;

  h2{
    font-size: 3.3rem;
    font-weight: $semi-bold;
    color: $color-orange;

    @include respond(phone) {
      font-size: 2.5rem;

    }

    & span{
      display: block;
    }

    & strong{
      font-weight: $bold;
      color: $color-orange;
    }
  }

  hr{
    height: .44rem;
    width: 10rem;
    opacity: 1;
    border-radius: 100px;
  }

  &.white{
    h2{
      color: $color-white;
    }

    hr{
      background-color: $color-white;
    }
  }

  &.dark{
    h2{
      color: $gray-dark;
      font-size: 2.9rem;
    }
  }
}

.revolutionize{

  .item{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:3rem 1rem;
    transition: $trans;


    h3{
      font-size: 2.1rem;
      font-weight: $semi-bold;
    }
    p{
      text-align: center;
    }

    svg{
      height: 6.4rem;
      width: 6.4rem;
      margin-bottom: 2rem;
    }
  }

  .light-orange{
    &:hover{
      background-color: darken($light-orange, 5%)
    }
  }

  .light-blue{
    &:hover{
      background-color: darken($light-blue, 5%)
    }
  }

  .light-pink{
    &:hover{
      background-color: darken($light-pink, 5%)
    }
  }

  .light-aqua{
    &:hover{
      background-color: darken($light-aqua, 5%)
    }
  }


}

.bmsoftware{

  .bmscontent{
    padding-top:4rem;

    display: grid;
    grid-template-columns: 45% 55%;

    .leftside{

      position: relative;

      & .textlinks{
        display: flex;
      }

      h4{
        font-size: 2.2rem;
        font-weight: $semi-bold;
        margin-bottom: 1.5rem;
      }

      padding-right: 6rem;

      & .floated{
        position: relative;
        margin-right: -12rem;
        margin-top: 2rem;
      }
    }
    .picside{

      img{
        max-width: $maxwidth;
        border-top-left-radius: 5rem;
      }
    }
  }

  & .nav{

    &-tabs{
      justify-content: space-between;
      border: none;
      background-color: $color-white;
      box-shadow: 0 .69rem 1rem transparentize($gray-dark, .93);
    }

    &-link{
      color: $gray-dark;
      font-weight: $semi-bold;
      border: none;
      border-bottom: .44rem solid transparent;
      border-radius: 0;
      padding: .69rem 2rem;

      &:hover,
      &:focus{
        color: $color-orange;
      }

      &.active{
        border-bottom-color: $color-orange;
      }
    }
  }
}

.checklist{

  display: grid;


  &.twocol{
    grid-template-columns: repeat(2, 1fr);
    column-gap: 3rem;
  }

  li{
    padding-left: 3.5rem;
    position: relative;
    margin: 1rem 0;
    display: flex;
    font-weight: $medium-bold;

    &::before{
      content: '\2713';
      background-color: $color-orange;
      @include portCenter;
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      justify-content: center;
      font-weight:$medium-bold;
      color: $color-white;
      left: 0;
      border-radius: 50%;
      z-index: 0;
    }
  }
}

.checklist-lg{

  display: grid;
  margin-bottom: 2rem;

  &.twocol{
    grid-template-columns: repeat(2, 1fr);
    column-gap: 4rem;
  }

  & li{
    padding:1rem 0 1rem 5rem;
    position: relative;
    display: flex;
    //font-weight: $medium-bold;
    color: $gray-mid;

    &::before{
      content: '\2713';
      background-color: transparent;
      font-size: 2.2rem;
      width: 3rem;
      height: 3rem;
      line-height:1.2;
      display: flex;
      justify-content: center;
      font-weight:$bold;
      color: $color-orange;
      left: 0;
      border-radius: 50%;
      border: .25rem solid $color-orange;
      z-index: 0;
      position: absolute;
      left: 0;
      top: .69rem;
    }
  }
}

.arrowlist{

  display: grid;
  color: $gray-mid;

  &-lg{
    font-size: 1.73rem;
    font-weight: $medium-bold;
    color:darken($gray-mid, 11%);
    margin-bottom: 2rem;
  }

  &.twocol{
    grid-template-columns: repeat(2, 1fr);
    column-gap: 3rem;
  }

  li{
    padding-left: 2rem;
    position: relative;
    margin-bottom: .69rem;
    display: flex;
    flex-direction: column;

    &::before{
      content: '\00BB';
      position: absolute;
      top: -.3rem;
      justify-content: center;
      font-weight: $medium-bold;
      color: $color-orange;
      left: 0;
      z-index: 0;
      font-size: 1.9rem;
    }

    & ul{
      margin-top: .69rem;
      & li{
        &::before{
          content: '\203A';
        }
      }
    }
  }
}

.starlist{
  font-size: 1.73rem;

  li{
    position: relative;
    padding-left: 4rem;
    margin-bottom: 1.5rem;
    font-weight: $bold;

    &::before{
      content: '\2605';
      @include portCenter;
      left: 0;
      color: $color-white;
      font-size: 3rem;
      -webkit-text-stroke: .2rem $color-orange;
    }
  }
}

.lgbtn-group{

  max-width: 80rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;

  @include respond(medium-tab) {
    flex-direction: column;
    row-gap: 2rem;
  }
}

.socialinks{
  display: flex;
  //margin-left: auto;
  // font-size: 2.2rem;

  li{

    margin: .69rem 1rem;
    display: inline-flex;
    align-items: center;
    svg{
      height: 2.8rem;
      width: 2.8rem;
      fill: $gray-80s;
      transition: $trans;

      @include respond(md) {
        width: 2.3rem;
      height: 2.3rem;
      }
    }

    a{
      &:link,
      &:visited{
        //background-color: $color-black;
        color: $gray-80s;
        width: 4rem;
        height: 4rem;
        display: inline-flex;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        transition: $trans;
        text-decoration: none;

        @include respond(md) {
          width: 3.5rem;
        height: 3.5rem;
        }

      }

      &:hover,
      &:focus{
        outline: none;
        svg{
          fill: $color-orange-1;
          height: 3rem;
          width: 3rem;

          @include respond(md) {
            width: 2.8rem;
          height: 2.8rem;
          }
        }
      }
    }
  }



&.colored{

  li{

    &:first-child{
      margin-left: 0;
    }

    a{
      &.facebook{
        background-color: $bluefb;
      }

      &.twitter{
        background-color: $bluetwr;
      }

      &.linkedin{
        background-color: $blueldn;
      }

      svg{
        fill: $color-white;
        height: 2rem;
        width: 2rem;
      }

      &:hover{
        transform: translateY(.33rem);
        background-color: $color-orange-3;
      }

    }
  }
}

&.small{
  margin: 0 0 0 1rem;

  li{

    margin: 0 .69rem 0 0;

    a{
      width: 3rem;
      height: 3rem;

      svg{
        width: 1.45rem;
        height: 1.45rem;
      }
    }
  }
}

}

.justflex{
  display: flex;

  &.center{
    justify-content: center;
  }

}

.bigtitle{
  text-align: center;
  font-size: 5rem;
  font-weight: $bold;
  margin-bottom: 6rem;
  @include respond(tab-port) {
    font-size: 3.3rem;
    margin-bottom: 3rem;
  }

  @include respond(phone) {
    font-size:2.8rem;
  }

}

.movements{
  transition: $trans;

  &.showmove{
    animation: serviceBox 4s ease .69s;
  }
}

.fadeInRight {
  @include animation('fadeInRight .5s ease .3s both');

}

.fadeInLeft {
  @include animation('fadeInLeft .5s ease .3s both');
}

.fadeInTop {
  @include animation('fadeInTop .5s ease .3s both');
}

.contentpics{
  max-width: $maxwidth;
  display: flex;
  margin: 2rem 0;
}

.splitform{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: $default-font-size;

  @include respond(normal-tab) {

    grid-template-columns:1fr;
    row-gap: $default-font-size;
  }


}

.titlelinks{
  display: flex;
  align-items: center;
  width: $maxwidth;
  margin-bottom: 2rem;

  .blogdate{
    align-items: center;
    display: inline-flex;
    margin-right: auto;
    p{
      margin: 0;
    }
    i{
      color: $color-orange;
      font-size: 2rem;
      margin-right: 1rem;
    }
  }


}

.blogmedia{
  display: flex;
  align-items: center;
}

.blog_bottom_card{
  display: grid;
  grid-template-columns: auto 1fr;
  box-shadow: $shadow;
  padding: 2rem;
  margin: 2rem 0;
  overflow: hidden;
  border-radius: .69rem;
  align-items: center;
  column-gap: $default-font-size;

  @include respond(phone) {
    grid-template-columns: auto;
    row-gap: 2rem;
  }


  & figure{
    display: flex;
    flex-direction: column;
    align-items: center;

    h5{
      font-size: $default-font-size;
      text-align: center;

      & small{
        display: block;
        color: $gray-mid;
      }
    }

    & img{
      border-radius: 50%;
      width: 12rem;
      margin-bottom: 1rem;
    }
  }
}
.blogsearch{
  display: flex;
  position: relative;
  overflow: hidden;
  margin-bottom: 2rem;

input{
  background-color: $color-white;
  border: $borders;
  border-radius: .5rem;
  padding: 1rem;
  width: $maxwidth;
  margin:0;
  //color: $gray-light;
  transition: $trans;

  &:hover,
  &:focus{
    outline: none;
  }

  &:focus{
    &:invalid {
      border-color: $color-red;
  }
  }

}

.searchbtn{
  @include portCenter;
  right: 0;
  background-color: transparent;
  height: $maxwidth;
  align-items: center;
  justify-content: center;
  width: 5rem;
  border: none;
  display: inline-flex;


  svg{
    width: 2rem;
    height: 2rem;
    fill: $gray-mid;
    transition: $trans;
  }
}

&:hover,
&:focus{

  & .searchbtn{
      outline: none;
  svg{
    fill: $color-orange;
  }
}
}

}

.subtitle_01{
  font-weight: $bold;
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.form-control,
.form-select,
.btn{
  font-size: $default-font-size;
}

.modalcolumns{
  display: grid;
  grid-template-columns: minmax(auto, 32rem) 1fr;


  @include respond(tab-port) {
    grid-template-columns:1fr;
  }

}

#producTour{

  .modal-content{
    border-radius: 1rem;
    overflow: hidden;
  }

  & .modal-body{
    padding: 0;

    & picture{
      background-image: url(../img/popup-bg.jpg);
      @include bgCenter;
      display: flex;
      height: 45.3rem;

      @include respond(tab-port) {
        display: none;
      }

    }

    img{
      max-width: $maxwidth;
      display: flex;
    }

    & article{
      position: relative;
      padding:2.5rem 3rem;

      input,
      select,
      textarea{
        background-color: $color-white;
        border: $borders;
        border-radius: $radius;
        padding: .55rem;
        width: $maxwidth;
        margin-bottom: 1.69rem;

        &:hover,
        &:focus{
          outline: none;
        }

        &:focus{
          &:invalid {
            border-color: $color-red;
        }
        }

      }

      & .nav{
        justify-content: center;

        &-item{
          &:first-child{

            & .nav-link{
            border-radius: 100px 0 0 100px;
            }
          }
          &:last-child{
            & .nav-link{
            border-radius: 0 100px 100px 0;
            }
          }
        }

        &-link{
          border-radius:0;
          padding: .33rem 3rem;
          border: 2px solid $color-orange;
          font-weight: $semi-bold;
          color: $color-orange;

          &.active{
            background-color: $color-orange;
            color: $color-white;
          }

        }

      }

      .nav-pills {

        margin-bottom: 2.5rem;

        & .show>.nav-link{
            background-color: $color-orange;
            color: $color-white;
        }
      }

      h3{
        margin:1rem 0 2rem 0;
        font-weight: $bold;
        font-size: 2rem;
        text-align: center;
      }

      h4{
        font-weight: $bold;
        font-size: 1.72rem;
        color: $color-orange;
      }

      p{
        color: $gray-four;
      }

      & .btn-dark{
        padding: .5rem 3rem;
        font-weight: $semi-bold;

        &:hover{
          background-color: $color-orange;
          border-color: $color-orange;
        }
      }

      & .btn-close{
        right: 2rem;
        top: 2rem;
        position: absolute;
        z-index: 100;

        &:focus{
          outline: none;
          box-shadow: none;
        }
      }
    }
  }
}

.commonContent{

  h4{
    font-size: 2.2rem;
    font-weight: $bold;
    margin: 2rem 0;
  }

  h5{
    font-weight: $semi-bold;
    font-size:1.72rem;
    color: $gray-three;
    margin-bottom: 1.69rem;
  }

}

ol{
  margin: 0;
  padding: 0;
  counter-reset: lists;

  & li{
    margin-bottom: .69rem;
    display: flex;
    padding-left: 2rem;
    position: relative;

    a{
      color:$color-orange;
      margin-left: .55rem;
      &:hover{
        color: $color-orange-2;
      }
    }

    &::before{
      content:counter(lists) '.';
            counter-increment: lists;
            color: $color-orange;
            top: 0;
            left:0;
            position: absolute;
            z-index: 1;
            font-weight: $medium-bold;
    }
  }
}

.smalltitle_01{
  color:$color-orange;
  font-weight: $bold;
  font-size: 2.8rem;
  margin-bottom: 2rem;
}

.scrollToTopBtn {
  background-color:$gray-dark-3;
  border: none;
  color: $color-white;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 5rem;
  font-size: 3rem;
  border-radius: 50%;
  position: fixed;
  bottom: 10rem;
  right: 3rem;
  z-index: 100;
  opacity: 0;
  transform: translateY(10rem);
  transition: $trans;

  @include respond(md) {
    width: 4rem;
  height: 4rem;
  font-size: 2.5rem;
  }

  &:hover{
    background-color: $color-orange;
  }

  &.showBtn {
    opacity: 1;
    transform: translateY(0);
  }
}



.faqsection{
  position: relative;
  margin-top: -17rem;
  padding-top: 16rem;
  padding-bottom: 6rem;
  background-color:$gray-ef;
  z-index: 0;


  p,
  ol,
  ul{
    color: $gray-four;
    text-align: left;

    & strong{
      font-weight: $semi-bold;
      color: $color-orange-3;
    }

    & a{
      color: $color-orange;

      &:hover{
        color: $color-orange-2;
      }
    }
  }

  .accordion-button{
    font-size: 1.82rem;
    font-weight: $semi-bold;
    outline: none;
    box-shadow: none;
    background-color: $color-white;
    color: $gray-dark;
    padding: 1.69rem;

    &::after{
      width: 2rem;
      height: 2rem;
      background-size: 1.82rem;
      background-image: url(../img/chevron-down.svg);
    }

    &:not(.collapsed){
      color: $color-orange;
    }
  }

.faqwrap {
  display: grid;
  grid-template-columns: 28rem 1fr;
  align-items: baseline;
  column-gap: 3rem;

  .faqCats{
    display: grid;

    & li{
      display: grid;
      padding: .69rem;
      border-radius: $radius;
      font-weight: $semi-bold;
      cursor: pointer;
      transition: $trans;
      margin-bottom: .69rem;

      &.active{
        background-color: $color-white;
        color: $color-orange;
      }
    }
  }

  .catContents {
    display: none;

    &.active {
      display: grid;
      align-items: baseline;
    }
  }

}

}

// cookies

.cookies-infobar {
  background:$gray-dark-1;
  position: fixed;
  width: $maxwidth;
  left: 0;
  right: 0;
  bottom: 0;
  padding:3rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 1022;
 // border-top: .2rem dashed transparentize($color-orange, .1);
  animation: scookieBar 1s ease;
  column-gap: 2rem;
  box-shadow: $shadow;


  &_wrapper {
    display: flex;
  }

  &_content{
    color: $color-white;
    a{
      &:link,
      &:visited{
        color: $color-orange;
        transition: $trans;
        text-decoration: none;
        font-weight: $semi-bold;
      }

      &:hover,
      &:focus{
        outline: none;
        color: $color-orange-1;
      }
    }
  }

  &_btn {
    display: inline-flex;
    padding:1rem 3rem;
    background: $color-orange;
    text-decoration: none;
    border-radius: $radius;
    color: $color-white;
    margin-left: 1rem;
    transition: $trans;
    font-weight: $semi-bold;
    width: max-content;
    border: none;
    &:hover{
      background-color: $gray-dark;
    }
    &:focus{
      outline: none;
    }
  }

  &_accepted {
    display: none;
  }

}

.cookieOptions{
  margin-top: 1rem;
}

.form-check-input{
  width: 1.69rem;
  height: 1.69rem;
  box-shadow:none;
  background-color: transparent;
  border-color: transparentize($color-white, .73);

  &:focus{
    border-color:$color-orange-2;
    outline: 0;
    box-shadow:none;
  }

  &:checked{
    background-color: $color-orange;
    border-color: $color-orange;
    box-shadow:none;
  }
}

.searchbox{
  max-width: 55rem;
  width: $maxwidth;
  margin: 0 auto 3.7rem auto;
  display: flex;

  & input{
    background-color: $color-white;
    background-image: url(../img/search.svg);
    background-repeat: no-repeat;
    background-position:1rem center;
    background-size: 2rem;
    border: $borders;
    border-radius:.5rem 0 0 .5rem;
    padding: 1rem 1rem 1rem 4rem;
    width: $maxwidth;
    margin:0;
    //color: $gray-light;
    transition: $trans;
    border-right: none;
    text-align: center;

    &:hover,
    &:focus{
      outline: none;
    }

    &:focus{
      &:invalid {
        border-color: $color-red;
    }
    }

  }

  & button{
    border: none;
    background-color: $color-orange;
    color: $color-white;
    text-transform: uppercase;
    padding: .11rem 1.5rem;
    border-radius:0 .5rem .5rem 0;
    transition: $trans;

    &:hover,
    &:focus{
      outline: none;
      background-color: $color-orange-1;
    }
  }
}

.supportCards{
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 3.3rem;
  border-radius: 2rem;
  overflow: hidden;
  column-gap: 3rem;
  margin:3.3rem 0;
  transition: $trans;
  align-items: center;

  @include respond(tab-land) {
      margin:2rem 0;
  }


  h4{
    font-size: 2rem;
    margin-bottom: 1.9rem;
    font-weight: $bold;
  }

  p{
    color: $gray-dark;
    margin: 0;
    text-align: left !important;
  }

  & span{
    width: 8rem;
    height: 8rem;
    background-color: transparentize($color-white, .66);
    display: inline-flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    transition: $trans;

    & svg{
      width: 4rem;
      height: 4rem;
      fill: currentColor;
    }

  }

  &.aqua{
    background-color: transparentize($color-water-1, .33);
  }

  &.blue{
    background-color: transparentize($color-blue-1, .33);
}
&.orange{
  background-color: transparentize($color-orange-2, .33);
}

&:hover{
transform: translateY(.66rem);

& span{
  background-color: transparentize($color-white, .33);

  & svg{
    fill: $color-black;
  }

}

}

}


// new edits 14/9/22


.loginModal {
  background: transparentize($color-black, .55) !important;

  & .modal{

  &-content {
    border-radius: 1rem;
  }
  &-title {
    color: $blue-dark;
    font-weight: $bold;
    text-align: center;

    & span {
      color:$color-orange-3;
    }
  }

  &-body {
    padding: 1rem 2rem;
  }

  &-footer {
    justify-content: center;
    padding: 2rem;

    & button {
      width:$maxwidth;
      padding: .5rem;
      margin: .5rem 0;
      border-radius: .5rem;
    }

    & a{
      text-decoration: none;
      color: $color-white;
    }
  }
}

& .btn{
  transition: $trans;
&-secondary {
  background-color: $color-orange-3;
  border-color: $color-orange;
  &:hover{
    background-color: $color-orange;
  }
}
&-primary {
  background-color:$gray-four;
  border-color: $gray-four;
  &:hover{
    background-color: $gray-dark;
  }
}
}

}


.trailForm {
  display: block;
  padding: 3rem;
  margin-top: -6.4rem;
  background-image: url(../../assets/img/trail_fornPattern.svg);
  background-repeat: no-repeat;
  background-position:top right;
  height: 100vh;
  background-size: contain;

  @include respond(tab-land) {
   padding: 1.5rem;
}

  .trailogo{
    & img {
      height: 4rem;
    }
    margin-bottom: 1rem;
  }
  .sec-back{
    background-color: transparent;
    border: none;
    font-weight: $semi-bold;
    font-size: 1.66rem;
    & svg{
      height: 1.66rem;
      width: 1.66rem;
    }
  }

 & .formColum {
    display: grid;
    grid-template-columns: 1fr 40%;
    column-gap: 6rem;
    margin-top: 2rem;
    vertical-align: middle;

    @include respond(tab-land) {
      grid-template-columns: 1fr;
   }

   & .form-check-input{
    width: 1.69rem;
    height: 1.69rem;
    box-shadow:none;
    background-color: $color-white;
    border-color: $color-orange;

    &:focus{
      border-color:$color-orange-2;
      outline: 0;
      box-shadow:none;
    }

    &:checked{
      background-color: $color-orange;
      border-color: $color-orange;
      box-shadow:none;
    }
  }

    & .form-container {
      display: grid;
      padding-left: 6rem;

      @include respond(tab-land) {
        padding-left: 0;
     }

      & h2 {
        font-size: 2.5rem;
        font-weight:$bold;
        margin-bottom: 1rem;
      }

      & p {
        color: $gray-four;
      }

      & .formClassification {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 4.4rem;
        margin-top: 1rem;

        @include respond(medium-tab) {
          grid-template-columns: 1fr;
       }
       & .dom-colum{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1rem;
      }
      }

    }

    & .right-bg {
      align-items: center;
      justify-content: center;
      display: grid;
      & img {
        max-height: 46rem;
        max-width: $maxwidth;
      }
    }
  }
}


