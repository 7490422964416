
.containers{
  @extend %commonSects;

  p{
    text-align: justify;

    & strong{

      color: $gray-four;
      font-weight: $semi-bold;
    }

  }

  @include respond(medium-tab) {

    p{
      text-align: left;
    }
  }
}

.hero{
  height: calc(100vh - 7.3rem);
  background-color: $gray-ef;
  display: grid;
  width: $maxwidth;
  grid-template-columns: 56% 44%;
  align-content:flex-start;


  .heroContent{

    padding:6rem 2rem 4rem  11rem;

    @include respond(mid-desktop) {

      padding:6rem 2rem 4rem  4rem;

    }

    p{
      line-height: 1.66;
    }

    h2{
      font-size: 3.7rem;
      font-weight: $bold;
      margin: 3rem 0;
      line-height: 1.6;

      span{
        color: $color-orange;
      }
    }

    .jumbo{
      background-color: $color-white;
      padding:1.5rem;
      border-radius: 5px;
      margin:0 auto;
      box-shadow: 0 0 1.1rem transparentize($gray-dark, .91);

      h1{
          text-align: center;
          margin: 0;
          line-height: normal;

          @include respond(mid-desktop) {

            font-size: 2.2rem;

          }
      }

      & strong{
          font-weight: $semi-bold;
          color: $color-aqua;
      }
  }

  .logoGroup{

    display: flex;
    margin:3rem 0;

    img{
      margin-right: 1.5rem;
      max-height: 4.4rem;
      box-shadow: .5rem .5rem 1rem transparentize($gray-dark, .82);
      padding:.66rem 1.33rem;
      border-radius: $radius;
      transition: all .2s ease-in-out;
      background-color: transparentize($color-white, .55);

      &:hover{
        background-color: transparentize($color-white, .1);
      }

    }
  }

  }

  .heropic{
    position: relative;
    display: flex;
    transition: all ease-in-out;

    &::after{
      position: absolute;
      bottom: -4rem;
      background-color: $color-orange;
      right: 0;
      content: '';
      width: 90%;
      height: 15rem;
      z-index: 0;
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
      animation: colors 3s ease-in-out infinite;
    }

    img{
      max-width: $maxwidth;
      margin-left: auto;
      z-index: 2;

    }
  }
}

.rollingrid{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: $maxwidth;
  column-gap: 3.5rem;

  .midiv{
    padding:3.5rem 2.5rem;
    text-align: center;
    border-radius: 3rem;
    background-color: $light-pink-2;
    border: 2px solid $color-orange;
    transition: $trans;
    display: grid;
    align-content: center;
    justify-items: center;
   // height: 31rem;

    .textlinks{

      &:hover,
      &:focus{
        color: $color-white;
      }
    }

    p{
      text-align: center;
      margin: 2rem;

    }

    h2{
      font-size: 3rem;
      font-weight: $semi-bold;

      strong{
        color: $color-orange;
      }
    }

    &:hover{
      background-color: $color-orange;

      p{
        color: $light-orange;
      }

      h2{
        strong{
          color: $color-white;
        }
      }
    }


  }

  .scrollicons{
    position: relative;
    //max-height:42rem;
   // overflow: hidden;
    transform: translate3d(0, 0, 0);

    .animscroll{
      // position:absolute;
      // animation: scrolls 8s linear 01ms infinite;

      h4{
        font-weight: $semi-bold;
        font-size: 2.2rem;
        margin-bottom: 1.9rem;
      }

      .iconbadges{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 3.5rem;
        margin-bottom: 2rem;

        .ibadge{

          p{
            text-align: center;
            color: $gray-dark;
            font-weight: $semi-bold;
            margin-top: .69rem;
          }

          span{
            display: grid;
            border-radius: .5rem;
            justify-content: center;
            padding: 2rem;
            transition: $trans;

            &:hover{
              background-color: $color-orange;
            }

            svg{
              height: 7.3rem;
              width: 7.3rem;
            }
          }
        }

      }

    }
  }
}

.twocolgrid{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 15%;
  align-content: center;

  .textside{

    display: grid;
    align-content: center;

    h2{
      font-size: 2.7rem;
      margin-bottom: 2rem;

      strong{
        color: $color-orange;
      }

      span{
        display: flex;
        color: $color-orange;
        font-size: 3.2rem;
        font-weight: $semi-bold;
        padding-bottom: 2rem;
      }
    }
  }

  .imgside{
    position: relative;
    padding: 5rem 0;

    img{
      max-width: $maxwidth;
      position: relative;
      z-index: 100;
    }

    &::after{
      height: $maxwidth;
      top: 0;
      content: '';
      width: 75%;
      position: absolute;
      z-index:1;
      right: 0;

    }

    &.blue{
     &::after{
      background-color: $light-blue;
     }
    }

    &.pink{
      &::after{
       background-color: $light-pink;
      }
     }

     &.aqua{
      &::after{
       background-color: $light-aqua;
      }
     }

     &.orange{
      &::after{
       background-color: $light-orange;
      }
     }

    &.left{
      &::after{
      left: 0;
      }
    }


  }
}

.opinions{
  display: grid;
  grid-template-columns: 37rem 1fr;
  column-gap: 5rem;
  width: $maxwidth;
  align-content: center;
  background-color: $gray-light;

  .wordings{

    display: grid;
    align-content: center;

    img{
      height: 5.5rem;
    }

    p{
      font-size: 1.9rem;
      margin: 2rem 0;
      color: $gray-dark;
      text-align: left;
      font-weight: $medium-bold;
    }

    h4{
      font-size: 2.5rem;


      small{
        display: flex;
        font-size: 1.5rem;
        color: $gray-mid;
        margin-top: .69rem;
      }
    }
  }

  .pictures{

    display: flex;
    position: relative;
    justify-content: flex-end;
    border-radius: 2rem;
    overflow: hidden;

    &::after{
      width: $maxwidth;
      bottom: 0;
      content: '';
      height: 82%;
      position: absolute;
      z-index:1;
      right: 0;
      border-radius: 2rem;

    }

    &.gray{
      &::after{
       background-color: $gray-ed;
      }
     }

     &.blue{
       &::after{
        background-color: $light-blue;
       }
      }

      &.orange{
       &::after{
        background-color: $light-orange;
       }
      }


    img{
      max-width: $maxwidth;
      z-index: 2;
    }
  }
}

#customersays{

.carousel-indicators{

  position: relative;
  justify-content: start;
  margin:2rem 0 0 0;

  [data-bs-target]{

  width: 5rem;
  height: 5rem;
  text-indent: 0;
  background-color: transparent;

  img{
    max-width: $maxwidth;
  }
}
}
}

footer{
  background-color: $gray-dark;
  padding: 1.5rem 0;
  display: flex;
  flex-direction: column;

  .footop{
    padding: 2rem 0;
    border-bottom: 1px solid $gray-four;
  }

  .footcolumns{
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    padding: 2rem 0;

    @include respond(normal-tab) {
      grid-template-columns:1fr;
      row-gap: 2rem;

    }

    h3{
      font-size: 2.2rem;
      color: $color-white;
      font-weight: $semi-bold;
      margin-bottom: 2rem;
    }

    .footaddress{

      ul{
        font-size: 1.55rem;

        li{

          display: block;
          margin: .69rem 0;
          color: $gray-80s;

          a{
            &:link,
            &:visited{
              color: $gray-80s;
              transition: $trans;
              text-decoration: none;
              display: inline-flex;
            }

            &:hover,
            &:focus{
              outline: none;
              color: $color-orange-1;
            }
          }
        }
      }

      img{
        height: 4.4rem;
        display: flex;
        margin-bottom: 2rem;

        @include respond(phone) {
          height: 3.7rem;
        }

      }
    }

    .footlinks{

      ul{

        font-size: 1.55rem;

        li{

          display: flex;
          margin: .69rem 0;
          position: relative;
          padding-left: 2rem;

          &::before{
            content: '';
            background-image: url('../img/chevron-right.svg');
            background-position: left center;
            background-size: 1.3rem;
            background-repeat: no-repeat;
            @include portCenter;
            width: 1.69rem;
            height: 1.69rem;
            left: 0;
            //font-weight: $medium-bold;
          }

          a{
            &:link,
            &:visited{
              color: $gray-80s;
              transition: $trans;
              text-decoration: none;
            }

            &:hover,
            &:focus{
              outline: none;
              color: $color-orange-1;
            }
          }
        }
      }
    }

    .footform{

      input{
        width: $maxwidth;
        padding: 1rem;
        background-color: $gray-dark;
        border: 1px solid $gray-80s;
        color: $gray-ed;
        border-radius: 0;
        font-family: 'Red Hat Text', sans-serif;
        font-size: 1.55rem;

        &:focus {
          outline: none;
          background-color: $gray-dark;
      }

      &:focus:invalid {
          border: 1px solid $color-red;
      }

      }

      button{
        font-size: 2rem;
        background-color: $color-orange;
        color: $color-white;
        font-weight: $medium-bold;
        display: flex;
        padding: .69rem 5rem .69rem .69rem;
        border:none;
        position: relative;
        width: 73%;
        margin-top: 1.69rem;
        font-family: 'Red Hat Text', sans-serif;
        overflow: hidden;


        &::after{
          content: '';
          background-image: url('../img/chevron-right.svg');
          background-position: center;
          background-size: 2rem;
          background-repeat: no-repeat;
          @include portCenter;
          width: 5rem;
          height: $maxwidth;
          right: 0;
          background-color: darken($color-orange, 20%);
          transition: $trans;
        }

        &:hover{
          &::after{
            background-color: $color-orange-3;
          }
        }

      }
    }

  }

  .copyrights{
    border-top: 1px solid $gray-four;
    text-align: center;
    padding-top:4.4rem;
    color: $gray-80s;
    position: relative;
    display: flex;
    justify-content: space-between;

    @include respond(normal-tab) {
      display: grid;

    }


      p{
        text-align: center;
        color: $gray-80s;
        font-size: 1.55rem;
        display: flex;
        align-items: center;
        a{
          display: inline-flex;
          margin: 0 .44rem;
          &:link,
          &:visited{
            color: $gray-80s;
            transition: $trans;
            text-decoration: none;
          }

          &:hover,
          &:focus{
            outline: none;
            color: $color-orange-1;
          }
        }
      }

  }

}

.footmedias{
  display: inline-flex;
  width: max-content;
  background-color: $gray-dark;
  @include landCenter;
  padding: 0 1rem;
  top: -2.8rem;

  @include respond(normal-tab) {
    top: auto;
    position: relative;
    left:auto;
    transform: translateX(0);
    padding: 0;

  }
}

.floated-title{

  padding: 3rem;
  border-radius: 2rem;
  background-color: $color-white;
  transform: translateY(-6.3rem);
  display: grid;
  box-shadow: $shadow;
  position: relative;
  z-index: 10;
  //margin-top: -1rem;

  @include respond(phone) {
    padding: 2rem;
   }

  &.notrans{
    transform: translateY(0);
    margin-top: 6rem;
  }

  &.centered{
      h2,
      p{
        text-align: center;
      }
  }


  &.split{
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;

    @include respond(tab-land) {
     grid-template-columns: 1fr;

     h2,
     p{
       text-align: center;
     }
    }


    & picture{
      img{
        position: absolute;
        bottom: 3rem;
        z-index: 10;
        max-width: $maxwidth;
        //animation: landBottom 1s cubic-bezier(0.1, -0.6, 0.2, 0);
      }

      @include respond(tab-land) {
        display: none;
       }
    }

  }

  h2{
    font-size: 3rem;
    font-weight: $bold;
    margin-bottom: 2rem;

    @include respond(phone) {
      font-size:2.5rem;
    }

  }
}

.price-table{
  transform: translateY(-1rem);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 4rem;
  row-gap: 3rem;

  .pricePlans{
    padding:4rem 0 3rem 0;
    box-shadow: $shadow;
    border-radius: 1rem;
    background-color: transparentize($light-blue, .82);
    transition: $trans;
    position: relative;
    overflow: hidden;

    &::before{
      content: '';
      width: 73%;
      @include landCenter;
      height: 2rem;
      top: -1rem;
      z-index: 1;
      border-radius: 100px;
    }

    &.sky{
      &::before{
        background-image: $sky;
        animation: colorheight 2s ease 1s;
      }
    }

    &.sea{
      &::before{
        background-image: $sea;
        animation: colorheight 2s ease 2s;
      }
    }

    &.dusk{
      &::before{
        background-image: $dusk;
        animation: colorheight 2s ease 3s;
      }
    }

    &:hover{
      background-color: $color-white;
    }

    .planheading{

      text-align: center;
      margin-bottom: 3rem;

      h4{
        color: $color-orange;
        font-size: 1.9rem;
        font-weight: $bold;
      }

      h3{
        font-size: 2.8rem;
      }

      h2{
        font-size: 4.4rem;
        font-weight: $bold;
        margin: 2.5rem 0;

        small{
          font-size: 40%;
        }
      }

      .addon{
        display: flex;
        margin-top: 2rem;
        justify-content: center;

        &.white{
          color: $color-white;
        }
      }

     p{
       text-align: center;
       font-size: 1.81rem;
       span{
         display: block;
       }
     }

     .subscribe-btn{
       background-color: $gray-ed;
       display: flex;
       justify-content: center;
       margin: 2rem 0;

       a{
         padding: 1.3rem 3.3rem;
         background-color: $color-orange;
         text-transform: uppercase;
         border: none;
         color: $color-white;
         transition: $trans;
         font-size: 1.73;

         &:link,
         &:visited{
           text-decoration: none;
         }

         &:hover,
         &:focus,
         &:active{
           background-color: $gray-four;
         }
       }
     }

    }

    .pricebody{

      h4{
        background-color: $gray-ef;
        font-size: 1.9rem;
        font-weight: $semi-bold;
        padding: 1.2rem;
        text-align: center;
        margin: 2rem 0;
      }

      ul{
        color: $gray-mid;
        display: grid;
        padding: 0 2.5rem;
        line-height: 2;

        li{
          position: relative;
          padding-left: 2.8rem;
          display: flex;

          &::before{
            content: '\27A4';
            @include portCenter;
            left:0;
            color: $color-orange;
          }
        }
      }
    }
  }
}

.faqcolumns{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 3rem;
  align-items: baseline;
  align-content: flex-start;


}

#faquestions,
#faquestions02{

  .accordion-button{
    display: flex;
    font-size: 2rem;
    align-items: center;
    position: relative;
    margin: .69rem 0;
    outline: none;
    font-weight: $medium-bold;

    &::after{
      content: '\271B';
      background-image: none;
      color: $color-orange;
      font-size: 3rem;
      @include portCenter;
      right: 1.5rem;
      width: auto;
      height: auto;

    }

    &:focus{
      outline: none;
      box-shadow: none;
    }

    &:not(.collapsed){
      box-shadow: none;
      background-color: $color-white;
      color: $color-orange;
      &::after{
        content: '\268A';
        background-image: none;
        transform: rotate(0);
        font-size: 3rem;
        @include portCenter;
        right: 1.5rem;
        width: auto;
        height: auto;
      }

    }
  }

}

#industrial{

  .accordion-button{
    display: flex;
    font-size: 2rem;
    align-items: center;
    position: relative;
    margin: .69rem 0;
    outline: none;
    font-weight: $medium-bold;

    @include respond(tab-port) {
      padding-right: 4rem;
      font-size: $default-font-size;
    }

    &::after{
      content: '\271B';
      background-image: none;
      color: $color-orange;
      font-size: 3rem;
      @include portCenter;
      right: 1.5rem;
      width: auto;
      height: auto;

    }

    &:focus{
      outline: none;
      box-shadow: none;
    }

    &:not(.collapsed){
      box-shadow: none;
      background-color: $color-white;
      color: $color-orange;
      &::after{
        content: '\268A';
        background-image: none;
        transform: rotate(0);
        font-size: 3rem;
        @include portCenter;
        right: 1.5rem;
        width: auto;
        height: auto;
      }

    }
  }
  .accordion-body{
    p{
      text-align: left;
      padding-right: 5rem;
    }
  }
}


.featuresGrid{
  display: grid;
  grid-template-columns:1fr minmax(25%, auto);
  padding:0 3rem;
  box-shadow: $shadow;
  align-items: center;
  margin:1rem 0 5rem 0;
  column-gap: 1.5rem;
  overflow-x: hidden;

  @include respond(tab-land) {
    grid-template-columns:repeat(2, 1fr);
  }

  @include respond(medium-tab) {
    grid-template-columns:1fr;
  }


  &.mrauto{
    grid-template-columns:minmax(25%, auto) 1fr;

    @include respond(tab-land) {
      grid-template-columns:repeat(2, 1fr);
    }

    @include respond(medium-tab) {
      grid-template-columns:1fr;
    }
  }

  & img{
    max-width: $maxwidth;
    display: flex;
  }

  & article{
    @include respond(medium-tab) {
      padding: 2rem 0;
    }
  }

  h2{
    font-size: 3.7rem;
    font-weight: $bold;
    margin-bottom: 2rem;
    @include respond(tab-port) {
      font-size: 3rem;
    }

    @include respond(phone) {
      font-size:2.5rem;
    }

  }

  p{
    text-align: left;
  }

}

.pricingBlue{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;

  @include respond(medium-tab) {
    grid-template-columns: 1fr;
  }

  & a{
    background-color: $color-orange;
    padding:2.2rem 5.5rem;
    border-radius:.5rem;
    color: $color-white;
    transition: $trans;
    font-weight: $semi-bold;
    margin-left: auto;
    border: none;

    @include respond(medium-tab) {
      margin-right: auto;
      margin-left: 0;
      margin-top: 1rem;
    }

    @include respond(md) {
      padding: 1rem 3rem;
      font-size: $default-font-size;
    }

    &:link,
    &:visited{
        text-decoration: none;
    }

    &:hover,
    &:focus{
        background-color: $gray-light;
        color: $blue-dark;
    }
  }

  p{
    color: $light-blue;
    margin-left: 3rem;
    text-align: left;
  }

  h2{
    font-size: 3.5rem;
    font-weight: $bold;
    margin-bottom: 2rem;
    position: relative;
    color: $color-white;
    padding-left: 3rem;

    @include respond(medium-tab) {
      font-size: 2.8rem;
    }

    @include respond(phone) {
      font-size:2.2rem;
    }

    &::before{
      content: '';
      height: $maxwidth;
      width: .69rem;
      background-color: $color-orange;
      left: 0;
      z-index: 1;
      position: absolute;
    }
  }
}

// h4{
//   color: $color-white;
//   margin: 0;
//   text-align: left;
//   font-size: 1.9;
//   font-weight: $semi-bold;
// }


.linked-icons{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 2rem;
  position: relative;
  z-index: 5;
  row-gap: 2rem;
  text-transform: capitalize;

  @include respond(tab-port) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include respond(normal-tab) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include respond(sd) {
    grid-template-columns: 1fr;
  }


  .iconbox{
    background-color: transparentize($color-white, .69);
    padding: 1.5rem;
    display: flex;
    border: 1px solid $gray-c;
    border-radius: 1rem;
    align-items: center;
    transition: $trans;

    svg{
      width: 5.5rem;
      height: 5.5rem;
      margin-right: 2rem;
      transition: $trans;
    }

    &:link,
    &:visited{
      text-decoration: none;
      outline: none;
      color: $gray-dark;
    }

    &:hover{
      background-color: transparentize($color-white, .91);
      color: $color-orange;

      svg{
        transform: scale(1.1);
        margin-right: 1.5rem;
      }
    }

  }


}


.industry-wave-pattern{
  //min-height: 37rem;
  background-image: url(../img/Industrie_banner_pattern.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  margin-top: -20rem;
  padding-top: 19rem;
  padding-bottom: 6rem;
  z-index: 0;
  //z-index: 2;

  &::before{
    content: '';
    position: absolute;
    background-color: transparentize($gray-three, .2);
    width: $maxwidth;
    height: $maxwidth;
    left: 0;
    top: 0;
    z-index: 0;
  }

  &.forHome{

    margin: 0;
    padding: 6rem 0;
  }

.linked-icons{

  .iconbox{
    border: 1px solid $light-blue;


    &:link,
    &:visited{
      color: $color-white;
    }

    &:hover{
      background-color: transparentize($color-white, .91);
    }

    h4{
      color: $color-white;
      font-size: 1.9rem;
      font-weight: $semi-bold;
      margin: 0;
      text-transform: capitalize;
    }

  }

}

.justflex{
  z-index: 1;
  position: relative;
  margin-top: 4rem;
}

.sectitle_02{
  z-index: 2;
  position: relative;
}

}


.industry-details{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  margin-bottom: 4rem;

  & picture{
    img{
      max-width: $maxwidth;
    }
  }

  & article{
    padding:5rem 5rem 5rem 0;
    margin-right: -8rem;
    position: relative;
    z-index: 1;
    background-color: $color-white;
    box-shadow: $shadow;

    p{
      text-align: left;
      padding-left: 5rem;

      & strong{
        color: $color-orange;
        font-weight: $semi-bold;
      }
    }

    h2{
      font-size:3.5rem;
      font-weight: $bold;
      margin-bottom: 2rem;
      position: relative;
      padding-left: 5rem;
      text-transform: capitalize;

      &::before{
        content: '';
        height: $maxwidth;
        width: .69rem;
        background-color: $color-orange;
        left: 0;
        z-index: 1;
        position: absolute;
      }
    }
  }

  &.picleft{
    & article{
      margin-left: -8rem;
      margin-right: 0;
    }
  }
}


.solution-details{
  display: grid;
  padding-bottom:6rem;
  grid-template-columns: repeat(2, auto);
  column-gap: 4rem;
  border-bottom: $borders;
  scroll-margin-top: 10rem;

  @include respond(tab-port) {
    display: grid;
    grid-template-columns: 1fr;
    padding-bottom: 3rem;
  }

  &.nbbottom{
    border-bottom:none;
  }

  & figure{
    position: relative;
    display: inline-flex;
    flex-direction: column;
   // width: max-content;
    overflow-x: hidden;
    justify-content: center;

    @include respond(tab-port) {

      display: grid;
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
    }

    @include respond(phone) {
      grid-template-columns:1fr;
    }

    & img{
      max-width: $maxwidth;
      position: relative;
    }

    & figcaption{
      display: flex;
      flex-direction: column;
     // @include landCenter;
      z-index: 10;
      padding: 2rem;
      background-color: $blue-dark;
      width:82%;
      //bottom: -6rem;
      box-shadow: $shadow;
      color: $gray-light;
      transition: $trans;
      margin: -6.6rem auto 0 auto;

      @include respond(tab-port) {
        width:100%;
        bottom: 0;
        left: 0;
        transform: translateX(0);
        position: relative;
      }

      h2{
        display: flex;
        font-size:2.5rem;
        font-weight: $bold;
        margin-bottom:1.9rem;
        position: relative;
        align-items: center;
        text-transform: capitalize;

        span{
          display: inline-flex;
          background-color: transparentize($color-white, .55);
          width: 4rem;
          height: 4rem;
          margin-right: 1rem;
          align-items: center;
          justify-content: center;
          border-radius:$radius;
        }

        svg{
          height: 2.8rem;
          width: 2.8rem;

        }

      }

      p{
        text-align: left;
        color: $color-blue-5;
        margin: 0;

        & strong{
          color: $color-orange;
          font-weight: $semi-bold;
        }
      }

    }

    &:hover{

      & figcaption{
        background-color: $gray-dark;
        border-radius: 1rem;
        p{
          color: $gray-ed;
        }
      }
    }

  }

  & article{
    padding:3rem 0;
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    flex: 1;

    h3{
      font-size: 2.5rem;
      font-weight: $bold;
      margin-bottom: 1.9rem;
    }
  }

}



.weeklyUpdate{
  height: 22rem;
  display: grid;
  background-image: url(../img/subscribe_pattern.png), $dusk;
  @include bgCenter;
  @include respond(medium-tab) {
    height: auto;
  }

  .subscribecolumns{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 3rem;
    align-items: center;

    @include respond(medium-tab) {
      grid-template-columns: 1fr;
      row-gap: 2rem;
      padding: 2rem 0;
    }


      svg{
        height: 5rem;
        width:5rem;
        margin-right: 1rem;
      }

    h2{
      font-size:3.5rem;
      font-weight: $bold;
      margin-bottom: 2rem;
      color: $color-white;
      @include respond(tab-land) {
        font-size:3rem;
      }

      @include respond(phone) {
        font-size:2.5rem;
      }
    }
    p{
      color: $color-white;
      text-align: left;
      padding-left: 7rem;

      @include respond(tab-land) {
        padding-left: 0;
      }
    }

    .subgroup{
      position: relative;
      background-color: $color-white;
      width: max-content;
      border-radius: 100px;
      overflow: hidden;
      width: 44rem;
      margin-left: auto;
      display: flex;

      @include respond(medium-tab) {
        margin-left: 0;
        width: 100%;
      }

      button{
        padding: 1.7rem;
        background-color: $color-orange;
        @include portCenter;
        right: .3rem;
        border-radius: 100px;
        border: none;
        color: $color-white;
        transition: all .2s ease-in;

        &:hover{
          background-color: $gray-dark;
        }
      }

      input{
        background-color:$color-white;
        border: none;
        padding: 2rem;
        width: $maxwidth;

        &:hover,
        &:focus{
          outline: none;
        }

      }

    }

  }

}

.solutionlist{

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 3rem;
  row-gap: 3rem;
  margin-top: 1rem;

  @include respond(tab-land) {
    column-gap: 2rem;
  row-gap: 2rem;
  }


  @include respond(tab-port) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include respond(medium-tab) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include respond(md) {
    grid-template-columns: 1fr;
  }

  .listbox{
    padding: 2.5rem;
    border: 1px solid $gray-c;
    border-radius: 1rem;
    background-color: $color-white;
    transition: $trans;

    p{
      text-align: left;
      margin-bottom: 1.9rem;
    }

   & span{
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 7.3rem;
      width: 7.3rem;
      border-radius: 50%;
      border: .2rem solid $gray-dark;
      margin-bottom: 2rem;
      transition: $trans;

      & svg{
        height: 4.4rem;
        width: 4.4rem;
      }

    }

   & h4{
      font-size: 1.9rem;
      margin-bottom: 1.9rem;
      font-weight: $semi-bold;
      text-transform: capitalize;
    }


    &:hover{

      box-shadow: 0 0 2rem .11rem transparentize($color-black, .91);
      border-color: $color-white;
      transform: translateY(-.33rem);

      & span{
        border-color: $color-orange;
      }
    }

  }

}

.bottomnews{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  position: relative;
  column-gap: 3rem;

  @include respond(tab-land) {
    grid-template-columns: 1fr;
  }


  .newstitle{

    display: grid;
    align-content: center;

    h2{
      font-size:3.3rem;
      font-weight: $bold;
      margin-bottom: 2rem;

      @include respond(medium-tab) {
        font-size:3rem;
      }

      @include respond(phone) {
        font-size:2.5rem;
      }

    }
    p{
      text-align: left;
    }
  }

  & .newslider{
    .carousel{
      position: unset;

      &-inner{
        position: unset;
      }

      &-control{
        &-next,
        &-prev{
          position: unset;
          width: 5.5rem;
          height: 5.5rem;
          background-color: $color-white;
          box-shadow: $shadow;
          border-radius: 50%;
          margin-right: 2rem;
          opacity: 1;
          box-shadow: 0 0 1rem .44rem transparentize($color-black, .88);
          transition: $trans;
          &:last-child{
            margin-right: 0;
          }

          &:hover{
            background-color: $color-yellow;
            box-shadow: 0 0 .73rem .33rem transparentize($color-black, .91);
          }

        }
      }
    }
  }

  .slidecontrols{
    display: inline-flex;
    left: 0;
    bottom: 0;
    position: absolute;
    z-index: 100;

    @include respond(tab-land) {
      display: flex;
      justify-content: center;
      position: relative;
    }

  }
}

.blogcards{
  margin: 1.69rem;
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 3rem;

  @include respond(tab-land) {
    justify-content: center;
  }

  @include respond(md) {
    grid-template-columns:1fr;
    row-gap: 3rem;
  }

  &.threecards{
    grid-template-columns: repeat(3, auto);
    column-gap: 5rem;

    @include respond(tab-port) {
      column-gap: 3rem;
    }

    @include respond(normal-tab) {
      row-gap: 3rem;
      grid-template-columns:repeat(2, auto);
    }

    @include respond(medium-tab) {
      grid-template-columns:1fr;
    }

  }

  & .blogcard{

    box-shadow: 0 0 1.55rem .33rem transparentize($color-black, .89);
    overflow: hidden;
    border-radius: .73rem;

    & article{
      padding: 2rem;
      display: grid;

      p{
        text-align: left;
        margin: 2rem 0;
        color: $gray-dark;

        & strong{
          font-weight: $semi-bold;
          color: $color-orange;
        }
      }

    }

    ul.dates{
      display: flex;
      justify-content: space-between;
      color: $gray-mid;

      li{
        display: flex;
        align-items: center;
      }

      i{
        color: $color-orange;
        font-size: 2rem;
        margin-right: .69rem;
      }
    }

    & img{
      max-width: $maxwidth;
    }
  }

  & .bigcard{
    transition: $trans;
    box-shadow: $shadow;
    overflow: hidden;
    border-radius: .73rem;

    & picture{
      display: flex;
    }

    & article{
      padding: 3rem;

      p{
        text-align: left;
      }

      h4{
        font-size: 2rem;
        font-weight: $semi-bold;
        margin-bottom: 1.69rem;

        & a{

          &:link,
          &:visited{
            text-decoration: none;
            color: $gray-dark;

            &:hover,
            &:focus{
              outline: none;
              color: $color-orange;
            }
          }
        }
      }

      h5{
        display: flex;
        align-items: center;
        font-size: 1.5rem;
        color: $gray-80s;
        margin: 2rem 0 0 0;
        img{
          height: 4rem;
          display: inline-flex;
          width: 4rem;
          border-radius: 50%;
          margin-right: 1rem;
        }
      }
    }

    &:hover{
      transform: translateY(-.5rem);
      box-shadow: 0 0 2rem .3rem transparentize($color-black, .91);;
    }

  }

}

// new home page

.heroSlides{

  .carousel-inner{
    height: 77vh;

    @include respond(tab-port) {
      height: 55vh;
    }

    @include respond(medium-tab) {
      height: 47vh;
    }
  }

  .carousel-item{
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 77vh;
      width: $maxwidth;

      @include respond(tab-port) {
        height: 55vh;
      }

      @include respond(medium-tab) {
        height: 47vh;

       & button{
          margin:1rem auto;
        }
      }

      & h2{
        font-size: 6rem;
        font-weight: $semi-bold;
        color: $color-white;
        animation: movetoBottom 3s ease-out;

        & span{
          display: block;
        }

        @include respond(tab-land) {
          font-size: 5rem;
        }

        @include respond(tab-port) {
          font-size: 4rem;
        }

        @include respond(medium-tab) {
          font-size:3.3rem;
          text-align: center;

        }

        @include respond(phone) {
          font-size: 2.5rem;

        }
      }

      &.slide_001{
        background-image: url(../img/slider1.jpg);
        @include bgTop;
      }

      &.slide_002{
        background-image: url(../img/slider2.jpg);
        @include bgTop;
      }

    }

    .carousel-control-next,
    .carousel-control-prev{
      width: 8.2rem;

      @include respond(medium-tab) {
        width: 5rem;
      }

    }
  }

  .homecards{
    display: grid;

    &.spited{
      grid-template-columns: 1fr 45rem;
      column-gap: 3rem;

      @include respond(normal-tab) {
        grid-template-columns: 1fr;
        row-gap: 3rem;

      }
    }

    @include respond(newphones) {
      a{
        margin: auto;
      }

    }

    & article,
    & picture{
      position: relative;
      z-index: 1;
      display: grid;
      align-content: flex-end;
    }

    & article{
      padding: 4rem;
    }


    h3{
      color: $color-white;
      font-size: 3.3rem;

      @include respond(newphones) {
        font-size: 2.5rem;

      }

    }

    & img{
      max-width: $maxwidth;
    }

    & .cardorange{
      background-image: url(../img/solution_link_bg.jpg);
      @include bgCenter;
      position: relative;
      height: 31rem;
      display: grid;
      align-content: center;
      transition: $trans;

      &::before{
        content: '';
        width: $maxwidth;
        height: $maxwidth;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-image:$transdusk;
        @include bgCenter;
        z-index: 0;
        transition: $trans;
      }

      &:hover{
        &::before{
        background-image:linear-gradient(to top, transparentize($color-orange-1, .11),transparentize($color-red-1, .11));
        }
      }
    }

    & .cardocean{
      background-image: url(../img/price_link_bg.jpg);
      @include bgCenter;
      position: relative;
      height: 31rem;
      display: grid;
      align-content: center;
      transition: $trans;

      &::before{
        content: '';
        width: $maxwidth;
        height: $maxwidth;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-image:$ocean;
        @include bgCenter;
        z-index: 0;
        transition: $trans;
      }

      &:hover{
        &::before{
        background-image:linear-gradient(to bottom, transparentize($color-water-1, .11),transparentize($color-water-2, .11));
        }
      }
    }

    &.cardblue{
      background-image: url(../img/feature_link_pattern.png);
      background-repeat: no-repeat;
      background-position: top right;
      background-color: $color-blue-7;
      grid-template-columns: repeat(2, auto);
      column-gap: 6rem;
      position: relative;
      margin-bottom: 3rem;
      transition: $trans;

      @include respond(medium-tab) {
        grid-template-columns: auto;

        & article,
        & picture{
          display: grid;
          justify-content: center;
          text-align: center;
        }
      }

      &::before{
        content: '';
        width: $maxwidth;
        height: $maxwidth;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-image: $deepsea;
        @include bgCenter;
        z-index: 0;
        transition: $trans;
      }

      &:hover{
        &::before{
        background-image:linear-gradient(to right top, $color-blue-6, transparent);
        }
      }

      & picture{
        padding: 1rem 0 0 4rem;
      }
    }

    &.cardyellow{
      background-image: url(../img/benefits_pattern.png);
      background-repeat: no-repeat;
      background-position: bottom left;
      background-color: $color-yellow-1;
      grid-template-columns: repeat(2, auto);
      column-gap:4rem;
      position: relative;
      border-radius: 2rem;
      overflow: hidden;

      @include respond(normal-tab) {
        grid-template-columns: 1fr;

      }

      h2{
        font-size: 3.3rem;
        font-weight: $bold;
        margin-bottom: 2rem;

        @include respond(phone) {
          font-size: 2.5rem;

        }

        span{
          color: $color-red;
        }
      }

      &::before{
        content: '';
        width: $maxwidth;
        height: $maxwidth;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-image: $orange-yellow;
        @include bgCenter;
        z-index: 0;
      }

      .txts{
        display: grid;
        z-index: 2;
        padding: 4rem;
        align-content: center;
      }

      & picture{
        position: relative;
        display: flex;
        justify-content: center;
        padding-right: 4rem;

        & img{
          position: relative;
          z-index: 10;
          max-height: 39rem;
        }

        &::before{
          content: '';
          width: 29rem;
          height: 29rem;
          @include portCenter;
          background-color: $color-white;
          z-index:2;
          border-radius: 50%;
          animation: orangeCircle 4s ease 3s infinite alternate;
        }
        &::after{
          content: '';
          width: 35rem;
          height: 35rem;
          @include portCenter;
          background-color:transparentize($color-white, .55);
          z-index: 1;
          border-radius: 50%;
          animation: orangeCircle-le 4s ease-in-out 3s infinite alternate;
        }
      }
    }

  }

  .solutionotes{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    margin-top: 6rem;
    column-gap: 6rem;

    @include respond(tab-port) {
      grid-template-columns: 1fr;
      row-gap: 2rem;
      justify-content: center;
      text-align: center;

    }

    h1{
      font-weight: $bold;
      font-size: 3.3rem;
      color: $blue-dark;

      @include respond(medium-tab) {
        font-size:2.8rem;

      }

      @include respond(newphones) {
        font-size:2.5rem;

      }
    }

    h5{
      color: $color-orange;
      font-size: 2rem;
      margin-bottom: 1.69rem;
      font-weight: $semi-bold;
    }
    p{
      color: $gray-dark;
      margin-bottom: 0;
      @include respond(tab-port) {
        text-align: center;

      }
    }
  }

  .homethumbs{

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 3.3rem;
    row-gap: 3.3rem;

    @include respond(tab-land) {
      column-gap: 2.3rem;
    row-gap: 2.3rem;

    }

    @include respond(tab-port) {
      grid-template-columns: repeat(3, 1fr);

    }

    @include respond(medium-tab) {
      grid-template-columns: repeat(2, 1fr);

    }

    @include respond(md) {
      grid-template-columns:1fr;
    }

   & figure{
      position: relative;
      display: flex;
      border-radius: .69rem;
      overflow: hidden;
      margin: 0;

     & figcaption{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        width: $maxwidth;
        height: $maxwidth;
        color: $color-white;
        background-image: linear-gradient(to bottom, transparent, transparentize($color-black, .44));
        z-index: 1;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        padding:1rem 1rem 3rem 3rem ;
        transition: $trans;

        h4{
          font-size: 2.2rem;
          margin-bottom:0;
          transition: $trans;
          text-transform: capitalize;
        }

        p{
          color: $gray-c;
          font-size: 1.4rem;
          transition: $trans;
        }

        a{
          padding:.33rem 1.69rem;
          font-size:1.4rem;
          width: max-content;
          background-color: transparent;
          transition: $trans;
          opacity: 0;
          margin-top: 1.5rem;
          margin-bottom: -5rem;

          &:link,
          &:visited{
            text-decoration: none;
            color: $color-white;
          }
        }
      }

      img{
        flex: 1;
        display: flex;
        transition: $trans;
      }

      &:hover{

        img{
          transform: scale(1.1);
        }

       & figcaption{
        background-image: linear-gradient(to bottom, transparent, transparentize($color-black, .22));

        a{
          display: flex;
          background-color: $color-orange;
          opacity: 1;
          margin-bottom: 0;

          &:hover{
            background-color: $color-orange-1;
          }
        }
      }
    }

    }
  }

  .pricingOrange{

    margin-top: 6rem;
    padding-top: 1rem;
    background-image:url(../img/pricing_bg_pattern.png);
    background-position: right top;
    background-repeat: no-repeat;
    position: relative;
    background-size: $maxwidth;
    background-color: $color-orange-2;

    @include respond(tab-land) {
      background-size: cover;
    }


    &::before{
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      left: 0;
      top: 0;
      width: $maxwidth;
      height: $maxwidth;
      background-image: $dawn;
      z-index: 0;
    }

    .homePricing{
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;

      @include respond(medium-tab) {
        grid-template-columns:1fr;

      }

      picture{
        position: relative;
        display: flex;
        overflow: hidden;
        padding: 1rem 6rem 0 6rem;

        img{
          z-index: 3;
          margin: auto;
          display: flex;
          max-width: $maxwidth;
        }

        &::after{
          content: '';
          width: 44rem;
          height: 44rem;
          @include landCenter;
          background-color:$color-red;
          z-index: 1;
          border-radius: 50%;
          bottom: -50%;
          animation: orangereds 10s ease infinite alternate;
        }
      }

      article,
      picture{
        z-index: 2;
        @include respond(medium-tab) {
          display: grid;
        }
      }

    }
h3{
    color: $color-white;
    font-size: 3.3rem;
    font-weight: $bold;
    animation: serviceBox 3s ease-out;

    @include respond(medium-tab) {
      text-align: center;
      margin-top: 2rem;

    }

    @include respond(phone) {
      font-size:2.5rem;

    }

    & span{
      display: block;
    }

}

p{
  color: $gray-light;
  margin: 2rem 0;
  @include respond(medium-tab) {
    text-align: center;

  }
}

    & a{
      background-color: $color-white;
      padding:2.2rem 5.5rem;
      border-radius:.5rem;
      color: $gray-dark;
      transition: $trans;
      font-weight: $semi-bold;
      border: none;
      display: inline-flex;

      @include respond(normal-tab) {
        margin: auto;
      }

      @include respond(md) {
        padding: 1rem 3rem;
        font-size: $default-font-size;
      }

      &:link,
      &:visited{
          text-decoration: none;
      }

      &:hover,
      &:focus{
          background-color:transparentize($color-white, .11);
          color: $color-orange;
      }
    }

  }

  .contactus{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 4.4rem;
    margin-top: 1rem;

    @include respond(tab-land) {
      column-gap: 2rem;
    }

    @include respond(tab-port) {
      grid-template-columns: 1fr;
      row-gap: 3rem;
    }

    & .formside,
     & address{
          padding: 4rem;

          @include respond(phone) {
            padding: 2rem;
          }

          p{
            text-align: left;
          }

          h2{
            font-size: 2.8rem;
            font-weight: $bold;
            margin-bottom: 2rem;
          }

          h4{
            font-size: 2.2rem;
            font-weight: $semi-bold;
            margin-bottom: 1.5rem;
          }

          h5{
            font-size: 1.72rem;
            margin:3rem 0 2rem 0;
          }

    }

    & address{
      box-shadow: $shadow;
      background-color: $color-white;

      hr{
        display: flex;
      }

      p{
        margin-bottom: 3.3rem;
      }

    }

    .formside{
      background-color: $gray-ef;
      transition: $trans;

      &:hover{
        background-color: darken($gray-ef, 3%);
      }

      form{
        margin-top: 2rem;
      }

      .form-label{
        color: $gray-mid;
      }

      .form-control{

        &-lg{
          font-size: $default-font-size;
          padding: .91rem;
          border-radius: .5rem;
        }
      }
      .large-btn{
        padding-left: 6rem;
        padding-right: 6rem;
        text-transform: uppercase;
      }
    }

  }

  .indus_details{
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 5rem;

    @include respond(tab-land) {
      column-gap: 3rem;
      grid-template-columns: 1fr 1fr;

    }

    @include respond(tab-port) {
      grid-template-columns:auto;
      row-gap: 3rem;
    }


    & article{
      display: grid;
      align-content: center;

      strong{
        font-weight: $semi-bold;
      }

      h2{
        font-weight: $bold;
        font-size: 3.3rem;
        margin-bottom: 2rem;
      }
    }

    & picture{

      display: flex;
      position: relative;
      padding:0 0 5rem 5rem;
      transition: $trans;

      @include respond(tab-land) {
        padding:0 0 2rem 2rem;
      }

      @include respond(tab-port) {
        justify-content: center;
        padding:2rem;
      }

      @include respond(phone) {
        padding:1rem;
      }

      &::before{
        content: '';
        position: absolute;
        height: 82%;
        width: 82%;
        left: 0;
        bottom: 0;
        background-image: linear-gradient(to right bottom, $color-yellow-2, $color-orange);
        z-index: 0;
        transition: $trans;

        @include respond(tab-port) {
          height: 100%;
          width: 100%;
        }
      }

      & img{
        max-width: $maxwidth;
        z-index: 1;
      }

      &:hover{
        &::before{
          width: 91%;
          height: 91%;
           background-image: radial-gradient($color-orange, $color-yellow-2);
           @include respond(tab-port) {
            height: 100%;
            width: 100%;
          }
          }
    }

    }
  }

  .bordered_columns{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 4rem;
    margin-top: 6rem;

    @include respond(normal-tab) {
      grid-template-columns: 1fr;
      row-gap: 3rem;
    }

    & .box{
      border: $borders;
      padding: 3rem;
      border-radius: 1rem;
      text-align: center;


      .numb{
        position: relative;
        display: flex;
        width: max-content;
        margin:2rem auto 4rem auto;
        transition: $trans;

        span{
          font-size: 2.8rem;
          font-weight: $bold;
          height: 6rem;
          width: 6rem;
          border-radius: 50%;
          color: $color-white;
          background-color: $color-orange-3;
          //margin-bottom: 2.8rem;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          z-index: 10;
          transition: $trans;
        }

        &::before{
          content: '';
          background-color: transparentize($color-orange, .5);
          width: 8rem;
          height:8rem;
          border-radius: 50%;
          @include absCenter;
          z-index: 0;
          animation:orangeCircle-sm-2 3s ease infinite alternate;
        }

        &::after{
          content: '';
          background-color: transparentize($color-orange, .82);
          width: 10rem;
          height: 10rem;
          border-radius: 50%;
          @include absCenter;
          z-index:-1;
          animation:orangeCircle-sm 3s ease infinite alternate;
        }

      }


      p{
        text-align: center;
        margin-bottom: 0;
      }

      h4{
        font-size: 2rem;
        font-weight: $semi-bold;
        margin-bottom: 2rem;
        text-transform: capitalize;
      }

      &:hover{
        background-color: transparentize($gray-mid, .93);
        border-color: transparentize($gray-mid, .93);
        & .numb{
          transform: translateY(.33rem);
          & span{
            background-color: $color-red;
          }
        }
      }
    }
  }

  .bloglayout{
    display: grid;
    grid-template-columns: minmax(auto, 75rem) 1fr;
    column-gap: 6rem;

    @include respond(tab-land) {
      grid-template-columns: 1fr;
      row-gap: 3rem;
    }

    .breadcrumbs{
      display: flex;
      align-items: center;
      margin-bottom: 1.69rem;

      & ul{
        display: flex;
        position: relative;
        width: $maxwidth;

        &::after{
          content: '';
          height: 1px;
          background-color: $gray-c;
          width: $maxwidth;
          right: 0;
          @include portCenter;
          z-index: 0;
        }

        & li{
          position: relative;
          padding:0 1rem;
          background-color: $color-white;
          z-index: 1;

          a{
            &:link,
            &:visited{
              color: $gray-mid;
              width: 4rem;
              text-decoration: none;
            }

            &:hover,
            &:focus{
              outline: none;
              color: $color-orange;
            }
          }

          &::before{
            content: '/';
            @include portCenter;
            left: 0;
          }

          &:first-child{
            padding-left: 0;
            &::before{
              display: none;
            }
          }
        }
      }
    }

    & article{

      & .titlepic{
        display: flex;
        position: relative;
        overflow: hidden;
        margin-bottom: 3rem;

        & figcaption{
          display: inline-flex;
          width: max-content;
          background-color: $color-orange;
          color: $color-white;
          position: absolute;
          left: 0;
          bottom: 0;
          padding:.69rem 1.69rem;
        }
      }

      .blogtitles{

        font-size: 2.69rem;
        font-weight: $semi-bold;
        margin-bottom: 3rem;
        transition: $trans;

        @include respond(normal-tab) {
          font-size: 2.33rem;
          margin-bottom: 2rem;
        }


        &-2{
          margin-top: 3rem;
          font-size: 2.55rem;

          @include respond(normal-tab) {
            font-size: 2.33rem;
            margin-top: 2rem;
          }

        }

        & a{

          &:link,
          &:visited{
            text-decoration: none;
            color: $gray-dark;

            &:hover,
            &:focus{
              outline: none;
              color: $color-orange;
            }
          }
        }

        &.center{
          text-align: center;
          padding: 0 1rem;
        }
      }

      p{
        text-align: left;
      }
    }


    img{
      max-width: $maxwidth;
      display: flex;
      transition: $trans;

      &:focus{
        outline: none;
      }
    }

    & aside{
      display: flex;
      flex-direction: column;

      & picture{
        overflow: hidden;
        display: flex;
      }

      & .list{
        display: grid;
        grid-template-columns: 19rem auto;
        column-gap:2rem;
        padding: 1.69rem 0;
        border-bottom: $borders;
        align-items: center;
        text-decoration: none;

        &:first-child{
          padding-top: 0;
        }

       & .txts{
         // max-width: 28rem;

         & h4{
            font-size: 2rem;
            margin: 1rem 0;
            font-weight: $medium-bold;
            color: $gray-dark;
            transition: $trans;
          }

         & p{
            font-size: 1.55rem;
            text-align: left;
            margin: 0;
          }
        }
        &:focus,
        &:hover{
          outline: none;
          h4{
            color: $color-orange;
          }

          img{
            transform: scale(1.1);
          }
        }

      }
    }
  }

  .blognews{
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @include respond(normal-tab) {
      grid-template-columns: 1fr;
    }


    & picture{
      background-image:$bluegray, url(../img/newsletter_bg.jpg);
      @include bgCenter;
      position: relative;
      background-blend-mode: hard-light;

      @include respond(normal-tab) {
        height: 30rem;
        @include bgTop;
      }

    }

    & .blogform_01{
      padding:5rem 6rem;
      background-color: $gray-dark-3;
      color: $color-white;
      display: grid;
      align-content: center;

      @include respond(phone) {
        padding: 2rem;
      }

      input{
        background-color: $gray-dark-3;
        border: 1px solid $gray-mid;
        border-radius: .5rem;
        padding: 1.55rem;
        width: $maxwidth;
        margin: 1.69rem 0;
        color: $gray-light;

        &:hover,
        &:focus{
          outline: none;
        }

        &:focus{
          &:invalid {
            border-color: $color-red;
        }
        }

      }

      h2{
        font-size: 2.8rem;
        font-weight: $bold;
        margin-bottom: 2rem;

        @include respond(phone) {
          font-size: 2.5rem;
        }

      }
    }

    &.forside{
      grid-template-columns: 1fr;
      margin-top: 3rem;

      & .blogform_01{
        padding: 3rem;

        h2{
          text-align: center;
        }
      }

      & picture{
        height: 22rem;

      }
    }
  }


.blogform_02{
    padding:3rem;
    background-color: $gray-dark-3;
    color: $color-white;
    display: grid;
    align-content: center;
    margin-top: 3rem;

    input{
      background-color: $gray-dark-3;
      border: 1px solid $gray-mid;
      border-radius: .5rem;
      padding: 1rem;
      width: $maxwidth;
      margin: 1.33rem 0;
      color: $gray-light;

      &:hover,
      &:focus{
        outline: none;
      }

      &:focus{
        &:invalid {
          border-color: $color-red;
      }
      }

    }

    h2{
      font-size: 2.8rem;
      font-weight: $bold;
      margin-bottom: 2rem;
      text-align: center;
    }
  }


  // pricing new

  .price-table-new{
    transform: translateY(-1rem);
    display: grid;
    grid-template-columns: auto repeat(3, 1fr);
    column-gap: 1px;
    padding: 1px;
    background-color: $gray-c;

    @include respond(tab-port) {
      grid-template-columns:repeat(2, 1fr);
      row-gap: 3rem;
      background-color: $color-white;
    }

    @include respond(phone) {
      grid-template-columns:1fr;
    }

    .pricePlans{
      padding:4rem 1rem 0 1rem;
      background-color:$tablebg;
      transition: $trans;
      position: relative;
      overflow: hidden;
      background-size: cover;

      &::before{
        content: '';
        width: 82%;
        @include landCenter;
        height: 2rem;
        top: -1rem;
        z-index: 1;
        border-radius: 100px;
        transition: $trans;
      }

      &.autumn{
        &::before{
          background-image: $autumn;
        }
      }

      &.silver{
        &::before{
          background-image: $silver;
        }
      }

      &.gold{
        &::before{
          background-image: $gold;
        }
      }

      &.platinum{
        &::before{
          background-image: $platinum;
        }
      }

      &.sky{
        &::before{
          background-image: $sky;
        }
      }

      &.sea{
        &::before{
          background-image: $sea;
        }
      }

      &.dusk{
        &::before{
          background-image: $dusk;
        }
      }

      &:hover{
        background-color: $color-white;

        &::before{
          width: 91%;
        }
      }

      .planheading{
        display: grid;
        text-align: center;
        height: 25rem;
        align-content: center;

       & h4{
          color: $color-orange;
          font-size: 1.9rem;
          font-weight: $bold;
        }

      &  h3{
          font-size: 2.8rem;
          font-weight:$bold;

          &.orange{
            color: $color-orange;
            margin-bottom: 1.69rem;
          }
        }

        & h2{
          font-size: 3.3rem;
          font-weight: $bold;
          margin: 2.5rem 0;

          & sup{
            font-size: 55%;
          }
        }

        & .addon{
          display: flex;
          margin-top: 2rem;
          justify-content: center;

          &.white{
            color: $color-white;
          }
        }

      & p{
         text-align: center;
         font-size: 1.81rem;
         span{
           display: block;
         }
       }

         .subscribe-btn{
           padding: 1rem 3.3rem;
           background-color: $gray-dark-3;
           text-transform: uppercase;
           border: none;
           color: $color-white;
           transition: $trans;
           font-size: 1.73;
           display: flex;
          justify-content: center;
          margin: 2rem 0;

           &:link,
           &:visited{
             text-decoration: none;
           }

           &:hover,
           &:focus,
           &:active{
             background-color: $color-orange;
             outline: none;
           }
         }
       }


      .pricebody{

        h4{
          background-color: $gray-ef;
          font-size: 1.9rem;
          font-weight: $semi-bold;
          padding: 1.2rem;
          text-align: center;
          margin: 2rem 0;
        }

        ul{
          display: grid;
          color: $gray-mid;
          border-top: $borders;
          margin-top: 1rem;
          &.thick{
            font-weight: $semi-bold;
            color: $gray-dark;
          }

          li{
            display: flex;
            border-bottom: $borders;
            padding: 1rem 0;

            &:last-child{
              border-bottom: none;
            }

          }
        }
      }
    }
  }


  .faqOrangeBox{
    background-image:url(../img/faq_pattern_bg.png);
    background-position: left top;
    background-repeat: no-repeat;
    position: relative;
    background-size: $maxwidth;
    background-color: $color-orange-2;
    overflow: hidden;
    border-radius: 1rem;
    text-align: center;
    padding: 6rem 3rem;


    &::before{
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      left: 0;
      top: 0;
      width: $maxwidth;
      height: $maxwidth;
      background-image: $dawn;
      z-index: 0;
    }

p{
  color: $color-white;
  max-width: 82rem;
  margin: 0 auto;
  font-size: 1.91rem;
  font-weight: $semi-bold;
  text-align: center;
  z-index: 1;
  position: relative;
}

    & a{
      background-color: $gray-dark;
      padding:1rem 4rem;
      border-radius:$radius;
      color: $color-white;
      transition: $trans;
      font-weight: $semi-bold;
      border: none;
      display: inline-flex;
      z-index: 10;
      position: relative;
      margin-top: 3rem;

      &:link,
      &:visited{
          text-decoration: none;
      }

      &:hover,
      &:focus{
          background-color:$color-white;
          color: $color-orange;
      }
    }

  }


  .supportlist{

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 4rem;
    row-gap: 4rem;

    @include respond(tab-land) {
      column-gap: 2rem;
      row-gap: 2rem;
    }

    @include respond(normal-tab) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include respond(phone) {
      grid-template-columns:1fr;
    }


    .listcolumn{
      padding: 3rem;
      box-shadow: 0 0 1.55rem .11rem transparentize($color-black, .89);
      border-radius: 1rem;
      background-color: $color-white;
      transition: $trans;

      & svg{
        height: 3.3rem;
        width: 3.3rem;
      }

      & h4{
        font-size: 2rem;
        font-weight: $semi-bold;
        text-transform: capitalize;
        margin: 2rem 0;
        transition: $trans
      }

        &:link,
        &:visited,
        &:hover,
        &:focus{
          text-decoration: none;
          outline: none;

      p{
        text-align: left;
        margin:0;
      }

     & h4{
        color: $gray-dark;
      }
    }

      &:hover{
        transform: translateY(-.69rem);
        box-shadow: 0 0 2.8rem .11rem transparentize($color-black, .91);
        & h4{
          color: $color-orange;
        }
      }

    }

    &.titles{
      column-gap: 3rem;
      row-gap: 3rem;

      & .listcolumn{
        display: grid;
        grid-template-columns: auto 1fr;
        column-gap: 1.5rem;
        padding: 2rem;
        align-items: center;
        border-radius: .69rem;

        & svg,
        & h4{
          margin: 0;
          display: inline-flex;
        }

        &:hover,
        &:focus,
        &:active,
        &.active{
          background-color: $gray-dark-1;

          & h4{
            color: $color-white;
          }
        }
      }
    }

  }

  .moreSupport{
    margin: 5.5rem 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 5.5rem;

    @include respond(tab-land) {
      column-gap: 2rem;
    }

    @include respond(normal-tab) {
      grid-template-columns: 1fr;
    }

    .supportform{
      background-color:transparentize( $light-pink, .33);
      padding: 5rem;
      border-radius: 1rem;
      display: grid;
      transition: $trans;

      @include respond(tab-land) {
        padding: 3rem;
      }

      @include respond(phone) {
        padding:2rem;
      }

      &:hover{
        background-color:transparentize($light-pink, .11) ;
      }


      h3{
        font-size: 2.8rem;
        text-align: center;
        margin-bottom: 2rem;
        font-weight: $bold;
      }

      .form-label{
        color: $gray-dark-3;
        margin-bottom: 1rem;
      }

      .form-control,
      .form-select{

        &-lg{
          font-size: $default-font-size;
          padding: .91rem;
          border-radius: .5rem;
          color: $gray-dark-3 !important;
          border-color: transparent;
        }

        &:focus{
          box-shadow: none;
        }
      }
      .large-btn{
        padding: .69rem 3rem;
        margin-top: 1rem;
      }

    }

  }
  .contactSupport{
    background-image: url(../img/conatct-support_bg.jpg);
    @include bgCenter;
    height:37rem;
    background-color: $light-pink-2;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-radius: 2rem;
    overflow: hidden;

    @include respond(phone) {
      justify-content: center;
    }

    .txts{
      width: $maxwidth;
      max-width: 50rem;

      @include respond(phone) {
        justify-items: center;
        display: grid;
      }

      p{
        font-size: 2rem;
        color: $gray-dark;
        font-weight: $medium-bold;
        margin-bottom: 1.6rem;
      }
      h3{
        font-size: 3.3rem;
        font-weight: $bold;
        margin-bottom:1.69rem;
      }

      a{
        display: inline-flex;
        padding: .82rem 3.3rem;
        background-color: $color-white;
        font-weight: $semi-bold;
        border-radius: $radius;
        transition: $trans;

        &:link,
        &:visited{
          text-decoration: none;
          color: $gray-dark;

          &:hover,
          &:focus{
            background-color: $color-orange-3;
            color: $color-white;
          }
        }
      }
    }
  }
  .helcenterContent{
    padding-top: 3rem;

    ul{
      margin-bottom: 1rem;
    }

    h4{
      font-size: 2rem;
      font-weight: $bold;
      margin: 1.9rem 0;
      color: $color-orange;
    }

    h6{
      font-size: 1.5rem;
      font-weight: $semi-bold;
      margin-top: 1rem;
      color: $color-blue-7;
    }

    h5{
      font-weight: $semi-bold;
      font-size: 1.72rem;
    }
  }

  .homerecruitment{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 4rem;
    align-items: center;

    @include respond(normal-tab) {
      grid-template-columns:1fr;
    }

    .sectitle_01{
      justify-content: left;
      text-align: left;
      @include respond(normal-tab) {
        justify-content: center;
        text-align: center;
      }
      & .underline-links,
      & h2,
      & p{
        text-align: left;
        @include respond(normal-tab) {
          text-align: center;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
.recruitboxes{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
  row-gap: 2rem;
  @include respond(newphones) {
    grid-template-columns:1fr;
  }
  & .box{
    padding: 1.5rem;
    box-shadow: $shadow2;
    text-align: center;
    border-radius: 1rem;
    background-color: $color-white;
    display: grid;
    align-items: center;
    justify-items: center;
    transition: $trans;
    &:hover{
      transform: translateY(-1rem);
    }

    & h4{
      margin: 1.5rem 0;
      font-size: 1.9rem;
    }

    & p{
      text-align: center;
    }

    & span{
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 6.9rem;
      width: 6.9rem;
      border-radius: 50%;

      & svg{
        width: 4rem;
        height: 4rem;
      }
    }
  }
}
.employboxes{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
  row-gap: 2rem;
  padding-top: 2rem;
  border-top: $borders;

  @include respond(newphones) {
    grid-template-columns:1fr;
  }

  & .box{
    padding: 1.5rem;
    border-radius: $radius;
    display: flex;
    align-items: center;
    transition: $trans;
    column-gap: 2rem;

    &:first-child{
      background-color: transparentize($color-orange-4, .91);
    }
    &:nth-child(2){
      background-color: transparentize($color-blue-9, .91);
    }
    &:nth-child(3){
      background-color: transparentize($color-green, .91);
    }
    &:last-child{
      background-color: transparentize($color-blue-8, .91);
    }

    &:hover{
      transform: translateX(-1rem);
    }

    & h4{
      margin: 1.2rem 0;
      font-size: 1.9rem;
    }

    & span{
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 7.3rem;
      width: 7.3rem;
      border-radius: $radius;

      & svg{
        width: 5rem;
        height: 5rem;
      }
    }
  }
}
.procurementboxes{
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @include respond(newphones) {
    grid-template-columns:1fr;
  }

  & .box{
    padding: 2rem;
    //border-radius: $radius;
    display: grid;
    align-items: center;
    transition: $trans;

    &:first-child{
      text-align: right;
      justify-content: right;
      justify-items: end;
      border-right: $borders;
      @include respond(newphones) {
        border-right: none;
      }
      // &:hover{
      //   transform: translateY(1rem);
      // }
    }
    &:nth-child(2){
      @include respond(newphones) {
        border-top: $borders;
      }
    }
    &:nth-child(3){
      text-align: right;
      justify-content: right;
      justify-items: end;
      border-right: $borders;
      border-top: $borders;
      @include respond(newphones) {
        border-right: none;
      }
      // &:hover{
      //   transform: translateX(-1rem);
      // }
    }
    &:last-child{
      border-top: $borders;
      // &:hover{
      //   transform: translateY(1rem);
      // }
    }

    // &:hover{
    //   & span{
    //     background-color: darken(currentColor, 10%);
    //   }
    // }

    & h4{
      margin: 1.2rem 0;
      font-size: 1.9rem;
    }

    & span{
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 6.6rem;
      width: 6.6rem;
      border-radius: $radius;

      & svg{
        width: 4rem;
        height: 4rem;
        fill: $gray-dark-1;
      }
    }
    @include respond(newphones) {
      border-bottom: $borders;
    }
  }
}
.homecrm{
  display: grid;
  grid-template-columns: minmax(auto, 40rem) 1fr;
  column-gap: 4rem;
  align-items: center;

  @include respond(normal-tab) {
    grid-template-columns:1fr;
  }


  .sectitle_01{
    justify-content: left;
    text-align: left;
    @include respond(normal-tab) {
      justify-content: center;
      text-align: center;
    }
    & .underline-links,
    & h2,
    & p{
      text-align: left;
      @include respond(normal-tab) {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
.crmboxes{
display: grid;
grid-template-columns: repeat(2, 1fr);
column-gap: 2rem;
row-gap: 2rem;
@include respond(newphones) {
  grid-template-columns:1fr;
}

& .box{
  padding: 1.5rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  transition: $trans;
  column-gap: 1.5rem;
  &:first-child{
    & span{
      background-color: $color-orange-2;
    }
  }
  &:nth-child(2){
    & span{
      background-color:$color-blue-8;
    }
  }
  &:nth-child(3){
    & span{
      background-color:$color-peach;
    }
  }
  &:last-child{
    & span{
      background-color: $color-aqua-2;
    }
  }
  &:hover{
    transform: translateX(1rem);
  }

  & h4{
   // margin: 1.5rem 0;
    font-size: 1.9rem;
  }

  & p{
    text-align: left;
  }

  & span{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 6.9rem;
    width: 6.9rem;
    border-radius: $radius;
    & svg{
      width: 4rem;
      height: 4rem;
      fill: $color-white;
    }
  }
}
}
