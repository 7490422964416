
.siteHeader{
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: auto;
    background-color: $gray-dark;
    z-index: 1020;
    transition: $trans;

    &.hiddenbar{
        top: -3.7rem;
    }
}

.topbar{
    display: flex;
    font-size: 1.33rem;
    color: $gray-ed;
    padding: .69rem 3rem;
    height: 3.7rem;
    align-items: center;
    background-color: $gray-dark-3;

    @include respond(md) {
      padding: .69rem 2rem;
    }

    a{
        display: inline-flex;

        &:link,
        &:visited{
          color: $gray-ed;
          transition: $trans;
          text-decoration: none;
        }

        &:hover,
        &:focus{
          outline: none;
          color: $color-orange;
        }
      }

    .rightbar{
        margin-left: auto;
        display:inline-flex;
        align-items: center;

        svg{
            height: 2.2rem;
            width: 2.8rem;
            margin-right: .44rem;
        }

        .languages{
            display: inline-flex;
            align-items: center;

            i{
                font-size: 1.1rem;
            }
        }

        a{
            margin:0 1rem;
        }
    }

    .leftbar{
            display: flex;

            & .hiddenid{
              @include respond(md) {
                display: none;
              }
            }


        a{
            margin-right: 1.5rem;

            i{
                color: $color-orange;
                margin-right: .66rem;
            }

            &:hover{
                i{
                    color: $gray-a;
                }
            }

        }


    }
}


.menubar{
    display: flex;
    align-items: center;
    padding: 1rem 3rem;

    @include respond(md) {
      padding: 1rem 2rem;
    }

    .logo{

        text-decoration: none;

        img{
            height: 4.4rem;

            @include respond(phone) {
              height: 3.7rem;

            }
        }

        &:hover,
        &:focus{
            outline: none;
        }
    }

    .topnav{
        margin-left: auto;
        position: relative;

        @include respond(tab-land) {
          display: grid;
          position: fixed;
          width: max-content;
          height: 100vh;
          margin: 0;
          z-index: 1020;
          left: -100%;
          top: 0;
          bottom: 0;
          background-color: $gray-dark;
          align-content: flex-start;
          padding: 3rem;
          transform: translateX(-100%);
          transition: $trans;

          &.showMenu{
            transform: translateX(0);
            left: 0;
          }
        }
    }

    .navlinks{
        display: flex;
        font-weight:$medium-bold;
        align-items: center;
        //letter-spacing: .33px;
        font-size: 1.72rem;

        @include respond(tab-land) {
          display: grid;

        }

        li{
            position: relative;
            display: inline-flex;
            align-items: center;

            @include respond(tab-land) {
              display: grid;

            }

            &.dropdown{

                &:hover{
                    & .dropDownBox{
                        display: grid;
                    }
                }
            }

            a,
            button{
                display: inline-flex;
                padding:.69rem 1rem;
                color: $color-white;
                text-decoration: none;
                transition: all .2s ease-out;
                align-items: center;
                text-transform: capitalize;

                & i{
                    font-size: 1.1rem;
                    margin-left: .69rem;
                    display: inline-flex;
                    transition: all .2s ease-out;
                    color: $gray-a;
                }

                svg{
                    width: 2rem;
                    height: 2rem;
                    margin-right: .44rem;
                }


            &:link,
            &:visited,
            &:focus{
                outline: none;
            }

            &:active,
            &.active,
            &:hover{
                color: $color-orange;

                & i{
                    transform: rotate(180deg);
                    color: $color-orange;
                }
            }

            &.nav-btn{
                background-color: $color-orange;
                margin: 0 1rem;
                padding: .44rem 2.2rem;
                border-radius: $radius;
                border: 2px solid $color-orange;
                color: $color-white;

                &:last-child{
                    margin-right: 0;
                }

                &-line{
                    background-color: transparent;
                }

                &:hover,
                &:focus{
                    background-color: $color-orange;
                    color: $color-white;
                }

                &-text{
                    background-color: transparent;
                    border-color: transparent;
                    color: $color-yellow-3;
                    padding:.33rem 0;

                    &:hover,
                    &:focus{
                        background-color:transparent;
                    }
                }
            }
        }

        &.active{

          & a{
          color:$color-orange;
          outline: none;

          & i{
            transform: rotate(180deg);
            color: $color-orange;
        }

           }
        }


        }
    }
}

.mobilenav{
  height: 3.33rem;
  width: 3.33rem;
  background-color: $color-orange;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  display: none;
  transition: $trans;
  margin-left: auto;

  @include respond(tab-land) {
    display: inline-flex;
  }

  & svg{
    fill: $color-white;
    height: 2.22rem;
    width: 2.22rem;
  }

  &:hover,
    &:focus{
      background-color: $color-yellow;

      & svg{
        fill: $gray-dark;
      }
  }
}

.hero-inner{
    height: 42.4rem;
    display: grid;
    align-content: center;
    @include bgCenter;
    position: relative;
    z-index: 1;

    @include respond(phone) {
      height: 37rem;
    }

    &::before{
        content: '';
        width: $maxwidth;
        height: $maxwidth;
        background-color: transparentize($color-black, .69);
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 0;
    }


    h1{
      font-size: 3.7rem;
      color: $color-white;
      font-weight: $bold;
      z-index: 1;
      position: relative;
      animation: serviceBox 3s ease-out;

      @include respond(medium-tab) {
        font-size:3.2rem;
      }

      @include respond(phone) {
        font-size:2.8rem;

        br{
          display: none;
        }
      }

      & small{
          margin-bottom: .66rem;
          display: flex;
          font-weight: $medium-bold;
          font-size: 55%;
          align-items: center;

          & a{
              &:link,
              &:visited,
              &:focus{
                  color: $color-white;
                  text-decoration: none;
              }
              &:hover{
                  color: $color-orange-2;
              }
          }

          & i{
              margin: 0 1rem;
              color: $color-orange;
          }
      }
  }

    &.pricing{
        background-image: url(../img/pricing_banner.jpg);
    }
    &.features{
        background-image: url(../img/features_banner.jpg);
    }
    &.industries{
        background-image: url(../img/industries_banner.jpg);
    }
    &.solutions{
        background-image: url(../img/solution_banner.jpg);
    }
    &.deployable{
        background-image: url(../img/deploy_banner.jpg);
    }
    &.customizable{
        background-image: url(../img/customizable-solutions-banner.jpg);
    }
    &.contact{
        background-image: url(../img/contact_banner.jpg);
    }

    &.industry_government{
        background-image: url(../img/header_gov.jpg);
    }

    &.industry_professional{
        background-image: url(../img/header_professional.jpg);
    }

    &.industry_education{
        background-image: url(../img/header_education.jpg);
    }

    &.industry_healthcare{
        background-image: url(../img/header_healthcare.jpg);
    }

    &.industry_finance{
        background-image: url(../img/header_finance.jpg);
    }

    &.industry_realestate{
        background-image: url(../img/header_realestate.jpg);
    }

    &.industry_travel{
        background-image: url(../img/header_travel.jpg);
    }

    &.industry_ites{
        background-image: url(../img/header_it.jpg);
    }

    &.blogs{
        background-image: url(../img/blog_banner.jpg);
    }

    &.blog-details{
        background-image: url(../img/blog_content_banner.jpg);
    }

    &.privacy{
        background-image: url(../img/privacy_banner.jpg);
    }

    &.disclaimer{
        background-image: url(../img/disclaimer_banner.jpg);
    }

    &.faq{
        background-image: url(../img/faq_banner.jpg);
    }

    &.cookies{
        background-image: url(../img/cookies_banner.jpg);
    }

    &.support{
        background-image: url(../img/support_header.jpg);
    }

    &.dark{
      background-color: $gray-dark;
      align-content: flex-start;
      height: auto;
      padding-bottom: 1rem;

      & .graybar{
          padding: 2rem;
          background-color: $gray-mid;
          display: grid;
          align-content: center;
          margin:4rem 0;

          & h1{
              margin: 0;
              & small{
                  margin: 0;
              }
          }
      }
      & .sectitle_01{
          color: $color-white;
      }
      & .sectitle_01,
      & .searchbox{
          z-index: 1;
          position: relative;
      }
  }
}

