@charset "UTF-8";
.containers {
  width: 100%;
  max-width: 130rem;
  margin: auto;
}
@media only screen and (max-width: 75em) {
  .containers {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(3.3rem, 25%, 3.3rem);
    -moz-transform: translate3d(3.3rem, 25%, 3.3rem);
    -ms-transform: translate3d(3.3rem, 25%, 3.3rem);
    -o-transform: translate3d(3.3rem, 25%, 3.3rem);
    transform: translate3d(3.3rem, 25%, 3.3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(3.3rem, 25%, 3.3rem);
    -moz-transform: translate3d(3.3rem, 25%, 3.3rem);
    -ms-transform: translate3d(3.3rem, 25%, 3.3rem);
    -o-transform: translate3d(3.3rem, 25%, 3.3rem);
    transform: translate3d(3.3rem, 25%, 3.3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-ms-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(3.3rem, 25%, 3.3rem);
    -moz-transform: translate3d(3.3rem, 25%, 3.3rem);
    -ms-transform: translate3d(3.3rem, 25%, 3.3rem);
    -o-transform: translate3d(3.3rem, 25%, 3.3rem);
    transform: translate3d(3.3rem, 25%, 3.3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(3.3rem, 25%, 3.3rem);
    -moz-transform: translate3d(3.3rem, 25%, 3.3rem);
    -ms-transform: translate3d(3.3rem, 25%, 3.3rem);
    -o-transform: translate3d(3.3rem, 25%, 3.3rem);
    transform: translate3d(3.3rem, 25%, 3.3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(3.3rem, 25%, 3.3rem);
    -moz-transform: translate3d(3.3rem, 25%, 3.3rem);
    -ms-transform: translate3d(3.3rem, 25%, 3.3rem);
    -o-transform: translate3d(3.3rem, 25%, 3.3rem);
    transform: translate3d(3.3rem, 25%, 3.3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-3.3rem, 25%, -3.3rem);
    -moz-transform: translate3d(-3.3rem, 25%, -3.3rem);
    -ms-transform: translate3d(-3.3rem, 25%, -3.3rem);
    -o-transform: translate3d(-3.3rem, 25%, -3.3rem);
    transform: translate3d(-3.3rem, 25%, -3.3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-3.3rem, 25%, -3.3rem);
    -moz-transform: translate3d(-3.3rem, 25%, -3.3rem);
    -ms-transform: translate3d(-3.3rem, 25%, -3.3rem);
    -o-transform: translate3d(-3.3rem, 25%, -3.3rem);
    transform: translate3d(-3.3rem, 25%, -3.3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-ms-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-3.3rem, 25%, -3.3rem);
    -moz-transform: translate3d(-3.3rem, 25%, -3.3rem);
    -ms-transform: translate3d(-3.3rem, 25%, -3.3rem);
    -o-transform: translate3d(-3.3rem, 25%, -3.3rem);
    transform: translate3d(-3.3rem, 25%, -3.3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-3.3rem, 25%, -3.3rem);
    -moz-transform: translate3d(-3.3rem, 25%, -3.3rem);
    -ms-transform: translate3d(-3.3rem, 25%, -3.3rem);
    -o-transform: translate3d(-3.3rem, 25%, -3.3rem);
    transform: translate3d(-3.3rem, 25%, -3.3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-3.3rem, 25%, -3.3rem);
    -moz-transform: translate3d(-3.3rem, 25%, -3.3rem);
    -ms-transform: translate3d(-3.3rem, 25%, -3.3rem);
    -o-transform: translate3d(-3.3rem, 25%, -3.3rem);
    transform: translate3d(-3.3rem, 25%, -3.3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes movetoTop {
  0% {
    transform: translateY(1rem);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes menumotion {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes moveLeft {
  0% {
    transform: translateX(20%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes pulsate {
  0% {
    transform: scaleY(1);
    box-shadow: none;
  }
  50% {
    transform: scaleY(1.05);
    box-shadow: 0 1rem 4rem rgba(0, 0, 0, 0.25);
  }
  100% {
    transform: scaleY(1);
    box-shadow: none;
  }
}
@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }
  80% {
    transform: translateX(1rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }
  80% {
    transform: translateX(-1rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
@keyframes movetoBottom {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes movefromBottom {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes menuBoxes {
  0% {
    opacity: 0;
    top: 120%;
  }
  100% {
    top: 100%;
    opacity: 1;
  }
}
@keyframes serviceBox {
  0% {
    opacity: 0;
    transform: translateY(-50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
@keyframes scookieBar {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes growSize {
  0% {
    opacity: 0.5;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes zoomOout {
  0% {
    opacity: 0;
    width: 20%;
  }
  100% {
    opacity: 0;
    width: 100%;
  }
}
@keyframes invisible {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}
@keyframes imageAnimation {
  0% {
    transform: scale(1.2) rotate3d(1, 1, 1, 8deg);
  }
  100% {
    transform: scale(1) rotate3d(0);
  }
}
@keyframes panning {
  0% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 100% 100%;
  }
  50% {
    background-position: 100% 0%;
  }
  75% {
    background-position: 0 100%;
  }
  100% {
    background-position: 0 0;
  }
}
@keyframes landBottom {
  0% {
    opacity: 0.5;
    transform: translateY(-100%);
    margin-top: -100%;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    margin-top: -3rem;
  }
}
@keyframes snooze {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1) rotate(5deg);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes orangereds {
  0% {
    background-color: #f72112;
  }
  50% {
    background-color: #ffaf37;
  }
  100% {
    background-color: #fb481f;
  }
}
@keyframes colors {
  0% {
    background-color: #f95c39;
  }
  50% {
    background-color: #fdae3d;
  }
  100% {
    background-color: #f95c39;
  }
}
@keyframes scrolls {
  0% {
    top: 0;
  }
  100% {
    top: -10rem;
  }
}
@keyframes orangeCircle {
  0% {
    background-color: #ffffff;
  }
  25% {
    background-color: #ffffff;
  }
  75% {
    background-color: #f88216;
  }
  100% {
    background-color: #f88216;
  }
}
@keyframes orangeCircle-le {
  0% {
    background-color: rgba(255, 255, 255, 0.45);
  }
  100% {
    background-color: #ffaf37;
  }
}
@keyframes orangeCircle-sm {
  0% {
    background-color: rgba(249, 92, 57, 0.27);
  }
  100% {
    background-color: rgba(249, 92, 57, 0.09);
  }
}
@keyframes orangeCircle-sm-2 {
  0% {
    background-color: rgba(249, 92, 57, 0.45);
  }
  100% {
    background-color: rgba(249, 92, 57, 0.18);
  }
}
@keyframes colorheight {
  0% {
    height: 2rem;
  }
  50% {
    height: 3rem;
  }
  100% {
    height: 2rem;
  }
}
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(50%, 0, 0);
    -moz-transform: translate3d(50%, 0, 0);
    -ms-transform: translate3d(50%, 0, 0);
    -o-transform: translate3d(50%, 0, 0);
    transform: translate3d(50%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(50%, 0, 0);
    -moz-transform: translate3d(50%, 0, 0);
    -ms-transform: translate3d(50%, 0, 0);
    -o-transform: translate3d(50%, 0, 0);
    transform: translate3d(50%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-ms-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(50%, 0, 0);
    -moz-transform: translate3d(50%, 0, 0);
    -ms-transform: translate3d(50%, 0, 0);
    -o-transform: translate3d(50%, 0, 0);
    transform: translate3d(50%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(50%, 0, 0);
    -moz-transform: translate3d(50%, 0, 0);
    -ms-transform: translate3d(50%, 0, 0);
    -o-transform: translate3d(50%, 0, 0);
    transform: translate3d(50%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(50%, 0, 0);
    -moz-transform: translate3d(50%, 0, 0);
    -ms-transform: translate3d(50%, 0, 0);
    -o-transform: translate3d(50%, 0, 0);
    transform: translate3d(50%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-50%, 0, 0);
    -moz-transform: translate3d(-50%, 0, 0);
    -ms-transform: translate3d(-50%, 0, 0);
    -o-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-50%, 0, 0);
    -moz-transform: translate3d(-50%, 0, 0);
    -ms-transform: translate3d(-50%, 0, 0);
    -o-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-ms-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-50%, 0, 0);
    -moz-transform: translate3d(-50%, 0, 0);
    -ms-transform: translate3d(-50%, 0, 0);
    -o-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-50%, 0, 0);
    -moz-transform: translate3d(-50%, 0, 0);
    -ms-transform: translate3d(-50%, 0, 0);
    -o-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-50%, 0, 0);
    -moz-transform: translate3d(-50%, 0, 0);
    -ms-transform: translate3d(-50%, 0, 0);
    -o-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fadeInTop {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    -moz-transform: translate3d(0, 50%, 0);
    -ms-transform: translate3d(0, 50%, 0);
    -o-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes fadeInTop {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    -moz-transform: translate3d(0, 50%, 0);
    -ms-transform: translate3d(0, 50%, 0);
    -o-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-ms-keyframes fadeInTop {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    -moz-transform: translate3d(0, 50%, 0);
    -ms-transform: translate3d(0, 50%, 0);
    -o-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes fadeInTop {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    -moz-transform: translate3d(0, 50%, 0);
    -ms-transform: translate3d(0, 50%, 0);
    -o-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInTop {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    -moz-transform: translate3d(0, 50%, 0);
    -ms-transform: translate3d(0, 50%, 0);
    -o-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
* {
  margin: 0;
  padding: 0;
}
*::selection {
  background-color: #f95c39;
  color: #ffffff;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  scroll-behavior: smooth;
}
@media only screen and (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
}

body {
  font-weight: 400;
  line-height: 1.5;
  font-size: 1.69rem;
  font-family: "Red Hat Text", sans-serif;
  caret-color: #f95c39;
  padding-top: 6.4rem;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.modal {
  background-image: linear-gradient(to top right, rgba(248, 130, 22, 0.89), rgba(205, 11, 63, 0.89));
}

p {
  color: #737373;
}

.white-bg {
  background-color: #ffffff;
}

.light-orange {
  background-color: #ffeeba;
}

.light-blue {
  background-color: #ceecff;
}

.light-pink {
  background-color: #ffe3e1;
}

.light-pink-2 {
  background-color: #f9f1e8;
}

.light-aqua {
  background-color: #d9f4e5;
}

.light-gray {
  background-color: #f7f7f7;
}

.light-gray-2 {
  background-color: #e0d5f5;
}

.dark-blue {
  background-color: #132144;
}

.bluewhite {
  background-image: linear-gradient(to right, #ffffff, #eef7ff);
}

.violets {
  background-color: rgba(87, 11, 230, 0.23);
}
.violets svg {
  fill: #570be6;
}

.pinks {
  background-color: rgba(233, 60, 216, 0.23);
}
.pinks svg {
  fill: #e93cd8;
}

.greens {
  background-color: rgba(144, 178, 73, 0.23);
}
.greens svg {
  fill: #90b249;
}

.aquas {
  background-color: rgba(21, 157, 117, 0.23);
}
.aquas svg {
  fill: #159d75;
}

.blues {
  background-color: rgba(27, 101, 193, 0.23);
}
.blues svg {
  fill: #1b65c1;
}

.oranges {
  background-color: rgba(238, 148, 67, 0.23);
}
.oranges svg {
  fill: #ee9443;
}

.textlinks {
  display: inline-flex;
  font-weight: 500;
}
.textlinks:link, .textlinks:visited {
  color: #191919;
}
.textlinks:hover, .textlinks:focus {
  color: #f95c39;
  outline: none;
}

.textlink-2 {
  display: inline-flex;
  transition: all 0.3s ease;
  font-size: 1.3rem;
  font-weight: 600;
}
.textlink-2:link, .textlink-2:visited {
  color: #f95c39;
  text-decoration: none;
}
.textlink-2:hover, .textlink-2:focus {
  color: #191919;
  outline: none;
}

.underline-links {
  font-weight: 600;
  font-size: 2rem;
  margin-top: 1.69rem;
  transition: all 0.3s ease;
  color: #ffffff;
  display: inline-flex;
  width: max-content;
  padding-bottom: 0.33rem;
  background-color: transparent;
  border: none;
  border-bottom: 0.28rem solid #f95c39;
}
.underline-links:link, .underline-links:visited {
  text-decoration: none;
}
.underline-links:hover, .underline-links:focus {
  border-color: #736ae7;
  outline: none;
  color: #ffce47;
}
.underline-links.black {
  color: #191919;
}
.underline-links.black:hover {
  color: #f95c39;
}
.underline-links.center {
  margin: 0 auto;
}

.large-btn {
  background-color: #f95c39;
  padding: 1rem 3rem;
  border-radius: 4px;
  border: 2px solid #f95c39;
  color: #ffffff;
  font-size: 1.9rem;
  transition: all 0.3s ease;
  font-weight: 600;
  display: inline-flex;
}
@media only screen and (max-width: 30em) {
  .large-btn {
    padding: 0.69rem 2.8rem;
    font-size: 1.69rem;
  }
}
.large-btn-line {
  background-color: transparent;
  color: #f95c39;
}
.large-btn:link, .large-btn:visited {
  text-decoration: none;
}
.large-btn:hover, .large-btn:focus {
  background-color: #f88216;
  border-color: #f88216;
  color: #ffffff;
  outline: none;
}

.exlarge-btn {
  background-color: #f95c39;
  padding: 1.5rem 6rem;
  border-radius: 0.5rem;
  border: 2px solid #f95c39;
  color: #ffffff;
  font-size: 2rem;
  text-transform: uppercase;
  transition: all 0.3s ease;
  font-weight: 600;
  display: inline-flex;
}
@media only screen and (max-width: 48em) {
  .exlarge-btn {
    padding: 1rem 4rem;
    justify-content: center;
  }
}
.exlarge-btn-line {
  background-color: transparent;
  color: #f95c39;
}
.exlarge-btn:link, .exlarge-btn:visited {
  text-decoration: none;
}
.exlarge-btn:hover, .exlarge-btn:focus {
  background-color: #191919;
  border-color: #191919;
  color: #ffffff;
}

.darkbtn {
  background-color: #111111;
  padding: 1.5rem 6rem;
  border-radius: 0.5rem;
  border: none;
  color: #ffffff;
  display: flex;
  text-transform: uppercase;
  transition: all 0.3s ease;
  font-weight: 500;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.darkbtn:link, .darkbtn:visited {
  text-decoration: none;
}
.darkbtn:hover, .darkbtn:focus {
  background-color: #03307c;
  color: #f7f7f7;
}

.dropDownBox {
  padding: 1.33rem;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f95c39;
  margin-top: 0;
  grid-column-gap: 1.5rem;
  grid-row-gap: 0.73rem;
  display: none;
  animation: menuBoxes 0.33s ease;
  width: max-content;
  z-index: 1000;
  border-radius: 4px;
}
@media only screen and (max-width: 75em) {
  .dropDownBox {
    position: static !important;
    padding: 1rem;
    animation: none;
    left: 0;
    transform: translateX(0);
  }
}
.dropDownBox.twoColumn {
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1.5rem;
}
@media only screen and (max-width: 75em) {
  .dropDownBox.twoColumn {
    grid-template-columns: 1fr !important;
    padding: 1rem;
  }
}
.dropDownBox.largeColumn {
  grid-template-columns: repeat(4, auto) !important;
  grid-auto-flow: dense;
  column-gap: 3rem;
}
@media only screen and (max-width: 75em) {
  .dropDownBox.largeColumn {
    grid-template-columns: 1fr !important;
    padding: 0 1rem;
  }
}
.dropDownBox.xtralargeColumn {
  grid-template-columns: repeat(5, auto) !important;
  grid-auto-flow: dense;
  column-gap: 3rem;
}
@media only screen and (max-width: 75em) {
  .dropDownBox.xtralargeColumn {
    grid-template-columns: 1fr !important;
    padding: 0 1rem;
  }
}
.dropDownBox h4 {
  color: #aaaaaa;
  font-weight: 700;
  padding-bottom: 0.5rem;
  text-transform: capitalize;
  font-size: 1.69rem;
}
.dropDownBox ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}
.dropDownBox ul li {
  margin-bottom: 0.69rem;
  display: flex;
}
.dropDownBox ul li:last-child {
  margin-bottom: 0;
}
.dropDownBox a {
  padding: 0 !important;
  font-size: 1.5rem;
  display: flex;
  color: #ffffff !important;
}
.dropDownBox a:hover {
  color: #000000 !important;
}

.sections {
  padding: 6rem 0;
  scroll-margin-top: 6rem;
  overflow-x: hidden;
}
.sections.npfull {
  padding: 0;
}
.sections.nptop {
  padding-top: 0;
}
.sections.npbottom {
  padding-bottom: 0;
}

.sectitle_01 {
  display: flex;
  flex-direction: column;
  margin: 0 auto 3rem auto;
}
.sectitle_01 h3 {
  text-align: center;
  text-transform: uppercase;
  color: #f95c39;
  font-weight: 500;
  font-size: 2rem;
  margin-bottom: 1.69rem;
}
.sectitle_01 h2 {
  font-size: 3.3rem;
  text-align: center;
  font-weight: 700;
}
@media only screen and (max-width: 48em) {
  .sectitle_01 h2 {
    font-size: 2.8rem;
  }
}
@media only screen and (max-width: 40em) {
  .sectitle_01 h2 {
    font-size: 2.5rem;
  }
}
@media only screen and (max-width: 30em) {
  .sectitle_01 h2 {
    font-size: 2.1rem;
  }
}
.sectitle_01 h2 span {
  display: block;
}
.sectitle_01 h2 strong {
  font-weight: 700;
  color: #f95c39;
}
.sectitle_01 p {
  text-align: center;
  max-width: 80rem;
  margin: 1rem auto;
  color: #132144;
  font-size: 1.73rem;
  font-weight: 500;
}

.sectitle_02 {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
}
.sectitle_02 h2 {
  font-size: 3.3rem;
  font-weight: 600;
  color: #f95c39;
}
@media only screen and (max-width: 37.5em) {
  .sectitle_02 h2 {
    font-size: 2.5rem;
  }
}
.sectitle_02 h2 span {
  display: block;
}
.sectitle_02 h2 strong {
  font-weight: 700;
  color: #f95c39;
}
.sectitle_02 hr {
  height: 0.44rem;
  width: 10rem;
  opacity: 1;
  border-radius: 100px;
}
.sectitle_02.white h2 {
  color: #ffffff;
}
.sectitle_02.white hr {
  background-color: #ffffff;
}
.sectitle_02.dark h2 {
  color: #191919;
  font-size: 2.9rem;
}

.revolutionize .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 1rem;
  transition: all 0.3s ease;
}
.revolutionize .item h3 {
  font-size: 2.1rem;
  font-weight: 600;
}
.revolutionize .item p {
  text-align: center;
}
.revolutionize .item svg {
  height: 6.4rem;
  width: 6.4rem;
  margin-bottom: 2rem;
}
.revolutionize .light-orange:hover {
  background-color: #ffe8a1;
}
.revolutionize .light-blue:hover {
  background-color: #b5e2ff;
}
.revolutionize .light-pink:hover {
  background-color: #ffcbc8;
}
.revolutionize .light-aqua:hover {
  background-color: #c5eed7;
}

.bmsoftware .bmscontent {
  padding-top: 4rem;
  display: grid;
  grid-template-columns: 45% 55%;
}
.bmsoftware .bmscontent .leftside {
  position: relative;
  padding-right: 6rem;
}
.bmsoftware .bmscontent .leftside .textlinks {
  display: flex;
}
.bmsoftware .bmscontent .leftside h4 {
  font-size: 2.2rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}
.bmsoftware .bmscontent .leftside .floated {
  position: relative;
  margin-right: -12rem;
  margin-top: 2rem;
}
.bmsoftware .bmscontent .picside img {
  max-width: 100%;
  border-top-left-radius: 5rem;
}
.bmsoftware .nav-tabs {
  justify-content: space-between;
  border: none;
  background-color: #ffffff;
  box-shadow: 0 0.69rem 1rem rgba(25, 25, 25, 0.07);
}
.bmsoftware .nav-link {
  color: #191919;
  font-weight: 600;
  border: none;
  border-bottom: 0.44rem solid transparent;
  border-radius: 0;
  padding: 0.69rem 2rem;
}
.bmsoftware .nav-link:hover, .bmsoftware .nav-link:focus {
  color: #f95c39;
}
.bmsoftware .nav-link.active {
  border-bottom-color: #f95c39;
}

.checklist {
  display: grid;
}
.checklist.twocol {
  grid-template-columns: repeat(2, 1fr);
  column-gap: 3rem;
}
.checklist li {
  padding-left: 3.5rem;
  position: relative;
  margin: 1rem 0;
  display: flex;
  font-weight: 500;
}
.checklist li::before {
  content: "✓";
  background-color: #f95c39;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  font-weight: 500;
  color: #ffffff;
  left: 0;
  border-radius: 50%;
  z-index: 0;
}

.checklist-lg {
  display: grid;
  margin-bottom: 2rem;
}
.checklist-lg.twocol {
  grid-template-columns: repeat(2, 1fr);
  column-gap: 4rem;
}
.checklist-lg li {
  padding: 1rem 0 1rem 5rem;
  position: relative;
  display: flex;
  color: #737373;
}
.checklist-lg li::before {
  content: "✓";
  background-color: transparent;
  font-size: 2.2rem;
  width: 3rem;
  height: 3rem;
  line-height: 1.2;
  display: flex;
  justify-content: center;
  font-weight: 700;
  color: #f95c39;
  left: 0;
  border-radius: 50%;
  border: 0.25rem solid #f95c39;
  z-index: 0;
  position: absolute;
  left: 0;
  top: 0.69rem;
}

.arrowlist {
  display: grid;
  color: #737373;
}
.arrowlist-lg {
  font-size: 1.73rem;
  font-weight: 500;
  color: #575757;
  margin-bottom: 2rem;
}
.arrowlist.twocol {
  grid-template-columns: repeat(2, 1fr);
  column-gap: 3rem;
}
.arrowlist li {
  padding-left: 2rem;
  position: relative;
  margin-bottom: 0.69rem;
  display: flex;
  flex-direction: column;
}
.arrowlist li::before {
  content: "»";
  position: absolute;
  top: -0.3rem;
  justify-content: center;
  font-weight: 500;
  color: #f95c39;
  left: 0;
  z-index: 0;
  font-size: 1.9rem;
}
.arrowlist li ul {
  margin-top: 0.69rem;
}
.arrowlist li ul li::before {
  content: "›";
}

.starlist {
  font-size: 1.73rem;
}
.starlist li {
  position: relative;
  padding-left: 4rem;
  margin-bottom: 1.5rem;
  font-weight: 700;
}
.starlist li::before {
  content: "★";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  color: #ffffff;
  font-size: 3rem;
  -webkit-text-stroke: 0.2rem #f95c39;
}

.lgbtn-group {
  max-width: 80rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}
@media only screen and (max-width: 48em) {
  .lgbtn-group {
    flex-direction: column;
    row-gap: 2rem;
  }
}

.socialinks {
  display: flex;
}
.socialinks li {
  margin: 0.69rem 1rem;
  display: inline-flex;
  align-items: center;
}
.socialinks li svg {
  height: 2.8rem;
  width: 2.8rem;
  fill: #8f8f9b;
  transition: all 0.3s ease;
}
@media only screen and (max-width: 35em) {
  .socialinks li svg {
    width: 2.3rem;
    height: 2.3rem;
  }
}
.socialinks li a:link, .socialinks li a:visited {
  color: #8f8f9b;
  width: 4rem;
  height: 4rem;
  display: inline-flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  text-decoration: none;
}
@media only screen and (max-width: 35em) {
  .socialinks li a:link, .socialinks li a:visited {
    width: 3.5rem;
    height: 3.5rem;
  }
}
.socialinks li a:hover, .socialinks li a:focus {
  outline: none;
}
.socialinks li a:hover svg, .socialinks li a:focus svg {
  fill: #f88216;
  height: 3rem;
  width: 3rem;
}
@media only screen and (max-width: 35em) {
  .socialinks li a:hover svg, .socialinks li a:focus svg {
    width: 2.8rem;
    height: 2.8rem;
  }
}
.socialinks.colored li:first-child {
  margin-left: 0;
}
.socialinks.colored li a.facebook {
  background-color: #4267b2;
}
.socialinks.colored li a.twitter {
  background-color: #1da1F2;
}
.socialinks.colored li a.linkedin {
  background-color: #0077b5;
}
.socialinks.colored li a svg {
  fill: #ffffff;
  height: 2rem;
  width: 2rem;
}
.socialinks.colored li a:hover {
  transform: translateY(0.33rem);
  background-color: #fb481f;
}
.socialinks.small {
  margin: 0 0 0 1rem;
}
.socialinks.small li {
  margin: 0 0.69rem 0 0;
}
.socialinks.small li a {
  width: 3rem;
  height: 3rem;
}
.socialinks.small li a svg {
  width: 1.45rem;
  height: 1.45rem;
}

.justflex {
  display: flex;
}
.justflex.center {
  justify-content: center;
}

.bigtitle {
  text-align: center;
  font-size: 5rem;
  font-weight: 700;
  margin-bottom: 6rem;
}
@media only screen and (max-width: 62em) {
  .bigtitle {
    font-size: 3.3rem;
    margin-bottom: 3rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .bigtitle {
    font-size: 2.8rem;
  }
}

.movements {
  transition: all 0.3s ease;
}
.movements.showmove {
  animation: serviceBox 4s ease 0.69s;
}

.fadeInRight {
  -webkit-animation: fadeInRight .5s ease .3s both;
  -moz-animation: fadeInRight .5s ease .3s both;
  -ms-animation: fadeInRight .5s ease .3s both;
  -o-animation: fadeInRight .5s ease .3s both;
  animation: fadeInRight .5s ease .3s both;
}
@media (prefers-reduced-motion) {
  .fadeInRight .animated {
    -webkit-animation: unset !important;
    animation: unset !important;
    -webkit-transition: none !important;
    transition: none !important;
  }
}

.fadeInLeft {
  -webkit-animation: fadeInLeft .5s ease .3s both;
  -moz-animation: fadeInLeft .5s ease .3s both;
  -ms-animation: fadeInLeft .5s ease .3s both;
  -o-animation: fadeInLeft .5s ease .3s both;
  animation: fadeInLeft .5s ease .3s both;
}
@media (prefers-reduced-motion) {
  .fadeInLeft .animated {
    -webkit-animation: unset !important;
    animation: unset !important;
    -webkit-transition: none !important;
    transition: none !important;
  }
}

.fadeInTop {
  -webkit-animation: fadeInTop .5s ease .3s both;
  -moz-animation: fadeInTop .5s ease .3s both;
  -ms-animation: fadeInTop .5s ease .3s both;
  -o-animation: fadeInTop .5s ease .3s both;
  animation: fadeInTop .5s ease .3s both;
}
@media (prefers-reduced-motion) {
  .fadeInTop .animated {
    -webkit-animation: unset !important;
    animation: unset !important;
    -webkit-transition: none !important;
    transition: none !important;
  }
}

.contentpics {
  max-width: 100%;
  display: flex;
  margin: 2rem 0;
}

.splitform {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1.69rem;
}
@media only screen and (max-width: 56.25em) {
  .splitform {
    grid-template-columns: 1fr;
    row-gap: 1.69rem;
  }
}

.titlelinks {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
}
.titlelinks .blogdate {
  align-items: center;
  display: inline-flex;
  margin-right: auto;
}
.titlelinks .blogdate p {
  margin: 0;
}
.titlelinks .blogdate i {
  color: #f95c39;
  font-size: 2rem;
  margin-right: 1rem;
}

.blogmedia {
  display: flex;
  align-items: center;
}

.blog_bottom_card {
  display: grid;
  grid-template-columns: auto 1fr;
  box-shadow: 0 0 1.55rem 0.3rem rgba(0, 0, 0, 0.09);
  padding: 2rem;
  margin: 2rem 0;
  overflow: hidden;
  border-radius: 0.69rem;
  align-items: center;
  column-gap: 1.69rem;
}
@media only screen and (max-width: 37.5em) {
  .blog_bottom_card {
    grid-template-columns: auto;
    row-gap: 2rem;
  }
}
.blog_bottom_card figure {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.blog_bottom_card figure h5 {
  font-size: 1.69rem;
  text-align: center;
}
.blog_bottom_card figure h5 small {
  display: block;
  color: #737373;
}
.blog_bottom_card figure img {
  border-radius: 50%;
  width: 12rem;
  margin-bottom: 1rem;
}

.blogsearch {
  display: flex;
  position: relative;
  overflow: hidden;
  margin-bottom: 2rem;
}
.blogsearch input {
  background-color: #ffffff;
  border: 1px solid #cac9c9;
  border-radius: 0.5rem;
  padding: 1rem;
  width: 100%;
  margin: 0;
  transition: all 0.3s ease;
}
.blogsearch input:hover, .blogsearch input:focus {
  outline: none;
}
.blogsearch input:focus:invalid {
  border-color: #f72112;
}
.blogsearch .searchbtn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  background-color: transparent;
  height: 100%;
  align-items: center;
  justify-content: center;
  width: 5rem;
  border: none;
  display: inline-flex;
}
.blogsearch .searchbtn svg {
  width: 2rem;
  height: 2rem;
  fill: #737373;
  transition: all 0.3s ease;
}
.blogsearch:hover .searchbtn, .blogsearch:focus .searchbtn {
  outline: none;
}
.blogsearch:hover .searchbtn svg, .blogsearch:focus .searchbtn svg {
  fill: #f95c39;
}

.subtitle_01 {
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.form-control,
.form-select,
.btn {
  font-size: 1.69rem;
}

.modalcolumns {
  display: grid;
  grid-template-columns: minmax(auto, 32rem) 1fr;
}
@media only screen and (max-width: 62em) {
  .modalcolumns {
    grid-template-columns: 1fr;
  }
}

#producTour .modal-content {
  border-radius: 1rem;
  overflow: hidden;
}
#producTour .modal-body {
  padding: 0;
}
#producTour .modal-body picture {
  background-image: url(../img/popup-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  height: 45.3rem;
}
@media only screen and (max-width: 62em) {
  #producTour .modal-body picture {
    display: none;
  }
}
#producTour .modal-body img {
  max-width: 100%;
  display: flex;
}
#producTour .modal-body article {
  position: relative;
  padding: 2.5rem 3rem;
}
#producTour .modal-body article input,
#producTour .modal-body article select,
#producTour .modal-body article textarea {
  background-color: #ffffff;
  border: 1px solid #cac9c9;
  border-radius: 4px;
  padding: 0.55rem;
  width: 100%;
  margin-bottom: 1.69rem;
}
#producTour .modal-body article input:hover, #producTour .modal-body article input:focus,
#producTour .modal-body article select:hover,
#producTour .modal-body article select:focus,
#producTour .modal-body article textarea:hover,
#producTour .modal-body article textarea:focus {
  outline: none;
}
#producTour .modal-body article input:focus:invalid,
#producTour .modal-body article select:focus:invalid,
#producTour .modal-body article textarea:focus:invalid {
  border-color: #f72112;
}
#producTour .modal-body article .nav {
  justify-content: center;
}
#producTour .modal-body article .nav-item:first-child .nav-link {
  border-radius: 100px 0 0 100px;
}
#producTour .modal-body article .nav-item:last-child .nav-link {
  border-radius: 0 100px 100px 0;
}
#producTour .modal-body article .nav-link {
  border-radius: 0;
  padding: 0.33rem 3rem;
  border: 2px solid #f95c39;
  font-weight: 600;
  color: #f95c39;
}
#producTour .modal-body article .nav-link.active {
  background-color: #f95c39;
  color: #ffffff;
}
#producTour .modal-body article .nav-pills {
  margin-bottom: 2.5rem;
}
#producTour .modal-body article .nav-pills .show > .nav-link {
  background-color: #f95c39;
  color: #ffffff;
}
#producTour .modal-body article h3 {
  margin: 1rem 0 2rem 0;
  font-weight: 700;
  font-size: 2rem;
  text-align: center;
}
#producTour .modal-body article h4 {
  font-weight: 700;
  font-size: 1.72rem;
  color: #f95c39;
}
#producTour .modal-body article p {
  color: #404040;
}
#producTour .modal-body article .btn-dark {
  padding: 0.5rem 3rem;
  font-weight: 600;
}
#producTour .modal-body article .btn-dark:hover {
  background-color: #f95c39;
  border-color: #f95c39;
}
#producTour .modal-body article .btn-close {
  right: 2rem;
  top: 2rem;
  position: absolute;
  z-index: 100;
}
#producTour .modal-body article .btn-close:focus {
  outline: none;
  box-shadow: none;
}

.commonContent h4 {
  font-size: 2.2rem;
  font-weight: 700;
  margin: 2rem 0;
}
.commonContent h5 {
  font-weight: 600;
  font-size: 1.72rem;
  color: #3f434f;
  margin-bottom: 1.69rem;
}

ol {
  margin: 0;
  padding: 0;
  counter-reset: lists;
}
ol li {
  margin-bottom: 0.69rem;
  display: flex;
  padding-left: 2rem;
  position: relative;
}
ol li a {
  color: #f95c39;
  margin-left: 0.55rem;
}
ol li a:hover {
  color: #ffaf37;
}
ol li::before {
  content: counter(lists) ".";
  counter-increment: lists;
  color: #f95c39;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1;
  font-weight: 500;
}

.smalltitle_01 {
  color: #f95c39;
  font-weight: 700;
  font-size: 2.8rem;
  margin-bottom: 2rem;
}

.scrollToTopBtn {
  background-color: #323336;
  border: none;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 5rem;
  font-size: 3rem;
  border-radius: 50%;
  position: fixed;
  bottom: 10rem;
  right: 3rem;
  z-index: 100;
  opacity: 0;
  transform: translateY(10rem);
  transition: all 0.3s ease;
}
@media only screen and (max-width: 35em) {
  .scrollToTopBtn {
    width: 4rem;
    height: 4rem;
    font-size: 2.5rem;
  }
}
.scrollToTopBtn:hover {
  background-color: #f95c39;
}
.scrollToTopBtn.showBtn {
  opacity: 1;
  transform: translateY(0);
}

.faqsection {
  position: relative;
  margin-top: -17rem;
  padding-top: 16rem;
  padding-bottom: 6rem;
  background-color: #edf0f7;
  z-index: 0;
}
.faqsection p,
.faqsection ol,
.faqsection ul {
  color: #404040;
  text-align: left;
}
.faqsection p strong,
.faqsection ol strong,
.faqsection ul strong {
  font-weight: 600;
  color: #fb481f;
}
.faqsection p a,
.faqsection ol a,
.faqsection ul a {
  color: #f95c39;
}
.faqsection p a:hover,
.faqsection ol a:hover,
.faqsection ul a:hover {
  color: #ffaf37;
}
.faqsection .accordion-button {
  font-size: 1.82rem;
  font-weight: 600;
  outline: none;
  box-shadow: none;
  background-color: #ffffff;
  color: #191919;
  padding: 1.69rem;
}
.faqsection .accordion-button::after {
  width: 2rem;
  height: 2rem;
  background-size: 1.82rem;
  background-image: url(../img/chevron-down.svg);
}
.faqsection .accordion-button:not(.collapsed) {
  color: #f95c39;
}
.faqsection .faqwrap {
  display: grid;
  grid-template-columns: 28rem 1fr;
  align-items: baseline;
  column-gap: 3rem;
}
.faqsection .faqwrap .faqCats {
  display: grid;
}
.faqsection .faqwrap .faqCats li {
  display: grid;
  padding: 0.69rem;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 0.69rem;
}
.faqsection .faqwrap .faqCats li.active {
  background-color: #ffffff;
  color: #f95c39;
}
.faqsection .faqwrap .catContents {
  display: none;
}
.faqsection .faqwrap .catContents.active {
  display: grid;
  align-items: baseline;
}

.cookies-infobar {
  background: #111111;
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 1022;
  animation: scookieBar 1s ease;
  column-gap: 2rem;
  box-shadow: 0 0 1.55rem 0.3rem rgba(0, 0, 0, 0.09);
}
.cookies-infobar_wrapper {
  display: flex;
}
.cookies-infobar_content {
  color: #ffffff;
}
.cookies-infobar_content a:link, .cookies-infobar_content a:visited {
  color: #f95c39;
  transition: all 0.3s ease;
  text-decoration: none;
  font-weight: 600;
}
.cookies-infobar_content a:hover, .cookies-infobar_content a:focus {
  outline: none;
  color: #f88216;
}
.cookies-infobar_btn {
  display: inline-flex;
  padding: 1rem 3rem;
  background: #f95c39;
  text-decoration: none;
  border-radius: 4px;
  color: #ffffff;
  margin-left: 1rem;
  transition: all 0.3s ease;
  font-weight: 600;
  width: max-content;
  border: none;
}
.cookies-infobar_btn:hover {
  background-color: #191919;
}
.cookies-infobar_btn:focus {
  outline: none;
}
.cookies-infobar_accepted {
  display: none;
}

.cookieOptions {
  margin-top: 1rem;
}

.form-check-input {
  width: 1.69rem;
  height: 1.69rem;
  box-shadow: none;
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.27);
}
.form-check-input:focus {
  border-color: #ffaf37;
  outline: 0;
  box-shadow: none;
}
.form-check-input:checked {
  background-color: #f95c39;
  border-color: #f95c39;
  box-shadow: none;
}

.searchbox {
  max-width: 55rem;
  width: 100%;
  margin: 0 auto 3.7rem auto;
  display: flex;
}
.searchbox input {
  background-color: #ffffff;
  background-image: url(../img/search.svg);
  background-repeat: no-repeat;
  background-position: 1rem center;
  background-size: 2rem;
  border: 1px solid #cac9c9;
  border-radius: 0.5rem 0 0 0.5rem;
  padding: 1rem 1rem 1rem 4rem;
  width: 100%;
  margin: 0;
  transition: all 0.3s ease;
  border-right: none;
  text-align: center;
}
.searchbox input:hover, .searchbox input:focus {
  outline: none;
}
.searchbox input:focus:invalid {
  border-color: #f72112;
}
.searchbox button {
  border: none;
  background-color: #f95c39;
  color: #ffffff;
  text-transform: uppercase;
  padding: 0.11rem 1.5rem;
  border-radius: 0 0.5rem 0.5rem 0;
  transition: all 0.3s ease;
}
.searchbox button:hover, .searchbox button:focus {
  outline: none;
  background-color: #f88216;
}

.supportCards {
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 3.3rem;
  border-radius: 2rem;
  overflow: hidden;
  column-gap: 3rem;
  margin: 3.3rem 0;
  transition: all 0.3s ease;
  align-items: center;
}
@media only screen and (max-width: 75em) {
  .supportCards {
    margin: 2rem 0;
  }
}
.supportCards h4 {
  font-size: 2rem;
  margin-bottom: 1.9rem;
  font-weight: 700;
}
.supportCards p {
  color: #191919;
  margin: 0;
  text-align: left !important;
}
.supportCards span {
  width: 8rem;
  height: 8rem;
  background-color: rgba(255, 255, 255, 0.34);
  display: inline-flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}
.supportCards span svg {
  width: 4rem;
  height: 4rem;
  fill: currentColor;
}
.supportCards.aqua {
  background-color: rgba(45, 237, 167, 0.67);
}
.supportCards.blue {
  background-color: rgba(79, 141, 253, 0.67);
}
.supportCards.orange {
  background-color: rgba(255, 175, 55, 0.67);
}
.supportCards:hover {
  transform: translateY(0.66rem);
}
.supportCards:hover span {
  background-color: rgba(255, 255, 255, 0.67);
}
.supportCards:hover span svg {
  fill: #000000;
}

.loginModal {
  background: rgba(0, 0, 0, 0.45) !important;
}
.loginModal .modal-content {
  border-radius: 1rem;
}
.loginModal .modal-title {
  color: #132144;
  font-weight: 700;
  text-align: center;
}
.loginModal .modal-title span {
  color: #fb481f;
}
.loginModal .modal-body {
  padding: 1rem 2rem;
}
.loginModal .modal-footer {
  justify-content: center;
  padding: 2rem;
}
.loginModal .modal-footer button {
  width: 100%;
  padding: 0.5rem;
  margin: 0.5rem 0;
  border-radius: 0.5rem;
}
.loginModal .modal-footer a {
  text-decoration: none;
  color: #ffffff;
}
.loginModal .btn {
  transition: all 0.3s ease;
}
.loginModal .btn-secondary {
  background-color: #fb481f;
  border-color: #f95c39;
}
.loginModal .btn-secondary:hover {
  background-color: #f95c39;
}
.loginModal .btn-primary {
  background-color: #404040;
  border-color: #404040;
}
.loginModal .btn-primary:hover {
  background-color: #191919;
}

.trailForm {
  display: block;
  padding: 3rem;
  margin-top: -6.4rem;
  background-image: url(../../assets/img/trail_fornPattern.svg);
  background-repeat: no-repeat;
  background-position: top right;
  height: 100vh;
  background-size: contain;
}
@media only screen and (max-width: 75em) {
  .trailForm {
    padding: 1.5rem;
  }
}
.trailForm .trailogo {
  margin-bottom: 1rem;
}
.trailForm .trailogo img {
  height: 4rem;
}
.trailForm .sec-back {
  background-color: transparent;
  border: none;
  font-weight: 600;
  font-size: 1.66rem;
}
.trailForm .sec-back svg {
  height: 1.66rem;
  width: 1.66rem;
}
.trailForm .formColum {
  display: grid;
  grid-template-columns: 1fr 40%;
  column-gap: 6rem;
  margin-top: 2rem;
  vertical-align: middle;
}
@media only screen and (max-width: 75em) {
  .trailForm .formColum {
    grid-template-columns: 1fr;
  }
}
.trailForm .formColum .form-check-input {
  width: 1.69rem;
  height: 1.69rem;
  box-shadow: none;
  background-color: #ffffff;
  border-color: #f95c39;
}
.trailForm .formColum .form-check-input:focus {
  border-color: #ffaf37;
  outline: 0;
  box-shadow: none;
}
.trailForm .formColum .form-check-input:checked {
  background-color: #f95c39;
  border-color: #f95c39;
  box-shadow: none;
}
.trailForm .formColum .form-container {
  display: grid;
  padding-left: 6rem;
}
@media only screen and (max-width: 75em) {
  .trailForm .formColum .form-container {
    padding-left: 0;
  }
}
.trailForm .formColum .form-container h2 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}
.trailForm .formColum .form-container p {
  color: #404040;
}
.trailForm .formColum .form-container .formClassification {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 4.4rem;
  margin-top: 1rem;
}
@media only screen and (max-width: 48em) {
  .trailForm .formColum .form-container .formClassification {
    grid-template-columns: 1fr;
  }
}
.trailForm .formColum .form-container .formClassification .dom-colum {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
}
.trailForm .formColum .right-bg {
  align-items: center;
  justify-content: center;
  display: grid;
}
.trailForm .formColum .right-bg img {
  max-height: 46rem;
  max-width: 100%;
}

.siteHeader {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: auto;
  background-color: #191919;
  z-index: 1020;
  transition: all 0.3s ease;
}
.siteHeader.hiddenbar {
  top: -3.7rem;
}

.topbar {
  display: flex;
  font-size: 1.33rem;
  color: #e0d5f5;
  padding: 0.69rem 3rem;
  height: 3.7rem;
  align-items: center;
  background-color: #323336;
}
@media only screen and (max-width: 35em) {
  .topbar {
    padding: 0.69rem 2rem;
  }
}
.topbar a {
  display: inline-flex;
}
.topbar a:link, .topbar a:visited {
  color: #e0d5f5;
  transition: all 0.3s ease;
  text-decoration: none;
}
.topbar a:hover, .topbar a:focus {
  outline: none;
  color: #f95c39;
}
.topbar .rightbar {
  margin-left: auto;
  display: inline-flex;
  align-items: center;
}
.topbar .rightbar svg {
  height: 2.2rem;
  width: 2.8rem;
  margin-right: 0.44rem;
}
.topbar .rightbar .languages {
  display: inline-flex;
  align-items: center;
}
.topbar .rightbar .languages i {
  font-size: 1.1rem;
}
.topbar .rightbar a {
  margin: 0 1rem;
}
.topbar .leftbar {
  display: flex;
}
@media only screen and (max-width: 35em) {
  .topbar .leftbar .hiddenid {
    display: none;
  }
}
.topbar .leftbar a {
  margin-right: 1.5rem;
}
.topbar .leftbar a i {
  color: #f95c39;
  margin-right: 0.66rem;
}
.topbar .leftbar a:hover i {
  color: #aaaaaa;
}

.menubar {
  display: flex;
  align-items: center;
  padding: 1rem 3rem;
}
@media only screen and (max-width: 35em) {
  .menubar {
    padding: 1rem 2rem;
  }
}
.menubar .logo {
  text-decoration: none;
}
.menubar .logo img {
  height: 4.4rem;
}
@media only screen and (max-width: 37.5em) {
  .menubar .logo img {
    height: 3.7rem;
  }
}
.menubar .logo:hover, .menubar .logo:focus {
  outline: none;
}
.menubar .topnav {
  margin-left: auto;
  position: relative;
}
@media only screen and (max-width: 75em) {
  .menubar .topnav {
    display: grid;
    position: fixed;
    width: max-content;
    height: 100vh;
    margin: 0;
    z-index: 1020;
    left: -100%;
    top: 0;
    bottom: 0;
    background-color: #191919;
    align-content: flex-start;
    padding: 3rem;
    transform: translateX(-100%);
    transition: all 0.3s ease;
  }
  .menubar .topnav.showMenu {
    transform: translateX(0);
    left: 0;
  }
}
.menubar .navlinks {
  display: flex;
  font-weight: 500;
  align-items: center;
  font-size: 1.72rem;
}
@media only screen and (max-width: 75em) {
  .menubar .navlinks {
    display: grid;
  }
}
.menubar .navlinks li {
  position: relative;
  display: inline-flex;
  align-items: center;
}
@media only screen and (max-width: 75em) {
  .menubar .navlinks li {
    display: grid;
  }
}
.menubar .navlinks li.dropdown:hover .dropDownBox {
  display: grid;
}
.menubar .navlinks li a,
.menubar .navlinks li button {
  display: inline-flex;
  padding: 0.69rem 1rem;
  color: #ffffff;
  text-decoration: none;
  transition: all 0.2s ease-out;
  align-items: center;
  text-transform: capitalize;
}
.menubar .navlinks li a i,
.menubar .navlinks li button i {
  font-size: 1.1rem;
  margin-left: 0.69rem;
  display: inline-flex;
  transition: all 0.2s ease-out;
  color: #aaaaaa;
}
.menubar .navlinks li a svg,
.menubar .navlinks li button svg {
  width: 2rem;
  height: 2rem;
  margin-right: 0.44rem;
}
.menubar .navlinks li a:link, .menubar .navlinks li a:visited, .menubar .navlinks li a:focus,
.menubar .navlinks li button:link,
.menubar .navlinks li button:visited,
.menubar .navlinks li button:focus {
  outline: none;
}
.menubar .navlinks li a:active, .menubar .navlinks li a.active, .menubar .navlinks li a:hover,
.menubar .navlinks li button:active,
.menubar .navlinks li button.active,
.menubar .navlinks li button:hover {
  color: #f95c39;
}
.menubar .navlinks li a:active i, .menubar .navlinks li a.active i, .menubar .navlinks li a:hover i,
.menubar .navlinks li button:active i,
.menubar .navlinks li button.active i,
.menubar .navlinks li button:hover i {
  transform: rotate(180deg);
  color: #f95c39;
}
.menubar .navlinks li a.nav-btn,
.menubar .navlinks li button.nav-btn {
  background-color: #f95c39;
  margin: 0 1rem;
  padding: 0.44rem 2.2rem;
  border-radius: 4px;
  border: 2px solid #f95c39;
  color: #ffffff;
}
.menubar .navlinks li a.nav-btn:last-child,
.menubar .navlinks li button.nav-btn:last-child {
  margin-right: 0;
}
.menubar .navlinks li a.nav-btn-line,
.menubar .navlinks li button.nav-btn-line {
  background-color: transparent;
}
.menubar .navlinks li a.nav-btn:hover, .menubar .navlinks li a.nav-btn:focus,
.menubar .navlinks li button.nav-btn:hover,
.menubar .navlinks li button.nav-btn:focus {
  background-color: #f95c39;
  color: #ffffff;
}
.menubar .navlinks li a.nav-btn-text,
.menubar .navlinks li button.nav-btn-text {
  background-color: transparent;
  border-color: transparent;
  color: #f9b303;
  padding: 0.33rem 0;
}
.menubar .navlinks li a.nav-btn-text:hover, .menubar .navlinks li a.nav-btn-text:focus,
.menubar .navlinks li button.nav-btn-text:hover,
.menubar .navlinks li button.nav-btn-text:focus {
  background-color: transparent;
}
.menubar .navlinks li.active a {
  color: #f95c39;
  outline: none;
}
.menubar .navlinks li.active a i {
  transform: rotate(180deg);
  color: #f95c39;
}

.mobilenav {
  height: 3.33rem;
  width: 3.33rem;
  background-color: #f95c39;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  display: none;
  transition: all 0.3s ease;
  margin-left: auto;
}
@media only screen and (max-width: 75em) {
  .mobilenav {
    display: inline-flex;
  }
}
.mobilenav svg {
  fill: #ffffff;
  height: 2.22rem;
  width: 2.22rem;
}
.mobilenav:hover, .mobilenav:focus {
  background-color: #fdae3d;
}
.mobilenav:hover svg, .mobilenav:focus svg {
  fill: #191919;
}

.hero-inner {
  height: 42.4rem;
  display: grid;
  align-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  z-index: 1;
}
@media only screen and (max-width: 37.5em) {
  .hero-inner {
    height: 37rem;
  }
}
.hero-inner::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.31);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
}
.hero-inner h1 {
  font-size: 3.7rem;
  color: #ffffff;
  font-weight: 700;
  z-index: 1;
  position: relative;
  animation: serviceBox 3s ease-out;
}
@media only screen and (max-width: 48em) {
  .hero-inner h1 {
    font-size: 3.2rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .hero-inner h1 {
    font-size: 2.8rem;
  }
  .hero-inner h1 br {
    display: none;
  }
}
.hero-inner h1 small {
  margin-bottom: 0.66rem;
  display: flex;
  font-weight: 500;
  font-size: 55%;
  align-items: center;
}
.hero-inner h1 small a:link, .hero-inner h1 small a:visited, .hero-inner h1 small a:focus {
  color: #ffffff;
  text-decoration: none;
}
.hero-inner h1 small a:hover {
  color: #ffaf37;
}
.hero-inner h1 small i {
  margin: 0 1rem;
  color: #f95c39;
}
.hero-inner.pricing {
  background-image: url(../img/pricing_banner.jpg);
}
.hero-inner.features {
  background-image: url(../img/features_banner.jpg);
}
.hero-inner.industries {
  background-image: url(../img/industries_banner.jpg);
}
.hero-inner.solutions {
  background-image: url(../img/solution_banner.jpg);
}
.hero-inner.deployable {
  background-image: url(../img/deploy_banner.jpg);
}
.hero-inner.customizable {
  background-image: url(../img/customizable-solutions-banner.jpg);
}
.hero-inner.contact {
  background-image: url(../img/contact_banner.jpg);
}
.hero-inner.industry_government {
  background-image: url(../img/header_gov.jpg);
}
.hero-inner.industry_professional {
  background-image: url(../img/header_professional.jpg);
}
.hero-inner.industry_education {
  background-image: url(../img/header_education.jpg);
}
.hero-inner.industry_healthcare {
  background-image: url(../img/header_healthcare.jpg);
}
.hero-inner.industry_finance {
  background-image: url(../img/header_finance.jpg);
}
.hero-inner.industry_realestate {
  background-image: url(../img/header_realestate.jpg);
}
.hero-inner.industry_travel {
  background-image: url(../img/header_travel.jpg);
}
.hero-inner.industry_ites {
  background-image: url(../img/header_it.jpg);
}
.hero-inner.blogs {
  background-image: url(../img/blog_banner.jpg);
}
.hero-inner.blog-details {
  background-image: url(../img/blog_content_banner.jpg);
}
.hero-inner.privacy {
  background-image: url(../img/privacy_banner.jpg);
}
.hero-inner.disclaimer {
  background-image: url(../img/disclaimer_banner.jpg);
}
.hero-inner.faq {
  background-image: url(../img/faq_banner.jpg);
}
.hero-inner.cookies {
  background-image: url(../img/cookies_banner.jpg);
}
.hero-inner.support {
  background-image: url(../img/support_header.jpg);
}
.hero-inner.dark {
  background-color: #191919;
  align-content: flex-start;
  height: auto;
  padding-bottom: 1rem;
}
.hero-inner.dark .graybar {
  padding: 2rem;
  background-color: #737373;
  display: grid;
  align-content: center;
  margin: 4rem 0;
}
.hero-inner.dark .graybar h1 {
  margin: 0;
}
.hero-inner.dark .graybar h1 small {
  margin: 0;
}
.hero-inner.dark .sectitle_01 {
  color: #ffffff;
}
.hero-inner.dark .sectitle_01, .hero-inner.dark .searchbox {
  z-index: 1;
  position: relative;
}

.containers p {
  text-align: justify;
}
.containers p strong {
  color: #404040;
  font-weight: 600;
}
@media only screen and (max-width: 48em) {
  .containers p {
    text-align: left;
  }
}

.hero {
  height: calc(100vh - 7.3rem);
  background-color: #edf0f7;
  display: grid;
  width: 100%;
  grid-template-columns: 56% 44%;
  align-content: flex-start;
}
.hero .heroContent {
  padding: 6rem 2rem 4rem 11rem;
}
@media only screen and (max-width: 85.5em) {
  .hero .heroContent {
    padding: 6rem 2rem 4rem 4rem;
  }
}
.hero .heroContent p {
  line-height: 1.66;
}
.hero .heroContent h2 {
  font-size: 3.7rem;
  font-weight: 700;
  margin: 3rem 0;
  line-height: 1.6;
}
.hero .heroContent h2 span {
  color: #f95c39;
}
.hero .heroContent .jumbo {
  background-color: #ffffff;
  padding: 1.5rem;
  border-radius: 5px;
  margin: 0 auto;
  box-shadow: 0 0 1.1rem rgba(25, 25, 25, 0.09);
}
.hero .heroContent .jumbo h1 {
  text-align: center;
  margin: 0;
  line-height: normal;
}
@media only screen and (max-width: 85.5em) {
  .hero .heroContent .jumbo h1 {
    font-size: 2.2rem;
  }
}
.hero .heroContent .jumbo strong {
  font-weight: 600;
  color: #0d5854;
}
.hero .heroContent .logoGroup {
  display: flex;
  margin: 3rem 0;
}
.hero .heroContent .logoGroup img {
  margin-right: 1.5rem;
  max-height: 4.4rem;
  box-shadow: 0.5rem 0.5rem 1rem rgba(25, 25, 25, 0.18);
  padding: 0.66rem 1.33rem;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  background-color: rgba(255, 255, 255, 0.45);
}
.hero .heroContent .logoGroup img:hover {
  background-color: rgba(255, 255, 255, 0.9);
}
.hero .heropic {
  position: relative;
  display: flex;
  transition: all ease-in-out;
}
.hero .heropic::after {
  position: absolute;
  bottom: -4rem;
  background-color: #f95c39;
  right: 0;
  content: "";
  width: 90%;
  height: 15rem;
  z-index: 0;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  animation: colors 3s ease-in-out infinite;
}
.hero .heropic img {
  max-width: 100%;
  margin-left: auto;
  z-index: 2;
}

.rollingrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  column-gap: 3.5rem;
}
.rollingrid .midiv {
  padding: 3.5rem 2.5rem;
  text-align: center;
  border-radius: 3rem;
  background-color: #f9f1e8;
  border: 2px solid #f95c39;
  transition: all 0.3s ease;
  display: grid;
  align-content: center;
  justify-items: center;
}
.rollingrid .midiv .textlinks:hover, .rollingrid .midiv .textlinks:focus {
  color: #ffffff;
}
.rollingrid .midiv p {
  text-align: center;
  margin: 2rem;
}
.rollingrid .midiv h2 {
  font-size: 3rem;
  font-weight: 600;
}
.rollingrid .midiv h2 strong {
  color: #f95c39;
}
.rollingrid .midiv:hover {
  background-color: #f95c39;
}
.rollingrid .midiv:hover p {
  color: #ffeeba;
}
.rollingrid .midiv:hover h2 strong {
  color: #ffffff;
}
.rollingrid .scrollicons {
  position: relative;
  transform: translate3d(0, 0, 0);
}
.rollingrid .scrollicons .animscroll h4 {
  font-weight: 600;
  font-size: 2.2rem;
  margin-bottom: 1.9rem;
}
.rollingrid .scrollicons .animscroll .iconbadges {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 3.5rem;
  margin-bottom: 2rem;
}
.rollingrid .scrollicons .animscroll .iconbadges .ibadge p {
  text-align: center;
  color: #191919;
  font-weight: 600;
  margin-top: 0.69rem;
}
.rollingrid .scrollicons .animscroll .iconbadges .ibadge span {
  display: grid;
  border-radius: 0.5rem;
  justify-content: center;
  padding: 2rem;
  transition: all 0.3s ease;
}
.rollingrid .scrollicons .animscroll .iconbadges .ibadge span:hover {
  background-color: #f95c39;
}
.rollingrid .scrollicons .animscroll .iconbadges .ibadge span svg {
  height: 7.3rem;
  width: 7.3rem;
}

.twocolgrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 15%;
  align-content: center;
}
.twocolgrid .textside {
  display: grid;
  align-content: center;
}
.twocolgrid .textside h2 {
  font-size: 2.7rem;
  margin-bottom: 2rem;
}
.twocolgrid .textside h2 strong {
  color: #f95c39;
}
.twocolgrid .textside h2 span {
  display: flex;
  color: #f95c39;
  font-size: 3.2rem;
  font-weight: 600;
  padding-bottom: 2rem;
}
.twocolgrid .imgside {
  position: relative;
  padding: 5rem 0;
}
.twocolgrid .imgside img {
  max-width: 100%;
  position: relative;
  z-index: 100;
}
.twocolgrid .imgside::after {
  height: 100%;
  top: 0;
  content: "";
  width: 75%;
  position: absolute;
  z-index: 1;
  right: 0;
}
.twocolgrid .imgside.blue::after {
  background-color: #ceecff;
}
.twocolgrid .imgside.pink::after {
  background-color: #ffe3e1;
}
.twocolgrid .imgside.aqua::after {
  background-color: #d9f4e5;
}
.twocolgrid .imgside.orange::after {
  background-color: #ffeeba;
}
.twocolgrid .imgside.left::after {
  left: 0;
}

.opinions {
  display: grid;
  grid-template-columns: 37rem 1fr;
  column-gap: 5rem;
  width: 100%;
  align-content: center;
  background-color: #f7f7f7;
}
.opinions .wordings {
  display: grid;
  align-content: center;
}
.opinions .wordings img {
  height: 5.5rem;
}
.opinions .wordings p {
  font-size: 1.9rem;
  margin: 2rem 0;
  color: #191919;
  text-align: left;
  font-weight: 500;
}
.opinions .wordings h4 {
  font-size: 2.5rem;
}
.opinions .wordings h4 small {
  display: flex;
  font-size: 1.5rem;
  color: #737373;
  margin-top: 0.69rem;
}
.opinions .pictures {
  display: flex;
  position: relative;
  justify-content: flex-end;
  border-radius: 2rem;
  overflow: hidden;
}
.opinions .pictures::after {
  width: 100%;
  bottom: 0;
  content: "";
  height: 82%;
  position: absolute;
  z-index: 1;
  right: 0;
  border-radius: 2rem;
}
.opinions .pictures.gray::after {
  background-color: #e0d5f5;
}
.opinions .pictures.blue::after {
  background-color: #ceecff;
}
.opinions .pictures.orange::after {
  background-color: #ffeeba;
}
.opinions .pictures img {
  max-width: 100%;
  z-index: 2;
}

#customersays .carousel-indicators {
  position: relative;
  justify-content: start;
  margin: 2rem 0 0 0;
}
#customersays .carousel-indicators [data-bs-target] {
  width: 5rem;
  height: 5rem;
  text-indent: 0;
  background-color: transparent;
}
#customersays .carousel-indicators [data-bs-target] img {
  max-width: 100%;
}

footer {
  background-color: #191919;
  padding: 1.5rem 0;
  display: flex;
  flex-direction: column;
}
footer .footop {
  padding: 2rem 0;
  border-bottom: 1px solid #404040;
}
footer .footcolumns {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  padding: 2rem 0;
}
@media only screen and (max-width: 56.25em) {
  footer .footcolumns {
    grid-template-columns: 1fr;
    row-gap: 2rem;
  }
}
footer .footcolumns h3 {
  font-size: 2.2rem;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 2rem;
}
footer .footcolumns .footaddress ul {
  font-size: 1.55rem;
}
footer .footcolumns .footaddress ul li {
  display: block;
  margin: 0.69rem 0;
  color: #8f8f9b;
}
footer .footcolumns .footaddress ul li a:link, footer .footcolumns .footaddress ul li a:visited {
  color: #8f8f9b;
  transition: all 0.3s ease;
  text-decoration: none;
  display: inline-flex;
}
footer .footcolumns .footaddress ul li a:hover, footer .footcolumns .footaddress ul li a:focus {
  outline: none;
  color: #f88216;
}
footer .footcolumns .footaddress img {
  height: 4.4rem;
  display: flex;
  margin-bottom: 2rem;
}
@media only screen and (max-width: 37.5em) {
  footer .footcolumns .footaddress img {
    height: 3.7rem;
  }
}
footer .footcolumns .footlinks ul {
  font-size: 1.55rem;
}
footer .footcolumns .footlinks ul li {
  display: flex;
  margin: 0.69rem 0;
  position: relative;
  padding-left: 2rem;
}
footer .footcolumns .footlinks ul li::before {
  content: "";
  background-image: url("../img/chevron-right.svg");
  background-position: left center;
  background-size: 1.3rem;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 1.69rem;
  height: 1.69rem;
  left: 0;
}
footer .footcolumns .footlinks ul li a:link, footer .footcolumns .footlinks ul li a:visited {
  color: #8f8f9b;
  transition: all 0.3s ease;
  text-decoration: none;
}
footer .footcolumns .footlinks ul li a:hover, footer .footcolumns .footlinks ul li a:focus {
  outline: none;
  color: #f88216;
}
footer .footcolumns .footform input {
  width: 100%;
  padding: 1rem;
  background-color: #191919;
  border: 1px solid #8f8f9b;
  color: #e0d5f5;
  border-radius: 0;
  font-family: "Red Hat Text", sans-serif;
  font-size: 1.55rem;
}
footer .footcolumns .footform input:focus {
  outline: none;
  background-color: #191919;
}
footer .footcolumns .footform input:focus:invalid {
  border: 1px solid #f72112;
}
footer .footcolumns .footform button {
  font-size: 2rem;
  background-color: #f95c39;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  padding: 0.69rem 5rem 0.69rem 0.69rem;
  border: none;
  position: relative;
  width: 73%;
  margin-top: 1.69rem;
  font-family: "Red Hat Text", sans-serif;
  overflow: hidden;
}
footer .footcolumns .footform button::after {
  content: "";
  background-image: url("../img/chevron-right.svg");
  background-position: center;
  background-size: 2rem;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 5rem;
  height: 100%;
  right: 0;
  background-color: #c62906;
  transition: all 0.3s ease;
}
footer .footcolumns .footform button:hover::after {
  background-color: #fb481f;
}
footer .copyrights {
  border-top: 1px solid #404040;
  text-align: center;
  padding-top: 4.4rem;
  color: #8f8f9b;
  position: relative;
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 56.25em) {
  footer .copyrights {
    display: grid;
  }
}
footer .copyrights p {
  text-align: center;
  color: #8f8f9b;
  font-size: 1.55rem;
  display: flex;
  align-items: center;
}
footer .copyrights p a {
  display: inline-flex;
  margin: 0 0.44rem;
}
footer .copyrights p a:link, footer .copyrights p a:visited {
  color: #8f8f9b;
  transition: all 0.3s ease;
  text-decoration: none;
}
footer .copyrights p a:hover, footer .copyrights p a:focus {
  outline: none;
  color: #f88216;
}

.footmedias {
  display: inline-flex;
  width: max-content;
  background-color: #191919;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 1rem;
  top: -2.8rem;
}
@media only screen and (max-width: 56.25em) {
  .footmedias {
    top: auto;
    position: relative;
    left: auto;
    transform: translateX(0);
    padding: 0;
  }
}

.floated-title {
  padding: 3rem;
  border-radius: 2rem;
  background-color: #ffffff;
  transform: translateY(-6.3rem);
  display: grid;
  box-shadow: 0 0 1.55rem 0.3rem rgba(0, 0, 0, 0.09);
  position: relative;
  z-index: 10;
}
@media only screen and (max-width: 37.5em) {
  .floated-title {
    padding: 2rem;
  }
}
.floated-title.notrans {
  transform: translateY(0);
  margin-top: 6rem;
}
.floated-title.centered h2,
.floated-title.centered p {
  text-align: center;
}
.floated-title.split {
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
}
@media only screen and (max-width: 75em) {
  .floated-title.split {
    grid-template-columns: 1fr;
  }
  .floated-title.split h2,
.floated-title.split p {
    text-align: center;
  }
}
.floated-title.split picture img {
  position: absolute;
  bottom: 3rem;
  z-index: 10;
  max-width: 100%;
}
@media only screen and (max-width: 75em) {
  .floated-title.split picture {
    display: none;
  }
}
.floated-title h2 {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 2rem;
}
@media only screen and (max-width: 37.5em) {
  .floated-title h2 {
    font-size: 2.5rem;
  }
}

.price-table {
  transform: translateY(-1rem);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 4rem;
  row-gap: 3rem;
}
.price-table .pricePlans {
  padding: 4rem 0 3rem 0;
  box-shadow: 0 0 1.55rem 0.3rem rgba(0, 0, 0, 0.09);
  border-radius: 1rem;
  background-color: rgba(206, 236, 255, 0.18);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}
.price-table .pricePlans::before {
  content: "";
  width: 73%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 2rem;
  top: -1rem;
  z-index: 1;
  border-radius: 100px;
}
.price-table .pricePlans.sky::before {
  background-image: linear-gradient(to right, #100bbc, #736ae7);
  animation: colorheight 2s ease 1s;
}
.price-table .pricePlans.sea::before {
  background-image: linear-gradient(to right, #03307c, #2deda7);
  animation: colorheight 2s ease 2s;
}
.price-table .pricePlans.dusk::before {
  background-image: linear-gradient(to right, #f72112, #f95c39);
  animation: colorheight 2s ease 3s;
}
.price-table .pricePlans:hover {
  background-color: #ffffff;
}
.price-table .pricePlans .planheading {
  text-align: center;
  margin-bottom: 3rem;
}
.price-table .pricePlans .planheading h4 {
  color: #f95c39;
  font-size: 1.9rem;
  font-weight: 700;
}
.price-table .pricePlans .planheading h3 {
  font-size: 2.8rem;
}
.price-table .pricePlans .planheading h2 {
  font-size: 4.4rem;
  font-weight: 700;
  margin: 2.5rem 0;
}
.price-table .pricePlans .planheading h2 small {
  font-size: 40%;
}
.price-table .pricePlans .planheading .addon {
  display: flex;
  margin-top: 2rem;
  justify-content: center;
}
.price-table .pricePlans .planheading .addon.white {
  color: #ffffff;
}
.price-table .pricePlans .planheading p {
  text-align: center;
  font-size: 1.81rem;
}
.price-table .pricePlans .planheading p span {
  display: block;
}
.price-table .pricePlans .planheading .subscribe-btn {
  background-color: #e0d5f5;
  display: flex;
  justify-content: center;
  margin: 2rem 0;
}
.price-table .pricePlans .planheading .subscribe-btn a {
  padding: 1.3rem 3.3rem;
  background-color: #f95c39;
  text-transform: uppercase;
  border: none;
  color: #ffffff;
  transition: all 0.3s ease;
  font-size: 1.73;
}
.price-table .pricePlans .planheading .subscribe-btn a:link, .price-table .pricePlans .planheading .subscribe-btn a:visited {
  text-decoration: none;
}
.price-table .pricePlans .planheading .subscribe-btn a:hover, .price-table .pricePlans .planheading .subscribe-btn a:focus, .price-table .pricePlans .planheading .subscribe-btn a:active {
  background-color: #404040;
}
.price-table .pricePlans .pricebody h4 {
  background-color: #edf0f7;
  font-size: 1.9rem;
  font-weight: 600;
  padding: 1.2rem;
  text-align: center;
  margin: 2rem 0;
}
.price-table .pricePlans .pricebody ul {
  color: #737373;
  display: grid;
  padding: 0 2.5rem;
  line-height: 2;
}
.price-table .pricePlans .pricebody ul li {
  position: relative;
  padding-left: 2.8rem;
  display: flex;
}
.price-table .pricePlans .pricebody ul li::before {
  content: "➤";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  color: #f95c39;
}

.faqcolumns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 3rem;
  align-items: baseline;
  align-content: flex-start;
}

#faquestions .accordion-button,
#faquestions02 .accordion-button {
  display: flex;
  font-size: 2rem;
  align-items: center;
  position: relative;
  margin: 0.69rem 0;
  outline: none;
  font-weight: 500;
}
#faquestions .accordion-button::after,
#faquestions02 .accordion-button::after {
  content: "✛";
  background-image: none;
  color: #f95c39;
  font-size: 3rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1.5rem;
  width: auto;
  height: auto;
}
#faquestions .accordion-button:focus,
#faquestions02 .accordion-button:focus {
  outline: none;
  box-shadow: none;
}
#faquestions .accordion-button:not(.collapsed),
#faquestions02 .accordion-button:not(.collapsed) {
  box-shadow: none;
  background-color: #ffffff;
  color: #f95c39;
}
#faquestions .accordion-button:not(.collapsed)::after,
#faquestions02 .accordion-button:not(.collapsed)::after {
  content: "⚊";
  background-image: none;
  transform: rotate(0);
  font-size: 3rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1.5rem;
  width: auto;
  height: auto;
}

#industrial .accordion-button {
  display: flex;
  font-size: 2rem;
  align-items: center;
  position: relative;
  margin: 0.69rem 0;
  outline: none;
  font-weight: 500;
}
@media only screen and (max-width: 62em) {
  #industrial .accordion-button {
    padding-right: 4rem;
    font-size: 1.69rem;
  }
}
#industrial .accordion-button::after {
  content: "✛";
  background-image: none;
  color: #f95c39;
  font-size: 3rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1.5rem;
  width: auto;
  height: auto;
}
#industrial .accordion-button:focus {
  outline: none;
  box-shadow: none;
}
#industrial .accordion-button:not(.collapsed) {
  box-shadow: none;
  background-color: #ffffff;
  color: #f95c39;
}
#industrial .accordion-button:not(.collapsed)::after {
  content: "⚊";
  background-image: none;
  transform: rotate(0);
  font-size: 3rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1.5rem;
  width: auto;
  height: auto;
}
#industrial .accordion-body p {
  text-align: left;
  padding-right: 5rem;
}

.featuresGrid {
  display: grid;
  grid-template-columns: 1fr minmax(25%, auto);
  padding: 0 3rem;
  box-shadow: 0 0 1.55rem 0.3rem rgba(0, 0, 0, 0.09);
  align-items: center;
  margin: 1rem 0 5rem 0;
  column-gap: 1.5rem;
  overflow-x: hidden;
}
@media only screen and (max-width: 75em) {
  .featuresGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 48em) {
  .featuresGrid {
    grid-template-columns: 1fr;
  }
}
.featuresGrid.mrauto {
  grid-template-columns: minmax(25%, auto) 1fr;
}
@media only screen and (max-width: 75em) {
  .featuresGrid.mrauto {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 48em) {
  .featuresGrid.mrauto {
    grid-template-columns: 1fr;
  }
}
.featuresGrid img {
  max-width: 100%;
  display: flex;
}
@media only screen and (max-width: 48em) {
  .featuresGrid article {
    padding: 2rem 0;
  }
}
.featuresGrid h2 {
  font-size: 3.7rem;
  font-weight: 700;
  margin-bottom: 2rem;
}
@media only screen and (max-width: 62em) {
  .featuresGrid h2 {
    font-size: 3rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .featuresGrid h2 {
    font-size: 2.5rem;
  }
}
.featuresGrid p {
  text-align: left;
}

.pricingBlue {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
}
@media only screen and (max-width: 48em) {
  .pricingBlue {
    grid-template-columns: 1fr;
  }
}
.pricingBlue a {
  background-color: #f95c39;
  padding: 2.2rem 5.5rem;
  border-radius: 0.5rem;
  color: #ffffff;
  transition: all 0.3s ease;
  font-weight: 600;
  margin-left: auto;
  border: none;
}
@media only screen and (max-width: 48em) {
  .pricingBlue a {
    margin-right: auto;
    margin-left: 0;
    margin-top: 1rem;
  }
}
@media only screen and (max-width: 35em) {
  .pricingBlue a {
    padding: 1rem 3rem;
    font-size: 1.69rem;
  }
}
.pricingBlue a:link, .pricingBlue a:visited {
  text-decoration: none;
}
.pricingBlue a:hover, .pricingBlue a:focus {
  background-color: #f7f7f7;
  color: #132144;
}
.pricingBlue p {
  color: #ceecff;
  margin-left: 3rem;
  text-align: left;
}
.pricingBlue h2 {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
  position: relative;
  color: #ffffff;
  padding-left: 3rem;
}
@media only screen and (max-width: 48em) {
  .pricingBlue h2 {
    font-size: 2.8rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .pricingBlue h2 {
    font-size: 2.2rem;
  }
}
.pricingBlue h2::before {
  content: "";
  height: 100%;
  width: 0.69rem;
  background-color: #f95c39;
  left: 0;
  z-index: 1;
  position: absolute;
}

.linked-icons {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 2rem;
  position: relative;
  z-index: 5;
  row-gap: 2rem;
  text-transform: capitalize;
}
@media only screen and (max-width: 62em) {
  .linked-icons {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 56.25em) {
  .linked-icons {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 30em) {
  .linked-icons {
    grid-template-columns: 1fr;
  }
}
.linked-icons .iconbox {
  background-color: rgba(255, 255, 255, 0.31);
  padding: 1.5rem;
  display: flex;
  border: 1px solid #cac9c9;
  border-radius: 1rem;
  align-items: center;
  transition: all 0.3s ease;
}
.linked-icons .iconbox svg {
  width: 5.5rem;
  height: 5.5rem;
  margin-right: 2rem;
  transition: all 0.3s ease;
}
.linked-icons .iconbox:link, .linked-icons .iconbox:visited {
  text-decoration: none;
  outline: none;
  color: #191919;
}
.linked-icons .iconbox:hover {
  background-color: rgba(255, 255, 255, 0.09);
  color: #f95c39;
}
.linked-icons .iconbox:hover svg {
  transform: scale(1.1);
  margin-right: 1.5rem;
}

.industry-wave-pattern {
  background-image: url(../img/Industrie_banner_pattern.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  margin-top: -20rem;
  padding-top: 19rem;
  padding-bottom: 6rem;
  z-index: 0;
}
.industry-wave-pattern::before {
  content: "";
  position: absolute;
  background-color: rgba(63, 67, 79, 0.8);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 0;
}
.industry-wave-pattern.forHome {
  margin: 0;
  padding: 6rem 0;
}
.industry-wave-pattern .linked-icons .iconbox {
  border: 1px solid #ceecff;
}
.industry-wave-pattern .linked-icons .iconbox:link, .industry-wave-pattern .linked-icons .iconbox:visited {
  color: #ffffff;
}
.industry-wave-pattern .linked-icons .iconbox:hover {
  background-color: rgba(255, 255, 255, 0.09);
}
.industry-wave-pattern .linked-icons .iconbox h4 {
  color: #ffffff;
  font-size: 1.9rem;
  font-weight: 600;
  margin: 0;
  text-transform: capitalize;
}
.industry-wave-pattern .justflex {
  z-index: 1;
  position: relative;
  margin-top: 4rem;
}
.industry-wave-pattern .sectitle_02 {
  z-index: 2;
  position: relative;
}

.industry-details {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  margin-bottom: 4rem;
}
.industry-details picture img {
  max-width: 100%;
}
.industry-details article {
  padding: 5rem 5rem 5rem 0;
  margin-right: -8rem;
  position: relative;
  z-index: 1;
  background-color: #ffffff;
  box-shadow: 0 0 1.55rem 0.3rem rgba(0, 0, 0, 0.09);
}
.industry-details article p {
  text-align: left;
  padding-left: 5rem;
}
.industry-details article p strong {
  color: #f95c39;
  font-weight: 600;
}
.industry-details article h2 {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
  position: relative;
  padding-left: 5rem;
  text-transform: capitalize;
}
.industry-details article h2::before {
  content: "";
  height: 100%;
  width: 0.69rem;
  background-color: #f95c39;
  left: 0;
  z-index: 1;
  position: absolute;
}
.industry-details.picleft article {
  margin-left: -8rem;
  margin-right: 0;
}

.solution-details {
  display: grid;
  padding-bottom: 6rem;
  grid-template-columns: repeat(2, auto);
  column-gap: 4rem;
  border-bottom: 1px solid #cac9c9;
  scroll-margin-top: 10rem;
}
@media only screen and (max-width: 62em) {
  .solution-details {
    display: grid;
    grid-template-columns: 1fr;
    padding-bottom: 3rem;
  }
}
.solution-details.nbbottom {
  border-bottom: none;
}
.solution-details figure {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  overflow-x: hidden;
  justify-content: center;
}
@media only screen and (max-width: 62em) {
  .solution-details figure {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }
}
@media only screen and (max-width: 37.5em) {
  .solution-details figure {
    grid-template-columns: 1fr;
  }
}
.solution-details figure img {
  max-width: 100%;
  position: relative;
}
.solution-details figure figcaption {
  display: flex;
  flex-direction: column;
  z-index: 10;
  padding: 2rem;
  background-color: #132144;
  width: 82%;
  box-shadow: 0 0 1.55rem 0.3rem rgba(0, 0, 0, 0.09);
  color: #f7f7f7;
  transition: all 0.3s ease;
  margin: -6.6rem auto 0 auto;
}
@media only screen and (max-width: 62em) {
  .solution-details figure figcaption {
    width: 100%;
    bottom: 0;
    left: 0;
    transform: translateX(0);
    position: relative;
  }
}
.solution-details figure figcaption h2 {
  display: flex;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1.9rem;
  position: relative;
  align-items: center;
  text-transform: capitalize;
}
.solution-details figure figcaption h2 span {
  display: inline-flex;
  background-color: rgba(255, 255, 255, 0.45);
  width: 4rem;
  height: 4rem;
  margin-right: 1rem;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.solution-details figure figcaption h2 svg {
  height: 2.8rem;
  width: 2.8rem;
}
.solution-details figure figcaption p {
  text-align: left;
  color: #6d8bdd;
  margin: 0;
}
.solution-details figure figcaption p strong {
  color: #f95c39;
  font-weight: 600;
}
.solution-details figure:hover figcaption {
  background-color: #191919;
  border-radius: 1rem;
}
.solution-details figure:hover figcaption p {
  color: #e0d5f5;
}
.solution-details article {
  padding: 3rem 0;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.solution-details article h3 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1.9rem;
}

.weeklyUpdate {
  height: 22rem;
  display: grid;
  background-image: url(../img/subscribe_pattern.png), linear-gradient(to right, #f72112, #f95c39);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
@media only screen and (max-width: 48em) {
  .weeklyUpdate {
    height: auto;
  }
}
.weeklyUpdate .subscribecolumns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 3rem;
  align-items: center;
}
@media only screen and (max-width: 48em) {
  .weeklyUpdate .subscribecolumns {
    grid-template-columns: 1fr;
    row-gap: 2rem;
    padding: 2rem 0;
  }
}
.weeklyUpdate .subscribecolumns svg {
  height: 5rem;
  width: 5rem;
  margin-right: 1rem;
}
.weeklyUpdate .subscribecolumns h2 {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
  color: #ffffff;
}
@media only screen and (max-width: 75em) {
  .weeklyUpdate .subscribecolumns h2 {
    font-size: 3rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .weeklyUpdate .subscribecolumns h2 {
    font-size: 2.5rem;
  }
}
.weeklyUpdate .subscribecolumns p {
  color: #ffffff;
  text-align: left;
  padding-left: 7rem;
}
@media only screen and (max-width: 75em) {
  .weeklyUpdate .subscribecolumns p {
    padding-left: 0;
  }
}
.weeklyUpdate .subscribecolumns .subgroup {
  position: relative;
  background-color: #ffffff;
  width: max-content;
  border-radius: 100px;
  overflow: hidden;
  width: 44rem;
  margin-left: auto;
  display: flex;
}
@media only screen and (max-width: 48em) {
  .weeklyUpdate .subscribecolumns .subgroup {
    margin-left: 0;
    width: 100%;
  }
}
.weeklyUpdate .subscribecolumns .subgroup button {
  padding: 1.7rem;
  background-color: #f95c39;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.3rem;
  border-radius: 100px;
  border: none;
  color: #ffffff;
  transition: all 0.2s ease-in;
}
.weeklyUpdate .subscribecolumns .subgroup button:hover {
  background-color: #191919;
}
.weeklyUpdate .subscribecolumns .subgroup input {
  background-color: #ffffff;
  border: none;
  padding: 2rem;
  width: 100%;
}
.weeklyUpdate .subscribecolumns .subgroup input:hover, .weeklyUpdate .subscribecolumns .subgroup input:focus {
  outline: none;
}

.solutionlist {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 3rem;
  row-gap: 3rem;
  margin-top: 1rem;
}
@media only screen and (max-width: 75em) {
  .solutionlist {
    column-gap: 2rem;
    row-gap: 2rem;
  }
}
@media only screen and (max-width: 62em) {
  .solutionlist {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 48em) {
  .solutionlist {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 35em) {
  .solutionlist {
    grid-template-columns: 1fr;
  }
}
.solutionlist .listbox {
  padding: 2.5rem;
  border: 1px solid #cac9c9;
  border-radius: 1rem;
  background-color: #ffffff;
  transition: all 0.3s ease;
}
.solutionlist .listbox p {
  text-align: left;
  margin-bottom: 1.9rem;
}
.solutionlist .listbox span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 7.3rem;
  width: 7.3rem;
  border-radius: 50%;
  border: 0.2rem solid #191919;
  margin-bottom: 2rem;
  transition: all 0.3s ease;
}
.solutionlist .listbox span svg {
  height: 4.4rem;
  width: 4.4rem;
}
.solutionlist .listbox h4 {
  font-size: 1.9rem;
  margin-bottom: 1.9rem;
  font-weight: 600;
  text-transform: capitalize;
}
.solutionlist .listbox:hover {
  box-shadow: 0 0 2rem 0.11rem rgba(0, 0, 0, 0.09);
  border-color: #ffffff;
  transform: translateY(-0.33rem);
}
.solutionlist .listbox:hover span {
  border-color: #f95c39;
}

.bottomnews {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  position: relative;
  column-gap: 3rem;
}
@media only screen and (max-width: 75em) {
  .bottomnews {
    grid-template-columns: 1fr;
  }
}
.bottomnews .newstitle {
  display: grid;
  align-content: center;
}
.bottomnews .newstitle h2 {
  font-size: 3.3rem;
  font-weight: 700;
  margin-bottom: 2rem;
}
@media only screen and (max-width: 48em) {
  .bottomnews .newstitle h2 {
    font-size: 3rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .bottomnews .newstitle h2 {
    font-size: 2.5rem;
  }
}
.bottomnews .newstitle p {
  text-align: left;
}
.bottomnews .newslider .carousel {
  position: unset;
}
.bottomnews .newslider .carousel-inner {
  position: unset;
}
.bottomnews .newslider .carousel-control-next, .bottomnews .newslider .carousel-control-prev {
  position: unset;
  width: 5.5rem;
  height: 5.5rem;
  background-color: #ffffff;
  box-shadow: 0 0 1.55rem 0.3rem rgba(0, 0, 0, 0.09);
  border-radius: 50%;
  margin-right: 2rem;
  opacity: 1;
  box-shadow: 0 0 1rem 0.44rem rgba(0, 0, 0, 0.12);
  transition: all 0.3s ease;
}
.bottomnews .newslider .carousel-control-next:last-child, .bottomnews .newslider .carousel-control-prev:last-child {
  margin-right: 0;
}
.bottomnews .newslider .carousel-control-next:hover, .bottomnews .newslider .carousel-control-prev:hover {
  background-color: #fdae3d;
  box-shadow: 0 0 0.73rem 0.33rem rgba(0, 0, 0, 0.09);
}
.bottomnews .slidecontrols {
  display: inline-flex;
  left: 0;
  bottom: 0;
  position: absolute;
  z-index: 100;
}
@media only screen and (max-width: 75em) {
  .bottomnews .slidecontrols {
    display: flex;
    justify-content: center;
    position: relative;
  }
}

.blogcards {
  margin: 1.69rem;
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 3rem;
}
@media only screen and (max-width: 75em) {
  .blogcards {
    justify-content: center;
  }
}
@media only screen and (max-width: 35em) {
  .blogcards {
    grid-template-columns: 1fr;
    row-gap: 3rem;
  }
}
.blogcards.threecards {
  grid-template-columns: repeat(3, auto);
  column-gap: 5rem;
}
@media only screen and (max-width: 62em) {
  .blogcards.threecards {
    column-gap: 3rem;
  }
}
@media only screen and (max-width: 56.25em) {
  .blogcards.threecards {
    row-gap: 3rem;
    grid-template-columns: repeat(2, auto);
  }
}
@media only screen and (max-width: 48em) {
  .blogcards.threecards {
    grid-template-columns: 1fr;
  }
}
.blogcards .blogcard {
  box-shadow: 0 0 1.55rem 0.33rem rgba(0, 0, 0, 0.11);
  overflow: hidden;
  border-radius: 0.73rem;
}
.blogcards .blogcard article {
  padding: 2rem;
  display: grid;
}
.blogcards .blogcard article p {
  text-align: left;
  margin: 2rem 0;
  color: #191919;
}
.blogcards .blogcard article p strong {
  font-weight: 600;
  color: #f95c39;
}
.blogcards .blogcard ul.dates {
  display: flex;
  justify-content: space-between;
  color: #737373;
}
.blogcards .blogcard ul.dates li {
  display: flex;
  align-items: center;
}
.blogcards .blogcard ul.dates i {
  color: #f95c39;
  font-size: 2rem;
  margin-right: 0.69rem;
}
.blogcards .blogcard img {
  max-width: 100%;
}
.blogcards .bigcard {
  transition: all 0.3s ease;
  box-shadow: 0 0 1.55rem 0.3rem rgba(0, 0, 0, 0.09);
  overflow: hidden;
  border-radius: 0.73rem;
}
.blogcards .bigcard picture {
  display: flex;
}
.blogcards .bigcard article {
  padding: 3rem;
}
.blogcards .bigcard article p {
  text-align: left;
}
.blogcards .bigcard article h4 {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1.69rem;
}
.blogcards .bigcard article h4 a:link, .blogcards .bigcard article h4 a:visited {
  text-decoration: none;
  color: #191919;
}
.blogcards .bigcard article h4 a:link:hover, .blogcards .bigcard article h4 a:link:focus, .blogcards .bigcard article h4 a:visited:hover, .blogcards .bigcard article h4 a:visited:focus {
  outline: none;
  color: #f95c39;
}
.blogcards .bigcard article h5 {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  color: #8f8f9b;
  margin: 2rem 0 0 0;
}
.blogcards .bigcard article h5 img {
  height: 4rem;
  display: inline-flex;
  width: 4rem;
  border-radius: 50%;
  margin-right: 1rem;
}
.blogcards .bigcard:hover {
  transform: translateY(-0.5rem);
  box-shadow: 0 0 2rem 0.3rem rgba(0, 0, 0, 0.09);
}

.heroSlides .carousel-inner {
  height: 77vh;
}
@media only screen and (max-width: 62em) {
  .heroSlides .carousel-inner {
    height: 55vh;
  }
}
@media only screen and (max-width: 48em) {
  .heroSlides .carousel-inner {
    height: 47vh;
  }
}
.heroSlides .carousel-item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 77vh;
  width: 100%;
}
@media only screen and (max-width: 62em) {
  .heroSlides .carousel-item {
    height: 55vh;
  }
}
@media only screen and (max-width: 48em) {
  .heroSlides .carousel-item {
    height: 47vh;
  }
  .heroSlides .carousel-item button {
    margin: 1rem auto;
  }
}
.heroSlides .carousel-item h2 {
  font-size: 6rem;
  font-weight: 600;
  color: #ffffff;
  animation: movetoBottom 3s ease-out;
}
.heroSlides .carousel-item h2 span {
  display: block;
}
@media only screen and (max-width: 75em) {
  .heroSlides .carousel-item h2 {
    font-size: 5rem;
  }
}
@media only screen and (max-width: 62em) {
  .heroSlides .carousel-item h2 {
    font-size: 4rem;
  }
}
@media only screen and (max-width: 48em) {
  .heroSlides .carousel-item h2 {
    font-size: 3.3rem;
    text-align: center;
  }
}
@media only screen and (max-width: 37.5em) {
  .heroSlides .carousel-item h2 {
    font-size: 2.5rem;
  }
}
.heroSlides .carousel-item.slide_001 {
  background-image: url(../img/slider1.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}
.heroSlides .carousel-item.slide_002 {
  background-image: url(../img/slider2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}
.heroSlides .carousel-control-next,
.heroSlides .carousel-control-prev {
  width: 8.2rem;
}
@media only screen and (max-width: 48em) {
  .heroSlides .carousel-control-next,
.heroSlides .carousel-control-prev {
    width: 5rem;
  }
}

.homecards {
  display: grid;
}
.homecards.spited {
  grid-template-columns: 1fr 45rem;
  column-gap: 3rem;
}
@media only screen and (max-width: 56.25em) {
  .homecards.spited {
    grid-template-columns: 1fr;
    row-gap: 3rem;
  }
}
@media only screen and (max-width: 40em) {
  .homecards a {
    margin: auto;
  }
}
.homecards article, .homecards picture {
  position: relative;
  z-index: 1;
  display: grid;
  align-content: flex-end;
}
.homecards article {
  padding: 4rem;
}
.homecards h3 {
  color: #ffffff;
  font-size: 3.3rem;
}
@media only screen and (max-width: 40em) {
  .homecards h3 {
    font-size: 2.5rem;
  }
}
.homecards img {
  max-width: 100%;
}
.homecards .cardorange {
  background-image: url(../img/solution_link_bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  height: 31rem;
  display: grid;
  align-content: center;
  transition: all 0.3s ease;
}
.homecards .cardorange::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(to top right, rgba(248, 130, 22, 0.89), rgba(205, 11, 63, 0.89));
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 0;
  transition: all 0.3s ease;
}
.homecards .cardorange:hover::before {
  background-image: linear-gradient(to top, rgba(248, 130, 22, 0.89), rgba(205, 11, 63, 0.89));
}
.homecards .cardocean {
  background-image: url(../img/price_link_bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  height: 31rem;
  display: grid;
  align-content: center;
  transition: all 0.3s ease;
}
.homecards .cardocean::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(to right bottom, rgba(45, 237, 167, 0.89), rgba(3, 48, 124, 0.89));
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 0;
  transition: all 0.3s ease;
}
.homecards .cardocean:hover::before {
  background-image: linear-gradient(to bottom, rgba(45, 237, 167, 0.89), rgba(3, 48, 124, 0.89));
}
.homecards.cardblue {
  background-image: url(../img/feature_link_pattern.png);
  background-repeat: no-repeat;
  background-position: top right;
  background-color: #307fe2;
  grid-template-columns: repeat(2, auto);
  column-gap: 6rem;
  position: relative;
  margin-bottom: 3rem;
  transition: all 0.3s ease;
}
@media only screen and (max-width: 48em) {
  .homecards.cardblue {
    grid-template-columns: auto;
  }
  .homecards.cardblue article, .homecards.cardblue picture {
    display: grid;
    justify-content: center;
    text-align: center;
  }
}
.homecards.cardblue::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(to right, #242833, transparent);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 0;
  transition: all 0.3s ease;
}
.homecards.cardblue:hover::before {
  background-image: linear-gradient(to right top, #242833, transparent);
}
.homecards.cardblue picture {
  padding: 1rem 0 0 4rem;
}
.homecards.cardyellow {
  background-image: url(../img/benefits_pattern.png);
  background-repeat: no-repeat;
  background-position: bottom left;
  background-color: #ffe29a;
  grid-template-columns: repeat(2, auto);
  column-gap: 4rem;
  position: relative;
  border-radius: 2rem;
  overflow: hidden;
}
@media only screen and (max-width: 56.25em) {
  .homecards.cardyellow {
    grid-template-columns: 1fr;
  }
}
.homecards.cardyellow h2 {
  font-size: 3.3rem;
  font-weight: 700;
  margin-bottom: 2rem;
}
@media only screen and (max-width: 37.5em) {
  .homecards.cardyellow h2 {
    font-size: 2.5rem;
  }
}
.homecards.cardyellow h2 span {
  color: #f72112;
}
.homecards.cardyellow::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(to top right, transparent, rgba(255, 206, 71, 0.89));
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 0;
}
.homecards.cardyellow .txts {
  display: grid;
  z-index: 2;
  padding: 4rem;
  align-content: center;
}
.homecards.cardyellow picture {
  position: relative;
  display: flex;
  justify-content: center;
  padding-right: 4rem;
}
.homecards.cardyellow picture img {
  position: relative;
  z-index: 10;
  max-height: 39rem;
}
.homecards.cardyellow picture::before {
  content: "";
  width: 29rem;
  height: 29rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ffffff;
  z-index: 2;
  border-radius: 50%;
  animation: orangeCircle 4s ease 3s infinite alternate;
}
.homecards.cardyellow picture::after {
  content: "";
  width: 35rem;
  height: 35rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.45);
  z-index: 1;
  border-radius: 50%;
  animation: orangeCircle-le 4s ease-in-out 3s infinite alternate;
}

.solutionotes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  margin-top: 6rem;
  column-gap: 6rem;
}
@media only screen and (max-width: 62em) {
  .solutionotes {
    grid-template-columns: 1fr;
    row-gap: 2rem;
    justify-content: center;
    text-align: center;
  }
}
.solutionotes h1 {
  font-weight: 700;
  font-size: 3.3rem;
  color: #132144;
}
@media only screen and (max-width: 48em) {
  .solutionotes h1 {
    font-size: 2.8rem;
  }
}
@media only screen and (max-width: 40em) {
  .solutionotes h1 {
    font-size: 2.5rem;
  }
}
.solutionotes h5 {
  color: #f95c39;
  font-size: 2rem;
  margin-bottom: 1.69rem;
  font-weight: 600;
}
.solutionotes p {
  color: #191919;
  margin-bottom: 0;
}
@media only screen and (max-width: 62em) {
  .solutionotes p {
    text-align: center;
  }
}

.homethumbs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 3.3rem;
  row-gap: 3.3rem;
}
@media only screen and (max-width: 75em) {
  .homethumbs {
    column-gap: 2.3rem;
    row-gap: 2.3rem;
  }
}
@media only screen and (max-width: 62em) {
  .homethumbs {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 48em) {
  .homethumbs {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 35em) {
  .homethumbs {
    grid-template-columns: 1fr;
  }
}
.homethumbs figure {
  position: relative;
  display: flex;
  border-radius: 0.69rem;
  overflow: hidden;
  margin: 0;
}
.homethumbs figure figcaption {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  color: #ffffff;
  background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.56));
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding: 1rem 1rem 3rem 3rem;
  transition: all 0.3s ease;
}
.homethumbs figure figcaption h4 {
  font-size: 2.2rem;
  margin-bottom: 0;
  transition: all 0.3s ease;
  text-transform: capitalize;
}
.homethumbs figure figcaption p {
  color: #cac9c9;
  font-size: 1.4rem;
  transition: all 0.3s ease;
}
.homethumbs figure figcaption a {
  padding: 0.33rem 1.69rem;
  font-size: 1.4rem;
  width: max-content;
  background-color: transparent;
  transition: all 0.3s ease;
  opacity: 0;
  margin-top: 1.5rem;
  margin-bottom: -5rem;
}
.homethumbs figure figcaption a:link, .homethumbs figure figcaption a:visited {
  text-decoration: none;
  color: #ffffff;
}
.homethumbs figure img {
  flex: 1;
  display: flex;
  transition: all 0.3s ease;
}
.homethumbs figure:hover img {
  transform: scale(1.1);
}
.homethumbs figure:hover figcaption {
  background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.78));
}
.homethumbs figure:hover figcaption a {
  display: flex;
  background-color: #f95c39;
  opacity: 1;
  margin-bottom: 0;
}
.homethumbs figure:hover figcaption a:hover {
  background-color: #f88216;
}

.pricingOrange {
  margin-top: 6rem;
  padding-top: 1rem;
  background-image: url(../img/pricing_bg_pattern.png);
  background-position: right top;
  background-repeat: no-repeat;
  position: relative;
  background-size: 100%;
  background-color: #ffaf37;
}
@media only screen and (max-width: 75em) {
  .pricingOrange {
    background-size: cover;
  }
}
.pricingOrange::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom right, transparent, rgba(251, 72, 31, 0.95));
  z-index: 0;
}
.pricingOrange .homePricing {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}
@media only screen and (max-width: 48em) {
  .pricingOrange .homePricing {
    grid-template-columns: 1fr;
  }
}
.pricingOrange .homePricing picture {
  position: relative;
  display: flex;
  overflow: hidden;
  padding: 1rem 6rem 0 6rem;
}
.pricingOrange .homePricing picture img {
  z-index: 3;
  margin: auto;
  display: flex;
  max-width: 100%;
}
.pricingOrange .homePricing picture::after {
  content: "";
  width: 44rem;
  height: 44rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f72112;
  z-index: 1;
  border-radius: 50%;
  bottom: -50%;
  animation: orangereds 10s ease infinite alternate;
}
.pricingOrange .homePricing article,
.pricingOrange .homePricing picture {
  z-index: 2;
}
@media only screen and (max-width: 48em) {
  .pricingOrange .homePricing article,
.pricingOrange .homePricing picture {
    display: grid;
  }
}
.pricingOrange h3 {
  color: #ffffff;
  font-size: 3.3rem;
  font-weight: 700;
  animation: serviceBox 3s ease-out;
}
@media only screen and (max-width: 48em) {
  .pricingOrange h3 {
    text-align: center;
    margin-top: 2rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .pricingOrange h3 {
    font-size: 2.5rem;
  }
}
.pricingOrange h3 span {
  display: block;
}
.pricingOrange p {
  color: #f7f7f7;
  margin: 2rem 0;
}
@media only screen and (max-width: 48em) {
  .pricingOrange p {
    text-align: center;
  }
}
.pricingOrange a {
  background-color: #ffffff;
  padding: 2.2rem 5.5rem;
  border-radius: 0.5rem;
  color: #191919;
  transition: all 0.3s ease;
  font-weight: 600;
  border: none;
  display: inline-flex;
}
@media only screen and (max-width: 56.25em) {
  .pricingOrange a {
    margin: auto;
  }
}
@media only screen and (max-width: 35em) {
  .pricingOrange a {
    padding: 1rem 3rem;
    font-size: 1.69rem;
  }
}
.pricingOrange a:link, .pricingOrange a:visited {
  text-decoration: none;
}
.pricingOrange a:hover, .pricingOrange a:focus {
  background-color: rgba(255, 255, 255, 0.89);
  color: #f95c39;
}

.contactus {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 4.4rem;
  margin-top: 1rem;
}
@media only screen and (max-width: 75em) {
  .contactus {
    column-gap: 2rem;
  }
}
@media only screen and (max-width: 62em) {
  .contactus {
    grid-template-columns: 1fr;
    row-gap: 3rem;
  }
}
.contactus .formside, .contactus address {
  padding: 4rem;
}
@media only screen and (max-width: 37.5em) {
  .contactus .formside, .contactus address {
    padding: 2rem;
  }
}
.contactus .formside p, .contactus address p {
  text-align: left;
}
.contactus .formside h2, .contactus address h2 {
  font-size: 2.8rem;
  font-weight: 700;
  margin-bottom: 2rem;
}
.contactus .formside h4, .contactus address h4 {
  font-size: 2.2rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}
.contactus .formside h5, .contactus address h5 {
  font-size: 1.72rem;
  margin: 3rem 0 2rem 0;
}
.contactus address {
  box-shadow: 0 0 1.55rem 0.3rem rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
}
.contactus address hr {
  display: flex;
}
.contactus address p {
  margin-bottom: 3.3rem;
}
.contactus .formside {
  background-color: #edf0f7;
  transition: all 0.3s ease;
}
.contactus .formside:hover {
  background-color: #e2e7f2;
}
.contactus .formside form {
  margin-top: 2rem;
}
.contactus .formside .form-label {
  color: #737373;
}
.contactus .formside .form-control-lg {
  font-size: 1.69rem;
  padding: 0.91rem;
  border-radius: 0.5rem;
}
.contactus .formside .large-btn {
  padding-left: 6rem;
  padding-right: 6rem;
  text-transform: uppercase;
}

.indus_details {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 5rem;
}
@media only screen and (max-width: 75em) {
  .indus_details {
    column-gap: 3rem;
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (max-width: 62em) {
  .indus_details {
    grid-template-columns: auto;
    row-gap: 3rem;
  }
}
.indus_details article {
  display: grid;
  align-content: center;
}
.indus_details article strong {
  font-weight: 600;
}
.indus_details article h2 {
  font-weight: 700;
  font-size: 3.3rem;
  margin-bottom: 2rem;
}
.indus_details picture {
  display: flex;
  position: relative;
  padding: 0 0 5rem 5rem;
  transition: all 0.3s ease;
}
@media only screen and (max-width: 75em) {
  .indus_details picture {
    padding: 0 0 2rem 2rem;
  }
}
@media only screen and (max-width: 62em) {
  .indus_details picture {
    justify-content: center;
    padding: 2rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .indus_details picture {
    padding: 1rem;
  }
}
.indus_details picture::before {
  content: "";
  position: absolute;
  height: 82%;
  width: 82%;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(to right bottom, #ffce47, #f95c39);
  z-index: 0;
  transition: all 0.3s ease;
}
@media only screen and (max-width: 62em) {
  .indus_details picture::before {
    height: 100%;
    width: 100%;
  }
}
.indus_details picture img {
  max-width: 100%;
  z-index: 1;
}
.indus_details picture:hover::before {
  width: 91%;
  height: 91%;
  background-image: radial-gradient(#f95c39, #ffce47);
}
@media only screen and (max-width: 62em) {
  .indus_details picture:hover::before {
    height: 100%;
    width: 100%;
  }
}

.bordered_columns {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 4rem;
  margin-top: 6rem;
}
@media only screen and (max-width: 56.25em) {
  .bordered_columns {
    grid-template-columns: 1fr;
    row-gap: 3rem;
  }
}
.bordered_columns .box {
  border: 1px solid #cac9c9;
  padding: 3rem;
  border-radius: 1rem;
  text-align: center;
}
.bordered_columns .box .numb {
  position: relative;
  display: flex;
  width: max-content;
  margin: 2rem auto 4rem auto;
  transition: all 0.3s ease;
}
.bordered_columns .box .numb span {
  font-size: 2.8rem;
  font-weight: 700;
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
  color: #ffffff;
  background-color: #fb481f;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  transition: all 0.3s ease;
}
.bordered_columns .box .numb::before {
  content: "";
  background-color: rgba(249, 92, 57, 0.5);
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  animation: orangeCircle-sm-2 3s ease infinite alternate;
}
.bordered_columns .box .numb::after {
  content: "";
  background-color: rgba(249, 92, 57, 0.18);
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  animation: orangeCircle-sm 3s ease infinite alternate;
}
.bordered_columns .box p {
  text-align: center;
  margin-bottom: 0;
}
.bordered_columns .box h4 {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 2rem;
  text-transform: capitalize;
}
.bordered_columns .box:hover {
  background-color: rgba(115, 115, 115, 0.07);
  border-color: rgba(115, 115, 115, 0.07);
}
.bordered_columns .box:hover .numb {
  transform: translateY(0.33rem);
}
.bordered_columns .box:hover .numb span {
  background-color: #f72112;
}

.bloglayout {
  display: grid;
  grid-template-columns: minmax(auto, 75rem) 1fr;
  column-gap: 6rem;
}
@media only screen and (max-width: 75em) {
  .bloglayout {
    grid-template-columns: 1fr;
    row-gap: 3rem;
  }
}
.bloglayout .breadcrumbs {
  display: flex;
  align-items: center;
  margin-bottom: 1.69rem;
}
.bloglayout .breadcrumbs ul {
  display: flex;
  position: relative;
  width: 100%;
}
.bloglayout .breadcrumbs ul::after {
  content: "";
  height: 1px;
  background-color: #cac9c9;
  width: 100%;
  right: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 0;
}
.bloglayout .breadcrumbs ul li {
  position: relative;
  padding: 0 1rem;
  background-color: #ffffff;
  z-index: 1;
}
.bloglayout .breadcrumbs ul li a:link, .bloglayout .breadcrumbs ul li a:visited {
  color: #737373;
  width: 4rem;
  text-decoration: none;
}
.bloglayout .breadcrumbs ul li a:hover, .bloglayout .breadcrumbs ul li a:focus {
  outline: none;
  color: #f95c39;
}
.bloglayout .breadcrumbs ul li::before {
  content: "/";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.bloglayout .breadcrumbs ul li:first-child {
  padding-left: 0;
}
.bloglayout .breadcrumbs ul li:first-child::before {
  display: none;
}
.bloglayout article .titlepic {
  display: flex;
  position: relative;
  overflow: hidden;
  margin-bottom: 3rem;
}
.bloglayout article .titlepic figcaption {
  display: inline-flex;
  width: max-content;
  background-color: #f95c39;
  color: #ffffff;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 0.69rem 1.69rem;
}
.bloglayout article .blogtitles {
  font-size: 2.69rem;
  font-weight: 600;
  margin-bottom: 3rem;
  transition: all 0.3s ease;
}
@media only screen and (max-width: 56.25em) {
  .bloglayout article .blogtitles {
    font-size: 2.33rem;
    margin-bottom: 2rem;
  }
}
.bloglayout article .blogtitles-2 {
  margin-top: 3rem;
  font-size: 2.55rem;
}
@media only screen and (max-width: 56.25em) {
  .bloglayout article .blogtitles-2 {
    font-size: 2.33rem;
    margin-top: 2rem;
  }
}
.bloglayout article .blogtitles a:link, .bloglayout article .blogtitles a:visited {
  text-decoration: none;
  color: #191919;
}
.bloglayout article .blogtitles a:link:hover, .bloglayout article .blogtitles a:link:focus, .bloglayout article .blogtitles a:visited:hover, .bloglayout article .blogtitles a:visited:focus {
  outline: none;
  color: #f95c39;
}
.bloglayout article .blogtitles.center {
  text-align: center;
  padding: 0 1rem;
}
.bloglayout article p {
  text-align: left;
}
.bloglayout img {
  max-width: 100%;
  display: flex;
  transition: all 0.3s ease;
}
.bloglayout img:focus {
  outline: none;
}
.bloglayout aside {
  display: flex;
  flex-direction: column;
}
.bloglayout aside picture {
  overflow: hidden;
  display: flex;
}
.bloglayout aside .list {
  display: grid;
  grid-template-columns: 19rem auto;
  column-gap: 2rem;
  padding: 1.69rem 0;
  border-bottom: 1px solid #cac9c9;
  align-items: center;
  text-decoration: none;
}
.bloglayout aside .list:first-child {
  padding-top: 0;
}
.bloglayout aside .list .txts h4 {
  font-size: 2rem;
  margin: 1rem 0;
  font-weight: 500;
  color: #191919;
  transition: all 0.3s ease;
}
.bloglayout aside .list .txts p {
  font-size: 1.55rem;
  text-align: left;
  margin: 0;
}
.bloglayout aside .list:focus, .bloglayout aside .list:hover {
  outline: none;
}
.bloglayout aside .list:focus h4, .bloglayout aside .list:hover h4 {
  color: #f95c39;
}
.bloglayout aside .list:focus img, .bloglayout aside .list:hover img {
  transform: scale(1.1);
}

.blognews {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
@media only screen and (max-width: 56.25em) {
  .blognews {
    grid-template-columns: 1fr;
  }
}
.blognews picture {
  background-image: linear-gradient(to right bottom, rgba(3, 48, 124, 0.8), rgba(25, 25, 25, 0.5)), url(../img/newsletter_bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  background-blend-mode: hard-light;
}
@media only screen and (max-width: 56.25em) {
  .blognews picture {
    height: 30rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
  }
}
.blognews .blogform_01 {
  padding: 5rem 6rem;
  background-color: #323336;
  color: #ffffff;
  display: grid;
  align-content: center;
}
@media only screen and (max-width: 37.5em) {
  .blognews .blogform_01 {
    padding: 2rem;
  }
}
.blognews .blogform_01 input {
  background-color: #323336;
  border: 1px solid #737373;
  border-radius: 0.5rem;
  padding: 1.55rem;
  width: 100%;
  margin: 1.69rem 0;
  color: #f7f7f7;
}
.blognews .blogform_01 input:hover, .blognews .blogform_01 input:focus {
  outline: none;
}
.blognews .blogform_01 input:focus:invalid {
  border-color: #f72112;
}
.blognews .blogform_01 h2 {
  font-size: 2.8rem;
  font-weight: 700;
  margin-bottom: 2rem;
}
@media only screen and (max-width: 37.5em) {
  .blognews .blogform_01 h2 {
    font-size: 2.5rem;
  }
}
.blognews.forside {
  grid-template-columns: 1fr;
  margin-top: 3rem;
}
.blognews.forside .blogform_01 {
  padding: 3rem;
}
.blognews.forside .blogform_01 h2 {
  text-align: center;
}
.blognews.forside picture {
  height: 22rem;
}

.blogform_02 {
  padding: 3rem;
  background-color: #323336;
  color: #ffffff;
  display: grid;
  align-content: center;
  margin-top: 3rem;
}
.blogform_02 input {
  background-color: #323336;
  border: 1px solid #737373;
  border-radius: 0.5rem;
  padding: 1rem;
  width: 100%;
  margin: 1.33rem 0;
  color: #f7f7f7;
}
.blogform_02 input:hover, .blogform_02 input:focus {
  outline: none;
}
.blogform_02 input:focus:invalid {
  border-color: #f72112;
}
.blogform_02 h2 {
  font-size: 2.8rem;
  font-weight: 700;
  margin-bottom: 2rem;
  text-align: center;
}

.price-table-new {
  transform: translateY(-1rem);
  display: grid;
  grid-template-columns: auto repeat(3, 1fr);
  column-gap: 1px;
  padding: 1px;
  background-color: #cac9c9;
}
@media only screen and (max-width: 62em) {
  .price-table-new {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 3rem;
    background-color: #ffffff;
  }
}
@media only screen and (max-width: 37.5em) {
  .price-table-new {
    grid-template-columns: 1fr;
  }
}
.price-table-new .pricePlans {
  padding: 4rem 1rem 0 1rem;
  background-color: #f5fafe;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  background-size: cover;
}
.price-table-new .pricePlans::before {
  content: "";
  width: 82%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 2rem;
  top: -1rem;
  z-index: 1;
  border-radius: 100px;
  transition: all 0.3s ease;
}
.price-table-new .pricePlans.autumn::before {
  background-image: linear-gradient(to right, #f88216, #ffaf37);
}
.price-table-new .pricePlans.silver::before {
  background-image: linear-gradient(to right, #B3CDD1, #9FA4C4);
}
.price-table-new .pricePlans.gold::before {
  background-image: linear-gradient(to right, #FCF6BA, #BF953F);
}
.price-table-new .pricePlans.platinum::before {
  background-image: linear-gradient(to right, #4088A9, #284E74);
}
.price-table-new .pricePlans.sky::before {
  background-image: linear-gradient(to right, #100bbc, #736ae7);
}
.price-table-new .pricePlans.sea::before {
  background-image: linear-gradient(to right, #03307c, #2deda7);
}
.price-table-new .pricePlans.dusk::before {
  background-image: linear-gradient(to right, #f72112, #f95c39);
}
.price-table-new .pricePlans:hover {
  background-color: #ffffff;
}
.price-table-new .pricePlans:hover::before {
  width: 91%;
}
.price-table-new .pricePlans .planheading {
  display: grid;
  text-align: center;
  height: 25rem;
  align-content: center;
}
.price-table-new .pricePlans .planheading h4 {
  color: #f95c39;
  font-size: 1.9rem;
  font-weight: 700;
}
.price-table-new .pricePlans .planheading h3 {
  font-size: 2.8rem;
  font-weight: 700;
}
.price-table-new .pricePlans .planheading h3.orange {
  color: #f95c39;
  margin-bottom: 1.69rem;
}
.price-table-new .pricePlans .planheading h2 {
  font-size: 3.3rem;
  font-weight: 700;
  margin: 2.5rem 0;
}
.price-table-new .pricePlans .planheading h2 sup {
  font-size: 55%;
}
.price-table-new .pricePlans .planheading .addon {
  display: flex;
  margin-top: 2rem;
  justify-content: center;
}
.price-table-new .pricePlans .planheading .addon.white {
  color: #ffffff;
}
.price-table-new .pricePlans .planheading p {
  text-align: center;
  font-size: 1.81rem;
}
.price-table-new .pricePlans .planheading p span {
  display: block;
}
.price-table-new .pricePlans .planheading .subscribe-btn {
  padding: 1rem 3.3rem;
  background-color: #323336;
  text-transform: uppercase;
  border: none;
  color: #ffffff;
  transition: all 0.3s ease;
  font-size: 1.73;
  display: flex;
  justify-content: center;
  margin: 2rem 0;
}
.price-table-new .pricePlans .planheading .subscribe-btn:link, .price-table-new .pricePlans .planheading .subscribe-btn:visited {
  text-decoration: none;
}
.price-table-new .pricePlans .planheading .subscribe-btn:hover, .price-table-new .pricePlans .planheading .subscribe-btn:focus, .price-table-new .pricePlans .planheading .subscribe-btn:active {
  background-color: #f95c39;
  outline: none;
}
.price-table-new .pricePlans .pricebody h4 {
  background-color: #edf0f7;
  font-size: 1.9rem;
  font-weight: 600;
  padding: 1.2rem;
  text-align: center;
  margin: 2rem 0;
}
.price-table-new .pricePlans .pricebody ul {
  display: grid;
  color: #737373;
  border-top: 1px solid #cac9c9;
  margin-top: 1rem;
}
.price-table-new .pricePlans .pricebody ul.thick {
  font-weight: 600;
  color: #191919;
}
.price-table-new .pricePlans .pricebody ul li {
  display: flex;
  border-bottom: 1px solid #cac9c9;
  padding: 1rem 0;
}
.price-table-new .pricePlans .pricebody ul li:last-child {
  border-bottom: none;
}

.faqOrangeBox {
  background-image: url(../img/faq_pattern_bg.png);
  background-position: left top;
  background-repeat: no-repeat;
  position: relative;
  background-size: 100%;
  background-color: #ffaf37;
  overflow: hidden;
  border-radius: 1rem;
  text-align: center;
  padding: 6rem 3rem;
}
.faqOrangeBox::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom right, transparent, rgba(251, 72, 31, 0.95));
  z-index: 0;
}
.faqOrangeBox p {
  color: #ffffff;
  max-width: 82rem;
  margin: 0 auto;
  font-size: 1.91rem;
  font-weight: 600;
  text-align: center;
  z-index: 1;
  position: relative;
}
.faqOrangeBox a {
  background-color: #191919;
  padding: 1rem 4rem;
  border-radius: 4px;
  color: #ffffff;
  transition: all 0.3s ease;
  font-weight: 600;
  border: none;
  display: inline-flex;
  z-index: 10;
  position: relative;
  margin-top: 3rem;
}
.faqOrangeBox a:link, .faqOrangeBox a:visited {
  text-decoration: none;
}
.faqOrangeBox a:hover, .faqOrangeBox a:focus {
  background-color: #ffffff;
  color: #f95c39;
}

.supportlist {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 4rem;
  row-gap: 4rem;
}
@media only screen and (max-width: 75em) {
  .supportlist {
    column-gap: 2rem;
    row-gap: 2rem;
  }
}
@media only screen and (max-width: 56.25em) {
  .supportlist {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 37.5em) {
  .supportlist {
    grid-template-columns: 1fr;
  }
}
.supportlist .listcolumn {
  padding: 3rem;
  box-shadow: 0 0 1.55rem 0.11rem rgba(0, 0, 0, 0.11);
  border-radius: 1rem;
  background-color: #ffffff;
  transition: all 0.3s ease;
}
.supportlist .listcolumn svg {
  height: 3.3rem;
  width: 3.3rem;
}
.supportlist .listcolumn h4 {
  font-size: 2rem;
  font-weight: 600;
  text-transform: capitalize;
  margin: 2rem 0;
  transition: all 0.3s ease;
}
.supportlist .listcolumn:link, .supportlist .listcolumn:visited, .supportlist .listcolumn:hover, .supportlist .listcolumn:focus {
  text-decoration: none;
  outline: none;
}
.supportlist .listcolumn:link p, .supportlist .listcolumn:visited p, .supportlist .listcolumn:hover p, .supportlist .listcolumn:focus p {
  text-align: left;
  margin: 0;
}
.supportlist .listcolumn:link h4, .supportlist .listcolumn:visited h4, .supportlist .listcolumn:hover h4, .supportlist .listcolumn:focus h4 {
  color: #191919;
}
.supportlist .listcolumn:hover {
  transform: translateY(-0.69rem);
  box-shadow: 0 0 2.8rem 0.11rem rgba(0, 0, 0, 0.09);
}
.supportlist .listcolumn:hover h4 {
  color: #f95c39;
}
.supportlist.titles {
  column-gap: 3rem;
  row-gap: 3rem;
}
.supportlist.titles .listcolumn {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 1.5rem;
  padding: 2rem;
  align-items: center;
  border-radius: 0.69rem;
}
.supportlist.titles .listcolumn svg, .supportlist.titles .listcolumn h4 {
  margin: 0;
  display: inline-flex;
}
.supportlist.titles .listcolumn:hover, .supportlist.titles .listcolumn:focus, .supportlist.titles .listcolumn:active, .supportlist.titles .listcolumn.active {
  background-color: #111111;
}
.supportlist.titles .listcolumn:hover h4, .supportlist.titles .listcolumn:focus h4, .supportlist.titles .listcolumn:active h4, .supportlist.titles .listcolumn.active h4 {
  color: #ffffff;
}

.moreSupport {
  margin: 5.5rem 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 5.5rem;
}
@media only screen and (max-width: 75em) {
  .moreSupport {
    column-gap: 2rem;
  }
}
@media only screen and (max-width: 56.25em) {
  .moreSupport {
    grid-template-columns: 1fr;
  }
}
.moreSupport .supportform {
  background-color: rgba(255, 227, 225, 0.67);
  padding: 5rem;
  border-radius: 1rem;
  display: grid;
  transition: all 0.3s ease;
}
@media only screen and (max-width: 75em) {
  .moreSupport .supportform {
    padding: 3rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .moreSupport .supportform {
    padding: 2rem;
  }
}
.moreSupport .supportform:hover {
  background-color: rgba(255, 227, 225, 0.89);
}
.moreSupport .supportform h3 {
  font-size: 2.8rem;
  text-align: center;
  margin-bottom: 2rem;
  font-weight: 700;
}
.moreSupport .supportform .form-label {
  color: #323336;
  margin-bottom: 1rem;
}
.moreSupport .supportform .form-control-lg,
.moreSupport .supportform .form-select-lg {
  font-size: 1.69rem;
  padding: 0.91rem;
  border-radius: 0.5rem;
  color: #323336 !important;
  border-color: transparent;
}
.moreSupport .supportform .form-control:focus,
.moreSupport .supportform .form-select:focus {
  box-shadow: none;
}
.moreSupport .supportform .large-btn {
  padding: 0.69rem 3rem;
  margin-top: 1rem;
}

.contactSupport {
  background-image: url(../img/conatct-support_bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 37rem;
  background-color: #f9f1e8;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 2rem;
  overflow: hidden;
}
@media only screen and (max-width: 37.5em) {
  .contactSupport {
    justify-content: center;
  }
}
.contactSupport .txts {
  width: 100%;
  max-width: 50rem;
}
@media only screen and (max-width: 37.5em) {
  .contactSupport .txts {
    justify-items: center;
    display: grid;
  }
}
.contactSupport .txts p {
  font-size: 2rem;
  color: #191919;
  font-weight: 500;
  margin-bottom: 1.6rem;
}
.contactSupport .txts h3 {
  font-size: 3.3rem;
  font-weight: 700;
  margin-bottom: 1.69rem;
}
.contactSupport .txts a {
  display: inline-flex;
  padding: 0.82rem 3.3rem;
  background-color: #ffffff;
  font-weight: 600;
  border-radius: 4px;
  transition: all 0.3s ease;
}
.contactSupport .txts a:link, .contactSupport .txts a:visited {
  text-decoration: none;
  color: #191919;
}
.contactSupport .txts a:link:hover, .contactSupport .txts a:link:focus, .contactSupport .txts a:visited:hover, .contactSupport .txts a:visited:focus {
  background-color: #fb481f;
  color: #ffffff;
}

.helcenterContent {
  padding-top: 3rem;
}
.helcenterContent ul {
  margin-bottom: 1rem;
}
.helcenterContent h4 {
  font-size: 2rem;
  font-weight: 700;
  margin: 1.9rem 0;
  color: #f95c39;
}
.helcenterContent h6 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 1rem;
  color: #307fe2;
}
.helcenterContent h5 {
  font-weight: 600;
  font-size: 1.72rem;
}

.homerecruitment {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 4rem;
  align-items: center;
}
@media only screen and (max-width: 56.25em) {
  .homerecruitment {
    grid-template-columns: 1fr;
  }
}
.homerecruitment .sectitle_01 {
  justify-content: left;
  text-align: left;
}
@media only screen and (max-width: 56.25em) {
  .homerecruitment .sectitle_01 {
    justify-content: center;
    text-align: center;
  }
}
.homerecruitment .sectitle_01 .underline-links, .homerecruitment .sectitle_01 h2, .homerecruitment .sectitle_01 p {
  text-align: left;
}
@media only screen and (max-width: 56.25em) {
  .homerecruitment .sectitle_01 .underline-links, .homerecruitment .sectitle_01 h2, .homerecruitment .sectitle_01 p {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}

.recruitboxes {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
  row-gap: 2rem;
}
@media only screen and (max-width: 40em) {
  .recruitboxes {
    grid-template-columns: 1fr;
  }
}
.recruitboxes .box {
  padding: 1.5rem;
  box-shadow: 0 0 1rem 0.3rem rgba(0, 0, 0, 0.05);
  text-align: center;
  border-radius: 1rem;
  background-color: #ffffff;
  display: grid;
  align-items: center;
  justify-items: center;
  transition: all 0.3s ease;
}
.recruitboxes .box:hover {
  transform: translateY(-1rem);
}
.recruitboxes .box h4 {
  margin: 1.5rem 0;
  font-size: 1.9rem;
}
.recruitboxes .box p {
  text-align: center;
}
.recruitboxes .box span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 6.9rem;
  width: 6.9rem;
  border-radius: 50%;
}
.recruitboxes .box span svg {
  width: 4rem;
  height: 4rem;
}

.employboxes {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
  row-gap: 2rem;
  padding-top: 2rem;
  border-top: 1px solid #cac9c9;
}
@media only screen and (max-width: 40em) {
  .employboxes {
    grid-template-columns: 1fr;
  }
}
.employboxes .box {
  padding: 1.5rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  column-gap: 2rem;
}
.employboxes .box:first-child {
  background-color: rgba(238, 148, 67, 0.09);
}
.employboxes .box:nth-child(2) {
  background-color: rgba(27, 101, 193, 0.09);
}
.employboxes .box:nth-child(3) {
  background-color: rgba(144, 178, 73, 0.09);
}
.employboxes .box:last-child {
  background-color: rgba(87, 11, 230, 0.09);
}
.employboxes .box:hover {
  transform: translateX(-1rem);
}
.employboxes .box h4 {
  margin: 1.2rem 0;
  font-size: 1.9rem;
}
.employboxes .box span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 7.3rem;
  width: 7.3rem;
  border-radius: 4px;
}
.employboxes .box span svg {
  width: 5rem;
  height: 5rem;
}

.procurementboxes {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
@media only screen and (max-width: 40em) {
  .procurementboxes {
    grid-template-columns: 1fr;
  }
}
.procurementboxes .box {
  padding: 2rem;
  display: grid;
  align-items: center;
  transition: all 0.3s ease;
}
.procurementboxes .box:first-child {
  text-align: right;
  justify-content: right;
  justify-items: end;
  border-right: 1px solid #cac9c9;
}
@media only screen and (max-width: 40em) {
  .procurementboxes .box:first-child {
    border-right: none;
  }
}
@media only screen and (max-width: 40em) {
  .procurementboxes .box:nth-child(2) {
    border-top: 1px solid #cac9c9;
  }
}
.procurementboxes .box:nth-child(3) {
  text-align: right;
  justify-content: right;
  justify-items: end;
  border-right: 1px solid #cac9c9;
  border-top: 1px solid #cac9c9;
}
@media only screen and (max-width: 40em) {
  .procurementboxes .box:nth-child(3) {
    border-right: none;
  }
}
.procurementboxes .box:last-child {
  border-top: 1px solid #cac9c9;
}
.procurementboxes .box h4 {
  margin: 1.2rem 0;
  font-size: 1.9rem;
}
.procurementboxes .box span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 6.6rem;
  width: 6.6rem;
  border-radius: 4px;
}
.procurementboxes .box span svg {
  width: 4rem;
  height: 4rem;
  fill: #111111;
}
@media only screen and (max-width: 40em) {
  .procurementboxes .box {
    border-bottom: 1px solid #cac9c9;
  }
}

.homecrm {
  display: grid;
  grid-template-columns: minmax(auto, 40rem) 1fr;
  column-gap: 4rem;
  align-items: center;
}
@media only screen and (max-width: 56.25em) {
  .homecrm {
    grid-template-columns: 1fr;
  }
}
.homecrm .sectitle_01 {
  justify-content: left;
  text-align: left;
}
@media only screen and (max-width: 56.25em) {
  .homecrm .sectitle_01 {
    justify-content: center;
    text-align: center;
  }
}
.homecrm .sectitle_01 .underline-links, .homecrm .sectitle_01 h2, .homecrm .sectitle_01 p {
  text-align: left;
}
@media only screen and (max-width: 56.25em) {
  .homecrm .sectitle_01 .underline-links, .homecrm .sectitle_01 h2, .homecrm .sectitle_01 p {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}

.crmboxes {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
  row-gap: 2rem;
}
@media only screen and (max-width: 40em) {
  .crmboxes {
    grid-template-columns: 1fr;
  }
}
.crmboxes .box {
  padding: 1.5rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  column-gap: 1.5rem;
}
.crmboxes .box:first-child span {
  background-color: #ffaf37;
}
.crmboxes .box:nth-child(2) span {
  background-color: #570be6;
}
.crmboxes .box:nth-child(3) span {
  background-color: #fe8f64;
}
.crmboxes .box:last-child span {
  background-color: #159d75;
}
.crmboxes .box:hover {
  transform: translateX(1rem);
}
.crmboxes .box h4 {
  font-size: 1.9rem;
}
.crmboxes .box p {
  text-align: left;
}
.crmboxes .box span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 6.9rem;
  width: 6.9rem;
  border-radius: 4px;
}
.crmboxes .box span svg {
  width: 4rem;
  height: 4rem;
  fill: #ffffff;
}