
// media query
@mixin respond($breakpoint) {

    @if $breakpoint == xsd {
        @media only screen and (max-width: 25em) { @content };    //400px
    }
    @if $breakpoint == xssd {
        @media only screen and (max-width: 27.5em) { @content };    //440px
    }
    @if $breakpoint == sd {
        @media only screen and (max-width: 30em) { @content };    //480px
    }
    @if $breakpoint == md {
      @media only screen and (max-width: 35em) { @content };    //560px
  }
    @if $breakpoint == phone {
        @media only screen and (max-width: 37.5em) { @content };    //600px
    }
    @if $breakpoint == newphones {
        @media only screen and (max-width: 40em) { @content };    //640px
    }
    @if $breakpoint == medium-tab {
        @media only screen and (max-width: 48em) { @content };    //768px
    }
    @if $breakpoint == normal-tab {
        @media only screen and (max-width: 56.25em) { @content };    //900px
    }
    @if $breakpoint == tab-port {
        @media only screen and (max-width: 62em) { @content };     //992px
    }
    @if $breakpoint == tab-land {
        @media only screen and (max-width: 75em) { @content };    //1200px
    }
    @if $breakpoint == mid-desktop {
        @media only screen and (max-width: 85.5em) { @content };    //1368px
    }
    @if $breakpoint == big-desktop {
        @media only screen and (min-width: 120em) { @content };    //1920px
    }
}


// absolute center

@mixin absCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin landCenter {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

@mixin portCenter {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

@mixin bgCenter {
    background-size: cover;
    background-repeat: no-repeat;
    background-position:center;
}

@mixin bgTop {
    background-size: cover;
    background-repeat: no-repeat;
    background-position:top center;
}

%commonSects {
    width:$maxwidth;
    max-width:130rem;
    margin:auto;

    @include respond(tab-land) {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
      }

  }


  %splitColumn {
    display: grid;
    grid-template-columns: 50% 50%;
    margin: 6rem 0;

    @include respond(tab-land) {
        grid-template-columns: 1fr;
      }
  }



  @mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};
    @media (prefers-reduced-motion) {
      .animated {
        -webkit-animation: unset !important;
        animation: unset !important;
        -webkit-transition: none !important;
        transition: none !important;
      }
    }
  }

  @mixin keyframes($animation-name) {
      @-webkit-keyframes #{$animation-name} {
          @content;
      }
      @-moz-keyframes #{$animation-name} {
          @content;
      }
      @-ms-keyframes #{$animation-name} {
          @content;
      }
      @-o-keyframes #{$animation-name} {
          @content;
      }
      @keyframes #{$animation-name} {
          @content;
      }
  }

  @mixin transform($value){
      -webkit-transform: $value;
      -moz-transform: $value;
      -ms-transform: $value;
      -o-transform: $value;
      transform: $value;
  }

//   .will-fadeIn{
//     display: block;
//     width: $maxwidth;
//     max-width: 640px;
//     margin: 20px auto;
//     height: 250px;
//     background-color: #b7f7f7;
//     padding: 10px;
//     &:nth-child(odd) {
//       background: #ffeded
//     }
//     &:first-child{
//       height: auto;
//       background-color: #edffff;
//     }
//   }


  @include keyframes(fadeInRight) {
      from {
          opacity: 0;
          @include transform( translate3d(3.3rem, 25%, 3.3rem));
      }

      to {
          opacity: 1;
          @include transform( translate3d(0, 0, 0));
      }
  }

  @include keyframes(fadeInLeft) {
      from {
          opacity: 0;
          @include transform( translate3d(-3.3rem, 25%, -3.3rem));
      }

      to {
          opacity: 1;
          @include transform( translate3d(0, 0, 0));
      }
  }
