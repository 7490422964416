
//Colors
$color-white:#ffffff;
$color-black:#000000;
$color-aqua:#0d5854;
$blue-dark:#132144;

$light-orange:#ffeeba;
$light-blue:#ceecff;
$light-pink:#ffe3e1;
$light-pink-2:#f9f1e8;
$light-aqua:#d9f4e5;
$gray-light:#f7f7f7;
$tablebg:#f5fafe;

$gray-ef:#edf0f7;
$gray-ed:#e0d5f5;
$gray-a:#aaaaaa;
$gray-mid:#737373;
$gray-80s:#8f8f9b;
$gray-dark:#191919;
$gray-four:#404040;
$gray-three:#3f434f;
$gray-c:#cac9c9;
$gray-dark-1:#111111;
$gray-dark-3:#323336;

$color-blue-1:#4f8dfd;
$color-blue-2:#4bcbfa;
$color-blue-3:#100bbc;
$color-blue-4:#736ae7;
$color-blue-5:#6d8bdd;
$color-blue-6:#242833;
$color-blue-7:#307fe2;
$color-blue-8:#570be6;
$color-blue-9:#1b65c1;

$color-pink:#e93cd8;
$color-green:#90b249;
$color-aqua-2:#159d75;

$color-water-1:#2deda7;
$color-water-2:#03307c;

$color-orange:#f95c39;
$color-orange-1:#f88216;
$color-orange-2:#ffaf37;
$color-orange-3:#fb481f;
$color-orange-4:#ee9443;

$color-red:#f72112;
$color-red-1:#cd0b3f;
$color-peach:#fe8f64;

$color-yellow:#fdae3d;
$color-yellow-1:#ffe29a;
$color-yellow-2:#ffce47;
$color-yellow-3:#f9b303;

$bluefb:#4267b2;
$bluetwr:#1da1F2;
$blueldn:#0077b5;

//Gradients
$sky:linear-gradient(to right, $color-blue-3,$color-blue-4);
$sea:linear-gradient(to right, $color-water-2,$color-water-1);
$dusk:linear-gradient(to right, $color-red,$color-orange);
$autumn:linear-gradient(to right, $color-orange-1,$color-orange-2);
$transdusk:linear-gradient(to top right, transparentize($color-orange-1, .11),transparentize($color-red-1, .11));
$deepsea:linear-gradient(to right, $color-blue-6, transparent);
$ocean:linear-gradient(to right bottom, transparentize($color-water-1, .11),transparentize($color-water-2, .11));
$orange-yellow:linear-gradient(to top right, transparent, transparentize($color-yellow-2, .11));
$dawn:linear-gradient(to bottom right, transparent, transparentize($color-orange-3, .05));
$bluegray:linear-gradient(to right bottom, transparentize($color-water-2, .2),transparentize($gray-dark, .5));
$silver:linear-gradient(to right, #B3CDD1,#9FA4C4);
$gold:linear-gradient(to right, #FCF6BA,#BF953F);
$platinum:linear-gradient(to right, #4088A9,#284E74);
$bluewhite:linear-gradient(to right, $color-white, #eef7ff);

// $sundusk:radial-gradient($color-red,$color-orange);
//Hamburger
$bar-width: 3.1rem;
$bar-height: .31rem;
$bar-spacing: .82rem;

//Font
$default-font-size:1.69rem;
$bold:700;
$medium-bold:500;
$semi-bold:600;
$bolder:900;
$normal:400;

// Commons
$shadow:0 0 1.55rem .3rem transparentize($color-black, .91);
$shadow2:0 0 1rem .3rem transparentize($color-black, .95);
$trans:all .3s ease;
$borders:1px solid $gray-c;
$radius:4px;
$chbx_size: 1.72rem;
$tt: .2s;
$tt_toggle: .4s;
$maxwidth:100%;
